import { restApi } from '../../api/restApi'

export const baseRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getImageUrl: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/getImageUrl',
        method: 'POST',
        body: payload
      })
    }),
    getExecutorRfm: builder.query({
      query: (payload) => ({
        url: '/api/app-template/executor/rfm',
        method: 'GET',
        params: payload
      })
    }),
  })
})

export const { useGetImageUrlMutation, useGetExecutorRfmQuery } = baseRestApi