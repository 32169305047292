import { createBrowserRouter, Outlet, useRouteError, Navigate } from "react-router-dom"
import Container from "@mui/material/Container"
import ScrollToTop from "./ScrollToTop"
import Funeral from "./Funeral"
import Products from "../features/products/Products"
import Navbar from "../layout/Navbar/Navbar"
import About from "../pages/about/About"
import Obituary from "../pages/obituary/Obituary"
import Test from "../pages/test/Test"
import Survivedby from "../pages/survivedby/Survivedby"
import Donation from "../pages/donation/Donation"
import Events from "../pages/events/Events"
import ProductPage from "../pages/product/ProductPage"
import Messages from "../pages/messages/Messages"
import GiveList from "../pages/give/GiveList"
import Profilepage from "../pages/profile/Profile"
import Logout from "../pages/profile/Logout"
import Checkout from "../pages/checkout/Checkout"
import Confirm from "../pages/checkout/Confirm"
import Footer from "../layout/Footer/Footer"
import ProtectedRoute from "./ProtectedRoute"
import Guestlist from "../pages/guest/Guestlist"
import Approval from "../pages/approval/Approval"
import EventCreate from "../pages/events/event_create/EventCreate"
import EditEvent from "../pages/events/event_edit/EditEvent"
import EventRsvp from "../pages/events/EventRsvp"
import ResetPw from "../pages/ResetPw/ResetPw"
import styles from "./Styles.module.css"
import Gallery from "../pages/gallery/Gallery"

const HeaderLayout = () => (
  <Funeral>
    <Navbar />
    <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
      <ScrollToTop />
      <Outlet />
    </Container>
    <Footer />
  </Funeral>
)

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <></>
    },
    {
      path: "/password-reset",
      element: <ResetPw />
    },
    {
      path: "/:name/:id",
      element: <HeaderLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "",
          element: <Navigate to="./about" />
        },
        {
          path: "about",
          element: <About />
        },
        {
          path: "obituary",
          element: <Obituary />
        },
        {
          path: "products",
          element: <Products />
        },
        {
          path: "survivedby",
          element: <Survivedby />
        },
        {
          path: "donation",
          element: <Donation />
        },
        {
          path: "events",
          element: <Events />
        },
        {
          path: "messages",
          element: <Messages />
        },
        {
          path: "gallery",
          element: <Gallery />
        },
        {
          path: "give",
          children: [
            {
              path: "",
              element: <GiveList />
            }
          ]
        },
        {
          path: "give/product/:slug/:productId",
          element: <ProductPage />
        },
        {
          path: "checkout",
          element: <Checkout />
        },
        {
          path: "checkout/confirmation/:orderCode",
          element: <Confirm />
        },
        {
          path: "profile/:pageId?",
          element: <Profilepage />
        },
        {
          path: "logout",
          element: <Logout />
        },
        {
          path: "rfm/approval",
          element: (
            <ProtectedRoute>
              <Approval />
            </ProtectedRoute>
          )
        },
        {
          path: "rfm/guestlist",
          element: (
            <ProtectedRoute>
              <Guestlist />
            </ProtectedRoute>
          )
        },
        {
          path: "rfm/event/create",
          element: (
            <ProtectedRoute>
              <EventCreate />
            </ProtectedRoute>
          )
        },
        {
          path: "rfm/event/edit/:eventId",
          element: (
            <ProtectedRoute>
              <EditEvent />
            </ProtectedRoute>
          )
        },
        {
          path: "rfm/event/rsvp/:eventId",
          element: (
            <ProtectedRoute>
              <EventRsvp />
            </ProtectedRoute>
          )
        },
        {
          path: "test",
          element: <Test />
        }
      ]
    }
  ],
  { basename: "/app" }
)

function ErrorBoundary() {
  let error = useRouteError()
  console.error(error)
  // Uncaught ReferenceError: path is not defined
  return <div>Error!</div>
}
