import { restApi } from '../../api/restApi'
import { setFuneralDetails } from './funeralSlice'

export const funeralRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvites: builder.query({
      query: () => ({
        url: '/api/app-template/funeral',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        
        } catch (error) {}
      },
    }),
    getOne: builder.query({
      providesTags: ['funeral'],
      query: ({id,name, isLoggedIn}) => ({
        url: `/api/app-template/funeral/${id}/${name}`
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setFuneralDetails(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getRfmlist: builder.query({
      providesTags: ['rfmlist'],
      query: (funeralId) => ({
        url: `/api/app-template/funeral/rfmlist/${funeralId}`
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getFuneralMasterProducts: builder.query({
      query: (payload) => ({
        url: '/api/app-template/account/master/products',
        method: 'GET',
        params: payload
      })
    }),
  })
})

export const { useGetAllInvitesQuery, useGetOneQuery, useGetRfmlistQuery, useGetFuneralMasterProductsQuery } = funeralRestApi