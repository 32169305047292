import { useEffect } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { useGetOrderQuery } from "../../../features/order/orderGqlApi"
import { FormEvent, useState } from "react"
import { Box, Typography, Grid, InputLabel, Button, CardMedia } from "@mui/material"
import RefreshIcon from "../../base/Icons/RefreshIcon"
import styles from "../Checkout.module.css"
import { useParams } from "react-router-dom"
import Toast from "../../base/Toast/Toast"
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align"

export const CheckoutForm = ({ orderCode, clientSecret }: { orderCode: string; clientSecret: string }) => {
  const [toast, setToast] = useState({
    type: "success",
    open: false,
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const stripe = useStripe()
  const elements = useElements()

  const { id } = useParams()
  const { data, isLoading, refetch } = useGetOrderQuery(id, { refetchOnMountOrArgChange: true })
  const activeOrder = data?.activeOrder
  const amount = activeOrder.totalWithTax
  const [isPending, setIsPending] = useState(false)

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsPending(true)
    if (!stripe || !elements) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setToast({
            ...toast,
            type: "success",
            open: true,
            message: "Payment succeeded!"
          })
          window.location.href = window.location.href + `/confirmation/${orderCode}`
          break
        case "processing":
          setToast({
            ...toast,
            type: "success",
            open: true,
            message: "Your payment is processing."
          })
          break
        case "requires_payment_method":
          setToast({
            ...toast,
            type: "error",
            open: true,
            message: "Your payment was not successful, please try again."
          })
          break
        default:
          setToast({
            ...toast,
            type: "error",
            open: true,
            message: "Something went wrong."
          })
          break
      }
    })
    setIsPending(false)
  }

  if (!isLoading) {
    return (
      <Box sx={{ mx: { xs: 3, md: 0 } }}>
        <Toast {...toast} />
        <Typography className="sectionTitle" sx={{ mt: 2 }}>
          <b>PayNow</b>
        </Typography>
        <Grid container className={styles.centerContainer}>
          <Grid item md={12} lg={7}>
            <InputLabel sx={{ mb: 0.5, fontSize: "18px", textAlign: "center", whiteSpace: "pre-wrap" }}>Scan below QR code image using your internet banking app</InputLabel>
            <InputLabel sx={{ mb: 1, fontSize: "16px", whiteSpace: "pre-wrap" }}>Paying on mobile? Use your phone to save or take a screenshot of the QR code and upload QR code using your mobile banking app.</InputLabel>
            <InputLabel sx={{ mb: 0.5, textAlign: "center" }}>
              SGD {(amount / 100).toFixed(2)}&nbsp;
              {(() => {
                if (!isPending) {
                  return (
                    <Button sx={{ minWidth: "16px", px: 0, mt: "-3px" }} onClick={handleSubmit}>
                      <RefreshIcon />
                    </Button>
                  )
                } else {
                  return (
                    <Button sx={{ minWidth: "16px", px: 0, mt: "-3px" }} className={styles.spinButton} disabled onClick={handleSubmit}>
                      <RefreshIcon />
                    </Button>
                  )
                }
              })()}
            </InputLabel>
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ width: { md: "520px" } }}>
                <img src="/images/sample.png" style={{ maxWidth: 520, width: "100%", height: "auto" }} />
              </Box>
              <InputLabel sx={{ mt: 0.5, fontSize: "18px", textAlign: "center" }}>Supported banks</InputLabel>
              <img src="/images/bank.png" style={{ maxWidth: 290, height: "auto" }}></img>
            </Box>
          </Grid>
        </Grid>
        <></>
      </Box>
    )
  } else {
    return null
  }
}
