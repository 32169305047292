import React, { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { InputLabel, Typography } from '@mui/material';
import CustomTextField from '../base/Textfield/CustomTextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './LoginModal.module.css'
import { useLoginMutation } from '../../features/auth/authGqlApi'
import { useGetMeMutation } from '../../features/user/userGqlAPI';
import { useBackendLoginMutation } from '../../features/auth/authRestApi';
import CustomFailMsg from '../base/Toast/CustomFailMsg';
import { debounce, validateEmail } from '../../constants';
import Button from '../base/Button/Button'
import Toast from '../base/Toast/Toast';
import { useUpdateEventGuestMutation, useUpdateMainGuestMutation } from '../../features/guestlist/guestlistRestApi';
import { useParams } from 'react-router-dom';

interface props {
  onClick: (x: number) => void;
  handleClose: () => void;
  type?: string;
  eventId?: string | null;
}

const LoginForm: React.FC<props> = (props) => {
  const {
    onClick,
    handleClose,
    type,
    eventId = null
  } = props;

  const {id} = useParams();
  const [user, setUser] = useState<any>({ email: '', password: '' })
  const [loginUser, shoplogin] = useLoginMutation();
  const [backendLogin, result] = useBackendLoginMutation()
  const [getMe, getActiveResult] = useGetMeMutation()
  const [updateMainGuest] = useUpdateMainGuestMutation()
  const [updateEventGuest] = useUpdateEventGuestMutation()
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: false,
    password: false
  })
  const [loginFail, setLoginFail] = useState({
    error: false,
    message: ''
  })
  const [loginForm, setLoginForm] = useState<any>({ email: '', password: '' })
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    type: 'success',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.email && user.password) {
      //backendLogin(user)
      loginUser(user)
    }
  }, [user])

  /* useEffect(() => {
    if(result.isSuccess && result.data.user_meta) {
      loginUser(loginForm)
    }
    if(result.isError && result.status === 'rejected') {
      setLoginFail({
        error: true,
        message: 'Incorrect Credentials!'
      })
       setTimeout(() => {
        setLoginFail({
          error: false,
          message: ''
        })
      }, 3000) 
      
    setLoading(false)
    }
  }, [result]) */

  useEffect(() => {
    if (shoplogin.isSuccess) {
      getMe(null)
    }
  }, [shoplogin])

  useEffect(() => {
    if (getActiveResult.isSuccess) {
      if (!getActiveResult.data.activeCustomer) {
        setToast({
          ...toast,
          open: true,
          type: 'error',
          message: 'Not a customer account!'
        })
        setLoading(false)
      } else {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: 'Login Successfully!'
        })

        addUserToGuest(getActiveResult.data.activeCustomer);
        handleClose()
      }
    }
  }, [getActiveResult])
  console.log('getActiveResult: ', getActiveResult);

  const validateSingleField = (obj: { key: string, value: string }) => {
    const { key, value } = obj
    let rv
    if (key === 'email') {
      rv = validateEmail(value)
    }
    if (key === 'password') {
      value ? rv = true : rv = false
    }

    if (!rv) {
      setErrors(prev => ({ ...prev, [key]: true }))
    } else {
      setErrors(prev => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setLoginForm({
      ...loginForm,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const validate = () => {
    let hasError = false
    for (const [key, value] of Object.entries(loginForm)) {
      switch (key) {
        case "email":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.email ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "password":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.password ? hasError = true : setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        default:
          break;
      }
    }

    return hasError

  }

  const handleSubmit = async () => {
    setLoading(true)
    const hasError = validate()
    if (hasError) {
      setLoading(false)
      return
    }
    try {
      //backendLogin(loginForm)
      loginUser(loginForm)
    } catch (e) {
      setLoading(false)
    }

  };

  const addUserToGuest = (guest: any) => {
    if (eventId) {
      handleAddGuestToEvent(guest)
    } else {
      handleAddGuestToMain(guest)
    }
  }

  const handleAddGuestToEvent = async (guest: any) => {
    try {
      let body = {
        type: 'add',
        funeralId: id,
        eventId: eventId,
        guest: {
          firstName: guest?.firstName,
          lastName: guest?.lastName,
          phoneNumber: guest?.phoneNumber,
          email: guest?.emailAddress
        }
      }
      let rv = await updateEventGuest(body).unwrap()

      if(rv.status === 'error') {
        setToast({
          ...toast,
          message: rv.message,
          type: 'error',
          open: true
        })
      }else {
        setToast({
          ...toast,
          message: rv.message,
          type: 'success',
          open: true
        })
      }
      return true
    }catch(e) {
      console.error('rejected', e);
    }

  }

  const handleAddGuestToMain = async (guest: any) => {
    try {
      let body = {
        type: 'add',
        funeralId: id,
        guest: {
          firstName: guest?.firstName,
          lastName: guest?.lastName,
          phoneNumber: guest?.phoneNumber,
          email: guest?.emailAddress
        }
      }
      let rv = await updateMainGuest(body).unwrap()

      if(rv.status === 'error') {
        setToast({
          ...toast,
          message: rv.message,
          type: 'error',
          open: true
        })
      }else {
        setToast({
          ...toast,
          message: rv.message,
          type: 'success',
          open: true
        })
      }
      return true
    }catch(e) {
      console.error('rejected', e);
    }

  }

  return (
    type && type === 'secondary' ?
      <>
        <Box component={'form'}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} onChange={handleChange} size="small" sx={{ width: { xs: '100%', md: '365px' } }} required id="email" name="loginEmail" placeholder='Email' className={styles.input} />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <CustomTextField error={errors.password} helperText={errors.password ? 'Requires a password' : ''} onChange={handleChange} size="small" required id="password" name="loginPassword" placeholder='Password'
                type={showPassword ? "text" : "password"} sx={{ width: { xs: '100%', md: '365px' } }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      className={styles.icon}
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                    </IconButton>
                  )
                }} />
            </Grid>
            <Grid item xs={12} sx={{ pt: 1 }}>
              {
                loginFail.error ? <div className={styles.error}>{loginFail.message}</div> : <div style={{ height: 5 }}></div>
              }
            </Grid>
            <Grid item xs={12} >
              <Button sx={{ width: { xs: '100%', md: '365px' } }} variant="contained" className="btnPrimary" loading={loading} onClick={handleSubmit}>Login</Button>
            </Grid>
          </Grid>
        </Box>
      </>
      :
      <>
        <Toast {...toast} />
        <div className={styles.loginModal}>
          <Card className={styles.card}>
            <div className='sectionTitle'>Login to Contribute</div>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <InputLabel sx={{ mb: 1 }} htmlFor="email">Email</InputLabel>
                <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} onChange={handleChange} size="small" required id="email" name="loginEmail" placeholder='Email' fullWidth className={styles.input} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ mb: 1 }} htmlFor="password">Password</InputLabel>
                <CustomTextField error={errors.password} helperText={errors.password ? 'Requires a password' : ''} onChange={handleChange} size="small" required id="password" name="loginPassword" placeholder='Password' fullWidth className={styles.input}
                  type={showPassword ? "text" : "password"} sx={{ mb: 1 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={styles.icon}
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                      </IconButton>
                    )
                  }} />
              </Grid>

            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Link className='link' sx={{ textDecorationColor: 'var(--TLC-gray)' }} onClick={() => onClick(3)}>Forgot password</Link>
              </Grid>
            </Grid>
            <CustomFailMsg error={loginFail.error} message={loginFail.message} />
            <Button fullWidth variant="contained" className="btnSecondary" loading={loading} onClick={handleSubmit}>Login</Button>
            <Typography align="center" sx={{ mb: 3, mt: 2 }}>
              <Link className='link' sx={{ textDecorationColor: 'var(--TLC-gray)' }} onClick={() => onClick(2)}>Register an account</Link>
            </Typography>
          </Card>
        </div>
      </>
  )
}

export default LoginForm