import React, { useState, useEffect }from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Badge from '@mui/material/Badge'
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../base/Textfield/CustomTextField';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../base/Select/CustomSelect';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Button from '../base/Button/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '../base/Icons/EditIcon';
import styles from './Share.module.css'
import Toast from '../base/Toast/Toast';
import { formatDate } from '../../constants';
import { useBroadcastMutation, useGetBroadcastQuery, useUpdateBroadcastMutation } from '../../features/rfmEdit/rfmEditRestApi';
import { useGetImageUrlMutation } from '../../features/base/baseRestApi';

interface ShareProps {
  account: any
  eventlist: any
  email: any
}

interface EmailEditContentProps {
  deceased: any
  imageUrl: any
  share: string
  isEdit: boolean
  handleSave: (email: any) => void
}

const EmailContent = (props: EmailEditContentProps) => {
  const { id } = useParams()
  const {deceased, imageUrl, isEdit, share, handleSave } = props
  const [preview, setPreview] = useState(false)
  const [email, setEmail] = useState({
    subject: "",
    message: ""
  })
  const getBroadcast = useGetBroadcastQuery(id, {refetchOnMountOrArgChange: true})
  
  useEffect(() => {
    if(getBroadcast.isSuccess && getBroadcast.data) {
      let text = getBroadcast.data?.message.replace(/<br \/>?/g, '\n');
      setEmail({
        ...email,
        subject: getBroadcast.data?.subject,
        message: text
      })
    }
    
  }, [getBroadcast])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    if(key === 'message') {
      value = value.replace(/\n\r?/g, '<br />');
      setEmail({
        ...email,
        message: value
      })
    }else {
      setEmail({
        ...email,
        subject: value
      })
    }
  }

  if(isEdit) {
    return (
      <Box sx={{mt:4}}>
        <Box>
          <InputLabel>Subject</InputLabel>
          <CustomTextField className='text-input' sx={{width: {xs:'100%', lg:'500px'}, mb:2}} value={email.subject} onChange={handleChange} id="subject" name="subject" size='small'/> 
        </Box>
        <Box sx={{mt:4}}>
          <InputLabel>Contents</InputLabel>
          <CustomTextField className='text-input' sx={{width: {xs: '100%', lg:'500px'}, mb:4}} multiline rows={6} value={email.message} onChange={handleChange} id='message' name='message' size='small'/>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiLink sx={{mr:2, cursor: 'pointer'}} onClick={() => setPreview(true)}>Preview Email</MuiLink>
            <Button className='btnPrimary' sx={{width: {xs: '100%', sm: 270}}} onClick={() => handleSave(email)}>Save</Button>
          </Grid>
        </Grid> 

        <Dialog
          open={preview}
          fullWidth={true}
          scroll={'paper'}
          onClose={() => setPreview(false)}
        >
          <DialogContent>
              <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
                <img 
                  src={`/images/TLC-MAIN-LOGO.png`}
                  width={'60%'}
                  alt="img"
                  loading="lazy"
                />
              </Box>
                
              { imageUrl ? <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
                <img 
                  src={`${imageUrl}`}
                  width={'100%'}
                  alt="img"
                  loading="lazy"
                />
              </Box> : <></>
              }
              <Box sx={{pb:2}}>
                <Box className={styles.nameBox}>
                  <div className={styles.deceasedName}>{deceased.name}</div>
                  <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                </Box>
              </Box>
              <Box style={{width: '100%', margin: 'auto'}}>
                <Box sx={{pb:4, width:'100%'}}>
                  <div className={styles.emailSubj}>{email.subject}</div>
                  <p className={styles.emailBody} style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: email.message }}>
                  </p>
                </Box>
                <MuiLink href={share} target='_blank' sx={{textDecoration: 'none'}}>
                  <Button className='btnPrimary' sx={{width: {xs: '300px'}}}>
                    Visit
                  </Button>
                </MuiLink>
              </Box>
          </DialogContent>
          
        </Dialog>
      
      </Box>
      
    )
  }else {
    return (
      <Box sx={{py: 4}} className={styles.emailContainer}>
        {/* <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
          <img 
            src={`/images/TLC-MAIN-LOGO.png`}
            width={'60%'}
            alt="img"
            loading="lazy"
          />
        </Box> */}
        
        <Box sx={{pb:4, width:'80%'}}>
          <div className={styles.emailSubj}>{email.subject}</div>
          <p className={styles.emailBody} style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: email.message }}>
          </p>
        </Box>
        <MuiLink href={share} target='_blank' sx={{textDecoration: 'none'}}>
          <Button className='btnPrimary' sx={{width: {xs: '300px'}}}>
            Visit
          </Button>
        </MuiLink>
      </Box>
    )
  }
  
}

const Share: React.FC<ShareProps> = (props) => {
  const { id } = useParams()
  const { account, eventlist } = props
  const dispatch = useAppDispatch()
  const [share, setShare] = useState('')
  const [events, setEventlist] = useState<any[]>(eventlist)
  const [selectedEvent, setEvent] = useState<any>('')
  const [imageURL, setImageUrl] = useState('')
  const [deceased, setDeceased] = useState({
    name: '',
    lifespan: ''
  })
  const [email, setEmail] = useState({
    subject: "",
    message: ""
  })
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const [preview, setPreview] = useState(false)

  const [isEdit, setEdit] = useState(false)
  const getBroadcast = useGetBroadcastQuery(id, {refetchOnMountOrArgChange: true})
  const [getImageUrl] = useGetImageUrlMutation()
  const [broadcast] = useBroadcastMutation()
  const [updateBroadcast] = useUpdateBroadcastMutation()

  useEffect(() => {
    if(getBroadcast.isSuccess && getBroadcast.data) {
      let text = getBroadcast.data?.message.replace(/<br \/>?/g, '\n');
      setEmail({
        subject: getBroadcast.data?.subject,
        message: text
      })
    }
    
  }, [getBroadcast])

  useEffect(() => {
    let sharelink = `${process.env.REACT_APP_URL}${account.lastName}/${account.id}`
    setShare(sharelink)
    if(account.profile_image) {
      getURL(account.profile_image)
    }
    let user = {
      firstName: account.firstName,
      lastName: account.lastName,
      dob: account.dob,
      dod: account.dod
    }
    let name = user.lastName.charAt(0).toUpperCase() + user.lastName.substring(1)
    let lifespan = formatDate(user.dob, 'DD MMM YYYY') + ' - ' + formatDate(user.dod, 'DD MMM YYYY')
    setDeceased({
      name: name,
      lifespan: lifespan
    })

  }, [account.firstName, account.lastName, account.id])

  useEffect(() => {
    setEventlist([
      {id: 0, funeralId: id, type: 'Master'},
      ...eventlist
    ])
  }, [eventlist])

  useEffect(() => {
    if(events) {
      setEvent(events[0])
    }
  }, [events])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({image: filename}).unwrap()
      setImageUrl(rv.imageUrl)
    }catch(e) {

    }
  }

  const handleEventChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setEvent(value)
  }

  const handleEdit = () => {
    setEdit(true)
  }

  const handleSaveEdit = async (email: any) => {
    try {
      let body = {
        funeralId: id,
        ...email
      }
      let rv = await updateBroadcast(body).unwrap()
      if(rv) {
        setEdit(false)
      }
    }catch(e) {

    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(share.replaceAll(' ', '%20'));
  }

  const handleSend = async () => {
    try {
     let body = {
        ...email,
        ...deceased,
        filename: account.profile_image,
        href: share,
        funeralId: account.id,
        eventId: selectedEvent.id
      }
      let rv = await broadcast(body).unwrap()

      if(rv) {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: rv.message
        })
      }
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        type: 'error',
        message: e.data.message
      })
    } 
  }
  
  return (
    <Box>
      <Toast {...toast}/>
      <Grid container spacing={2} sx={{py:2}} justifyContent={'space-between'}>
        <Grid item xs={12} md={6}>
          <Box>
            {/* {
              imageURL ? 
              <Box style={{backgroundImage: `url(${imageURL})`, backgroundSize: 'cover',width:'100%', height:'500px'}}>
                <Box className={styles.absBox}>
                  <Box className={styles.nameBox}>
                    <div className={styles.deceasedName}>{deceased.name}</div>
                    <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                  </Box>
                </Box>
              </Box> : 
              <Box>
                  <Box className={styles.nameBox}>
                    <div className={styles.deceasedName}>{deceased.name}</div>
                    <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                  </Box>
              </Box>
            } */}
            { imageURL ? <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
              <img 
                src={`${imageURL}`}
                width={'100%'}
                alt="img"
                loading="lazy"
              />
            </Box> : <></>
            }
            <Box>
                <Box className={styles.nameBox}>
                  <div className={styles.deceasedName}>{deceased.name}</div>
                  <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
                </Box>
            </Box>
          </Box>
          <EmailContent isEdit={isEdit} deceased={deceased} imageUrl={imageURL} share={share} handleSave={handleSaveEdit} />
       
        </Grid>
        
        

        {
          !isEdit && (
            <Grid item xs={12} md={4}>
              <InputLabel>Share Message</InputLabel>
              <CustomTextField disabled sx={{width: '100%'}} size='small' value={share.replaceAll(' ', '%20')} className={styles.shareLink}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {/* <InputLabel sx={{mt:4}}>Broadcast</InputLabel>
              <div>Broadcast this event to this guest list</div>
              <CustomSelect
                  className={styles.input}
                  sx={{width:  '100%', mb: 4}}
                  size='small'
                  labelId="action"
                  value={selectedEvent || ""}
                  onChange={handleEventChange}
                >
                  {events.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                    >
                      {item.type + ' ' + formatDate(item.startDate, 'DD MMM YYYY')}
                    </MenuItem>
                  ))}
              </CustomSelect>
              <Button className='btnTransparent' sx={{width: {xs:'100%', sm:'300px'}, mt:4}} onClick={handleSend}>Send</Button> */}
            </Grid>
          )
        }

        { isEdit ? <></> :
        <Grid item xs={12}>
          <MuiLink className={styles.link} onClick={handleEdit} style={{cursor: 'pointer'}}>
            <span>Edit</span>
            <Badge badgeContent={0} sx={{ml:1}}>
              <EditIcon/>
            </Badge>
          </MuiLink>
        </Grid>
        }
      </Grid>
      <Dialog
        open={preview}
        fullWidth={true}
        scroll={'paper'}
        onClose={() => setPreview(false)}
      >
        <DialogContent>
            <Box sx={{mb:4}} style={{display:'flex', justifyContent: 'center'}}>
              <img 
                src={`/images/TLC-MAIN-LOGO.png`}
                width={'60%'}
                alt="img"
                loading="lazy"
              />
            </Box>
            <Box style={{display:'flex', justifyContent: 'center'}}>
              <img 
                src={`${imageURL}`}
                width={'100%'}
                alt="img"
                loading="lazy"
              />
            </Box>
            <Box sx={{pb:2}}>
              <Box className={styles.nameBox}>
                <div className={styles.deceasedName}>{deceased.name}</div>
                <div className={styles.deceasedLifespan}>{deceased.lifespan}</div>
              </Box>
            </Box>
            <Box style={{width: '100%', margin: 'auto'}}>
              <Box sx={{pb:4, width:'100%'}}>
                <div className={styles.emailSubj}>{email.subject}</div>
                <p className={styles.emailBody} style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: email.message }}>
                </p>
              </Box>
              <MuiLink href={share} target='_blank' sx={{textDecoration: 'none'}}>
                <Button className='btnPrimary' sx={{width: {xs: '300px'}}}>
                  Visit
                </Button>
              </MuiLink>
            </Box>
        </DialogContent>
        
      </Dialog>
    </Box>
  )
}

export default Share