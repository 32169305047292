import { useGetProductsQuery } from './productGqlApi';
import ProductList from './ProductList'

function Products() {
  const { data,  isLoading } = useGetProductsQuery('')
  if(!isLoading) {
    return (
      <ProductList data={data}/>
    )
  }else {
    return (
      <div>Loading...</div>
    )
  }
  
}

export default Products