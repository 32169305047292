import React, {useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTransitionOrderToStateMutation, useAddPaymentToOrderMutation } from '../../features/payment/paymentGqpApi';

function Confirm() {
    const [message, setMessage] = useState(null);

    const { orderCode } = useParams();
    const [addPaymentToOrder] = useAddPaymentToOrderMutation();
    // const [payment_intent, payment_intent_client_secret, redirect_status] = useSearchParams();

    return (
        <>
            <p>Success! Your order is processing!</p>
        </>
    )
}

export default Confirm;