import React, { useState, useEffect } from "react"
import { Box, Typography, Grid, Avatar } from "@mui/material"
import WebviewNotes from "../../components/webview/notes/WebviewNotes"
import CondolenceMessage from "../../components/webview/condolenceMessage/CondolenceMessage"
import CondolenceList from "../../components/webview/condolenceList/CondolenceList"
import DonationSection from "../../components/donate/DonationSection"
import Give from "../give/Give"
import WebviewEvent from "../../components/webview/event/WebviewEvent"
import Overlay from "../../components/Overlay/Overlay"
import { useAppSelector } from "../../app/hooks"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { getUserState } from "../../features/user/userSlice"
import { useGetImageUrlMutation } from "../../features/base/baseRestApi"
import Button from "../../components/base/Button/Button"
import { useParams, Link, useNavigate } from "react-router-dom"
import { getCurrentWidthDimension } from "../../constants"
import styles from "./About.module.css"
import { useGetCondolenceMessagelistQuery } from "../../features/webview/condolenceList/condolenceListRestApi"

interface obituarySectionProps {
  message: string
  obituary_image: string
}

const ObituarySection: React.FC<obituarySectionProps> = (props) => {
  const { message, obituary_image } = props
  const [obituaryImg, setObituaryImg] = useState("")
  const [obiMsg, setObiMsg] = useState("")

  useEffect(() => {
    if (obituary_image) {
      getURL(obituary_image)
    }
  }, [obituary_image])

  useEffect(() => {
    if (message) {
      let more = ""
      if (message && message.length >= 200) {
        more = "..."
      }
      setObiMsg(message + more)
    }
  }, [message])

  const [getImageUrl] = useGetImageUrlMutation()

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setObituaryImg(rv.imageUrl)
    } catch (e) {}
  }

  return (
    <Box>
      <Typography component={"p"} className="sectionTitle">
        Obituary
      </Typography>
      <Grid container spacing={2} sx={{ pt: 1, tb: 1 }} justifyContent="space-between">
        <Grid item xs={12} lg={9}>
          <div dangerouslySetInnerHTML={{ __html: obiMsg }}></div>
          <br />
        </Grid>
        <Grid item sx={{ py: 1, pr: 2 }}>
          {obituary_image ? <img src={obituaryImg} className="img" style={{ maxWidth: "200px" }} /> : <></>}
        </Grid>
      </Grid>
    </Box>
  )
}

function About() {
  const navigate = useNavigate()
  const funeralState = useAppSelector(getFuneralState)
  const { obituary_image } = funeralState.details
  const isEdit = funeralState.isEdit
  const { events } = funeralState
  const [upcomingEvent, setUpcomingEvent] = useState<any>([])
  const [screenWidth, setScreenWidth] = useState(0)

  const { id } = useParams()
  const { isLoggedIn, funeral } = useAppSelector(getUserState)
  const { data, isLoading } = useGetCondolenceMessagelistQuery({ funeralId: id, isLoggedIn: isLoggedIn }, { refetchOnMountOrArgChange: true })
  const [messages, setMessages] = useState<any[]>([])
  const [limit, setLimit] = useState<number>(4)

  useEffect(() => {
    if (data && !isLoading) {
      if (limit && data.length !== limit) {
        setMessages(data.slice(0, limit))
      } else {
        setMessages(data)
      }
    }
  }, [data, isLoading])

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener("resize", updateDimension)
  }, [screenWidth])

  useEffect(() => {
    let date = new Date().setHours(0, 0, 0, 0)

    let upcomingEvents: any = []

    for (var item of events) {
      if (new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
        upcomingEvents = [...upcomingEvents, item]
      }
    }

    function compareEvents(event1: any, event2: any) {
      // Compare startDates
      if (event1.startDate < event2.startDate) {
        return -1
      }
      if (event1.startDate > event2.startDate) {
        return 1
      }

      // If startDates are equal, compare startTimes
      if (event1.startTime < event2.startTime) {
        return -1
      }
      if (event1.startTime > event2.startTime) {
        return 1
      }

      return 0 // If both startDate and startTime are equal
    }

    upcomingEvents.sort(compareEvents)

    setUpcomingEvent(upcomingEvents)
  }, [events])

  const handleClick = () => {
    navigate("../obituary")
  }

  const handleSeeMoreMessage = () => {
    navigate("../messages")
  }

  const handleSubmitMessage = () => {
    navigate("../messages")
  }

  return (
    <div>
      {(obituary_image || isEdit) && (
        <Box>
          <Box sx={{ display: "flex", mt: 4, mb: 2 }}>
            <img src={`/images/icons/orbituary.svg`} alt="img" loading="lazy" />
            <Typography component={"div"} variant={"body2"} className="sectionTitleAbout" sx={{ ml: 2 }}>
              Obituary
            </Typography>
          </Box>
          <WebviewNotes />
        </Box>
      )}

      <Overlay>
        {upcomingEvent && (
          <Box sx={{ py: 2 }}>
            <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
              <img src={`/images/icons/upcoming_gray.svg`} alt="img" loading="lazy" />
              <Typography
                component={"div"}
                className="sectionTitleAbout"
                sx={{
                  ml: 2
                }}
              >
                Upcoming Services
              </Typography>
            </Box>

            {/* <WebviewEvent event={upcomingEvent} isOver={false} type={'about'} /> */}

            {upcomingEvent.map((event: any, idx: number) => (
              <Box key={idx} mb={"15px"}>
                <WebviewEvent event={event} isOver={false} type={"about"} />
              </Box>
            ))}
          </Box>
        )}

        {/* {
          funeralState.details.showObituary ? (
            <>
              <Box sx={{py: 4 }}>
                <ObituarySection message={obituary} obituary_image={obituary_image}/>
                <Box>
                  <Button type="submit" sx={{width: {md: '300px', sm: '300px'}}} variant="contained" className="btnSecondary" onClick={handleClick} style={{position: 'absolute', bottom: 0}} fullWidth>
                    Read More
                  </Button>
                </Box> 
              </Box>

              <Typography  component={'div'} variant={'body2'} className="hr"></Typography>
            </>
          ): null
        } */}

        <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
          <img src={`/images/icons/hearfelt_msg.svg`} alt="img" loading="lazy" />
          <Typography component={"div"} variant={"body2"} className="sectionTitleAbout" sx={{ ml: 2 }}>
            Heartfelt Messages
          </Typography>
        </Box>

        {messages.length > 3 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button className="btnPrimary" sx={{ width: { sm: "384px" }, height: { xs: "45px" }, display: "flex" }} onClick={handleSubmitMessage} fullWidth>
                Write A Heartfelt Message
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
              <Button className="btnSecondary" sx={{ width: { sm: "384px" }, height: { xs: "45px" }, display: "flex", ml: "auto" }} onClick={handleSeeMoreMessage} fullWidth>
                View More Messages
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <CondolenceList limit={4} pagination={false} isSimple={true} />

        {/* <Box sx={{py: 4 }}>
          <Give />
        </Box>

        <Typography  component={'div'} variant={'body2'} className="hr"></Typography>

        <Box sx={{py: 4 }}>
          <DonationSection />
        </Box> */}
      </Overlay>
    </div>
  )
}

export default About
