import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box'
import { Hidden } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import NavMenu from '../Navbar/NavMenu';
import styles from './Footer.module.css'

function Footer() {
  return (
    <Box>
      <Box className={styles.bgmenu} sx={{mt:4}}>
        <Container maxWidth="xl">
          <NavMenu />
        </Container>
      </Box>

      <Box className={styles.footerBg}>
        <Container maxWidth="xl">
          <Grid container className={styles.footer} justifyContent={'space-evenly'}>
            <Grid xs={12} sm={6} md={3} >
              <Typography className={styles.footertext}>
                <b>ADDRESS</b><br /><br />
                Blk 89 Geylang Bahru Industrial Estate<br />
                #01-2738, Singapore 339697
              </Typography>
            </Grid>
            <Grid xs={12} md={12} sx={{display: {xs: 'block', sm:'none'}}}>
              <img src="/images/TLC-FOOTER.png" className={styles.footerBrands} style={{maxWidth: '600px', width: '100%'}}></img>
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <Typography className={styles.footertext}>
                <b>PHONE</b><br /><br />
                <b><u>+65 6684 8488</u></b><br />
              </Typography>
            </Grid>
            <Grid xs={12} md={3}>
              <Typography className={styles.footertext}>
                <b>EMAIL</b><br /><br />
                <b><u>Care@TheLifeCelerant.SG</u></b><br />
              </Typography>
            </Grid>
            
            <Hidden smDown>
            <Grid xs={12} md={3}>
              <Typography component="div" textAlign={'end'}>
                <img src="/images/TLC-FOOTER-LOGO.png" className={styles.logofooter}></img>
              </Typography>
            </Grid>
            </Hidden>
          </Grid>
          <Grid container spacing={2} sx={{display: {xs: 'none', sm:'block'}}}>
            <Grid xs={12} md={12}>
              <img src="/images/TLC-FOOTER.png" className={styles.footerBrands} style={{maxWidth: '600px', width: '100%'}}></img>
            </Grid>
          </Grid>
        </Container>
        <Typography  align="center" sx={{p: 2}}>
            <small className={styles.copyright}>© {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. </small>
            <small className={styles.copyright}>Powered by <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer"> VisionTech</a>.</small>
        </Typography>
      </Box>   
    </Box>

  )
}

export default Footer