import { Visibility, VisibilityOff } from "@mui/icons-material"
import { MenuItem, Box, Button as MuiButton, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, Select, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material"
import React, { useState, useEffect, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { formatDate, relationships, debounce, validateName, validateEmail, validateTel, generateInvoice, validateCountryCode } from "../../constants"
import CustomTextField from "../../components/base/Textfield/CustomTextField"
import CashIcon from "../../components/base/Icons/CashIcon"
import { styled } from "@mui/material/styles"
import { useLogoutMutation, useGetActiveCustomerQuery, useSetUpdateCustomerMutation, useUpdateCustomerPasswordMutation } from "../../features/auth/authGqlApi"
import { useGetOrderHistoryQuery, useGetInviteAccountsQuery, useGetRelationshipQuery, useSetUpdateRelationshipMutation, useUpdateInvitesNameMutation } from "../../features/profile/profileRestApi"
import { useGetOrderByIdMutation } from "../../features/order/orderGqlApi"
import InviteProfile from "./InviteProfile"
import styles from "./Profile.module.css"
import Toast from "../../components/base/Toast/Toast"
import Button from '../../components/base/Button/Button'
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomSelect = styled(Select)({
  "&": {
    //   background: 'transparent'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)",
    borderRadius: "3px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  }
})

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

type userInfo = {
  firstName: string
  lastName: string
  emailAddress: string
  countryCode: string
  phoneNumber: string
  relationship: string
}

type passwordGroup = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

function Profilepage() {
  const [toast, setToast] = useState({
    open: false,
    type: "success",
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const { data, isLoading, refetch } = useGetActiveCustomerQuery("")
  const activeCustomer = data?.activeCustomer

  const userId = activeCustomer?.id
  const orderResponse = useGetOrderHistoryQuery({ userId })
  const orders = orderResponse?.data

  const [orderId, setOrderId] = useState(0)
  const [order, setOrder] = useState({
    code: "",
    date: "",
    status: "",
    event: "",
    address: "",
    country: "",
    lines: [],
    subTotal: 0,
    gst: 8,
    subTotalWithTax: 0,
    shippingWithTax: 0
  })

  const [relationship, setRelationship] = useState('-')
  const userEmail = activeCustomer?.emailAddress
  const { id } = useParams()
  const relationshipResponse: any = useGetRelationshipQuery({ userEmail, id }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (relationshipResponse.data != null) {
      setRelationship(relationshipResponse.data.relationship)
    } else {
      setRelationship("-")
    }
  }, [relationshipResponse])

  useEffect(() => {
    if (orderResponse.data) {
    }
  }, [orderResponse])

  const inviteResponse: any = useGetInviteAccountsQuery({ userEmail, id })
  const inviteAccounts = inviteResponse.data

  const [setUpdateCustomerPassword] = useUpdateCustomerPasswordMutation()

  const [setUpdateCustomer] = useSetUpdateCustomerMutation()
  const [setUpdateRelationship] = useSetUpdateRelationshipMutation()
  const [getOrderById] = useGetOrderByIdMutation({})
  const [updateInvites] = useUpdateInvitesNameMutation()

  const validate = (obj: { key: string; value: string }) => {
    const { key, value } = obj
    let check
    if (key === "firstName" || key === "lastName") {
      if (value) {
        check = validateName(value)
      } else check = true
    }
    if (key === "phoneNumber") {
      if (value) {
        check = validateTel(value)
      } else check = true
    }
    if (key === "countryCode") {
      if (value) {
        check = validateCountryCode(value)
      } else check = true
    }
    if (key === "emailAddress") {
      if (value) {
        check = validateEmail(value)
      } else check = true
    }

    if (!check) {
      setErrors((prev) => ({ ...prev, [key]: true }))
    } else {
      setErrors((prev) => ({ ...prev, [key]: false }))
    }

  }

  const [userInfo, setUserInfo] = useState<userInfo>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    relationship: "",
    countryCode: "",
  })

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    emailAddress: false,
    countryCode: false,
  })

  const [failMsg, setFailMsg] = useState<any>({
    error: false,
    message: ""
  })

  const [passwordGroup, setPasswordGroup] = useState<passwordGroup>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  useEffect(() => {
    if (failMsg.error) {
      setTimeout(() => {
        setFailMsg({
          error: false,
          message: ""
        })
      }, 5000)
    }
  }, [failMsg])

  useEffect(() => {
    let initial = {
      firstName: activeCustomer?.firstName,
      lastName: activeCustomer?.lastName,
      emailAddress: activeCustomer?.emailAddress,
      phoneNumber: activeCustomer?.phoneNumber.split(" ")[1],
      relationship: relationship,
      countryCode: activeCustomer?.phoneNumber.split(" ")[0],
    }
    
    setUserInfo({
      ...initial
    })
  }, [activeCustomer, relationship])

  const [logoutUser] = useLogoutMutation()
  //const navigate = useNavigate()

  const handleLogout = () => {
    logoutUser(null)
    if (pageId) {
      window.location.href = "../logout"
    } else {
      window.location.href = "./logout"
    }

  }

  const resetOrderId = () => {
    setOrderId(0)
  }

  const handlePassword = (event: any) => {
    let key = event.target.name
    let value = event.target.value

    setPasswordGroup({
      ...passwordGroup,
      [key]: value
    })
  }

  const handleOrderDetail = async (event: any) => {
    const orderId = event.target.id
    try {
      const response: any = await getOrderById({ orderId })
      const temp = response.data.order
      let gst = 0
      for (var item of temp.taxSummary) {
        if (item.description.toLowerCase().includes('gst')) {
          gst = item.taxRate
        }
      }
      setOrder({
        code: temp.customFields.orderId,
        date: temp.updatedAt,
        status: temp.state,
        event: temp.shippingAddress.customFields.eventId,
        address: temp.shippingAddress.streetLine1 + ", " + temp.shippingAddress.streetLine2,
        country: temp.shippingAddress.country + ", " + temp.shippingAddress.postalCode,
        lines: temp.lines,
        subTotal: temp.subTotal,
        gst: gst,
        subTotalWithTax: temp.subTotalWithTax,
        shippingWithTax: temp.shippingWithTax
      })
    } catch (e) {
      console.error(e)
    }
    setOrderId(orderId)
  }

  const handlePasswordChange = async () => {
    if (passwordGroup.newPassword !== passwordGroup.confirmPassword) {
      setToast({
        ...toast,
        open: true,
        message: "New password unmatched!",
        type: "error"
      })
      return null
    } else {
      const currentPassword = passwordGroup.currentPassword
      const newPassword = passwordGroup.newPassword
      let message: string
      try {
        const response: any = await setUpdateCustomerPassword({ currentPassword, newPassword })
        if (response.data.updateCustomerPassword.success) {
          message = "Password changed successfully!"
          setToast({
            ...toast,
            open: true,
            message: message,
            type: "success"
          })
          setPasswordGroup({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
          })
        } else {
          message = response.data.updateCustomerPassword.message
          setToast({
            ...toast,
            open: true,
            message: message,
            type: "error"
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const [value, setValue] = React.useState(0)

  const { pageId } = useParams();
  useEffect(() => {
    if (pageId != undefined) {
      const preValue: number = +pageId
      setValue(preValue)
    }
  }, [pageId])


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleClickShowCurrentPassword = (event: any) => {
    setShowCurrentPassword((show) => !show)
  }
  const handleClickShowNewPassword = (event: any) => {
    setShowNewPassword((show) => !show)
  }
  const handleClickShowConfirmPassword = (event: any) => {
    setShowConfirmPassword((show) => !show)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const debounceHandler = useCallback(debounce(validate), [])

  const handleChange = (event: any) => {
    let key = event.target.name
    let value = event.target.value

    setUserInfo({
      ...userInfo,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const validateUserInfo = (item: any, showError: boolean) => {
    let hasError = false
    for (const [key, value] of Object.entries<string>(item)) {
      switch (key) {
        case "firstName":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateName(value)
            if (check) {
              hasError = false
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "lastName":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateName(value)
            if (check) {
              hasError = false
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "emailAddress":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateEmail(value)
            if (check) {
              hasError = false
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "phoneNumber":
          if (value.trim()) {
            let check = validateTel(value)
            if (check) {
              hasError = false
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "countryCode":
          if (value.trim()) {
            let check = validateCountryCode(value)
            if (check) {
              hasError = false
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        default:
          break
      }
    }
    return hasError
  }

  const handleSave = async () => {
    const hasError = validateUserInfo(userInfo, true)
    if (hasError) {
      return
    }
    const { firstName, lastName, emailAddress, phoneNumber, relationship } = userInfo
    if (activeCustomer.firstName !== firstName || activeCustomer.lastName !== lastName || activeCustomer.phoneNumber !== phoneNumber) {
      try {
        const response: any = await setUpdateCustomer({ firstName, lastName, phoneNumber: `${userInfo.countryCode} ${userInfo.phoneNumber}` })
        if (response.data.updateCustomer.__typename === "Customer") {
          //update invites
          let rv = await updateInvites({ firstName, lastName, phoneNumber: `${userInfo.countryCode} ${userInfo.phoneNumber}` }).unwrap()
          if (rv) {
            setToast({
              ...toast,
              open: true,
              message: "Successfully Updated Account Details!",
              type: "success"
            })
          }

        } else {
          setToast({
            ...toast,
            open: true,
            message: "Invalid Account Information!",
            type: "error"
          })
        }
      } catch (e) {
        console.error("debug error = ", e)
      }
    }
    if (relationship !== relationshipResponse.data.relationship) {
      try {
        const response: any = await setUpdateRelationship({ id, userEmail, relationship })
        if (response.data === "yes") {
          setToast({
            ...toast,
            open: true,
            message: "Successfully Updated Account Details!",
            type: "success"
          })
        }
        // refetch()
      } catch (e) {
        console.error("debug error = ", e)
      }
    }
  }

  const handleDownloadReceipt = async () => {
    generateInvoice({
      order: order,
      outputType: 'save',
      filename: 'invoice',
      customer: userInfo
    })
    /* document.getElementById("viewportMeta")?.setAttribute("content", "width=1920px");
    const input: any = document.getElementById('receipt');
    if(window.innerWidth > 768) {
      input.style.width = '50%'
    }
    const height = input.clientHeight
    let pageHeight: number = 1136
    const pdf: any = new jsPDF({
      orientation: "portrait",
      unit: 'px',
      hotfixes: ["px_scaling"],
      format: 'a4'
    })
    html2canvas(input, {
      scale: 1,
      useCORS: true,
    })
      .then((canvas) => {
          let position = 0;
          const imgData = canvas.toDataURL();
          let imgWidth = canvas.width
          let imgHeight = ((canvas.height * imgWidth) / 600)*1.24;

          let heightLeft = imgHeight
          if(canvas.height > pageHeight) {
            pdf.addImage(imgData, 'JPEG', 2,position,canvas.width, imgHeight);
          }else {
            pdf.addImage(imgData, 'JPEG', 2,position,canvas.width, canvas.height);
          }
          heightLeft -= pageHeight;
          
          while (heightLeft >= 0) {
            let position = heightLeft - imgHeight;
            
            pdf.addPage();
            pdf.addImage(imgData, "JPEG", 0, position, canvas.width, imgHeight);
            heightLeft -= pageHeight;
          }
          
          //pdf.addImage(imgData, 'JPEG', 2,position,canvas.width, canvas.height);
          pdf.save("receipt.pdf");
      })
      
    if(window.innerWidth > 768) {
      input.style.width = '100%'
    }
    document.getElementById("viewportMeta")?.setAttribute("content", "width=device-width, initial-scale=1"); */
  }

  if (!isLoading && activeCustomer !== undefined && orders !== undefined) {
    let purchaseUI
    if (window.innerWidth > 768) {
      if (orderId !== 0) {
        purchaseUI = (
          <Box id="receipt">
            <Grid container item sx={{ mt: 3 }}>
              <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                OID
              </Grid>
              <Grid item xs={10} sx={{ mt: 1, mb: 1 }}>
                #{order.code}
              </Grid>
              <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                Date:
              </Grid>
              <Grid item xs={10} sx={{ mt: 1, mb: 1 }}>
                {formatDate(order.date, "DD/MM/YYYY")}
              </Grid>
              <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                Status
              </Grid>
              <Grid item xs={10} sx={{ mt: 1, mb: 1 }}>
                {order.status.replace(/([a-zA-Z])(?=[A-Z])/g, "$1 ")}
              </Grid>
              <Grid item xs={2} sx={{ mt: 1, mb: 1 }}>
                Address:
              </Grid>
              <Grid item xs={10} sx={{ mt: 1, mb: 1 }}>
                {order.event}
                <br />
                {order.address}
                <br />
                {order.country}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} sx={{ mt: 4, mb: 1 }}>
                <Typography component={"p"} className="sectionTitle">
                  <b>Order Summary</b>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ mt: 4, mb: 1, maxWidth: 'fit-content!important' }} data-html2canvas-ignore="true">
                <MuiButton className={styles.btnBack} variant="outlined" onClick={resetOrderId}>Back</MuiButton>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <br />
            <Grid>
              {order.lines.map((item: any, index: any) => {
                return (
                  <Box key={index}>
                    <Grid container item>
                      <Grid item xs={2}>
                        <Box
                          component="img"
                          sx={{
                            height: 80,
                            width: 80
                          }}
                          src={item.featuredAsset.preview}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography className={styles.productPrice}>
                          {item.quantity} x {item.productVariant.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={styles.productPrice} align="right">
                          ${((item.quantity * item.productVariant.price) / 100).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography component={"div"} variant={"body2"} sx={{ mt: 2, mb: 2 }} className="hr"></Typography>
                  </Box>
                )
              })}
            </Grid>
            <Grid container item sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={7}></Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="left">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={styles.productPrice} align="right">
                  ${(order.subTotal / 100).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="left">
                  GST(8%)
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={styles.productPrice} align="right">
                  ${((order.subTotalWithTax - order.subTotal) / 100).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="left">
                  Delivery
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={styles.productPrice} align="right">
                  ${(order.shippingWithTax / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Grid container item sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={7}>
                {/* <table>
                  <tr>
                    <td><CashIcon /></td>
                    <td>**** 4242</td>
                  </tr>
                </table> */}
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="left">
                  Total
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={styles.productPrice} align="right">
                  ${((order.subTotalWithTax + order.shippingWithTax) / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Grid container sx={{ mt: 4 }} data-html2canvas-ignore="true">
              <Grid item xs={12}>
                <Button className='btnSecondary' sx={{ width: { md: '400px' } }} onClick={handleDownloadReceipt} fullWidth>Download Receipt</Button>
              </Grid>
            </Grid>
          </Box>
        )
      } else {
        purchaseUI = (
          <>
            <Typography sx={{ pt: 3, pb: 1, pl: 0, pr: 0 }} className="sectionPara">
              Purchases
            </Typography>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <InputLabel className="sectionText">Date</InputLabel>
                    </TableCell>
                    <TableCell align="left">
                      <InputLabel className="sectionText">OID</InputLabel>
                    </TableCell>
                    <TableCell align="left">
                      <InputLabel className="sectionText">Total</InputLabel>
                    </TableCell>
                    <TableCell align="left">
                      <InputLabel className="sectionText">Status</InputLabel>
                    </TableCell>
                    <TableCell align="center">
                      <InputLabel className="sectionText">View</InputLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((row: any) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <InputLabel className="sectionText">{formatDate(row.updatedAt, "DD/MM/YYYY")}</InputLabel>
                      </TableCell>
                      <TableCell>
                        <InputLabel className="sectionText">#{row.customFieldsOrderid}</InputLabel>
                      </TableCell>
                      <TableCell align="left">
                        <InputLabel className="sectionText">${((row.subTotalWithTax + row.shippingWithTax) / 100).toFixed(2)}</InputLabel>
                      </TableCell>
                      <TableCell align="left">
                        <InputLabel className="sectionText">{row.state.replace(/([a-zA-Z])(?=[A-Z])/g, "$1 ")}</InputLabel>
                      </TableCell>
                      <TableCell align="center">
                        <MuiButton id={row.id} className="link" sx={{ fontSize: '16px' }} onClick={handleOrderDetail}>
                          View Order
                        </MuiButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    } else {
      if (orderId !== 0) {
        purchaseUI = (
          <Box id="receipt">
            <Grid container item sx={{ mt: 3 }}>
              <Grid item xs={4} sx={{ mt: 1, mb: 1 }}>
                OID
              </Grid>
              <Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
                #{order.code}
              </Grid>
              <Grid item xs={4} sx={{ mt: 1, mb: 1 }}>
                Date:
              </Grid>
              <Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
                {formatDate(order.date, "DD/MM/YYYY")}
              </Grid>
              <Grid item xs={4} sx={{ mt: 1, mb: 1 }}>
                Status
              </Grid>
              <Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
                {order.status.replace(/([a-zA-Z])(?=[A-Z])/g, "$1 ")}
              </Grid>
              <Grid item xs={4} sx={{ mt: 1, mb: 1 }}>
                Address:
              </Grid>
              <Grid item xs={8} sx={{ mt: 1, mb: 1 }}>
                {order.event}
                <br />
                {order.address}
                <br />
                {order.country}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} sx={{ mt: 4, mb: 1 }}>
                <Typography component={"p"} className="sectionTitle">
                  <b>Order Summary</b>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ mt: 4, mb: 1, maxWidth: 'fit-content!important' }} data-html2canvas-ignore="true">
                <MuiButton className={styles.btnBack} variant="outlined" onClick={resetOrderId}>Back</MuiButton>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <br />
            <Grid>
              {order.lines.map((item: any, index: any) => {
                return (
                  <Box key={index}>
                    <Grid container key={index} item>
                      <Grid item xs={3}>
                        <Box
                          component="img"
                          sx={{
                            height: 50,
                            width: 50
                          }}
                          src={item.featuredAsset.preview}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className={styles.productPrice}>
                          {item.quantity} x {item.productVariant.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={styles.productPrice} align="right">
                          ${((item.quantity * item.productVariant.price) / 100).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography component={"div"} variant={"body2"} sx={{ mt: 2, mb: 2 }} className="hr"></Typography>
                  </Box>
                )
              })}
            </Grid>
            <Grid container item sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={9}>
                <Typography className={styles.productPrice} align="left">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="right">
                  ${(order.subTotal / 100).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={styles.productPrice} align="left">
                  GST(8%)
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="right">
                  ${((order.subTotalWithTax - order.subTotal) / 100).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={styles.productPrice} align="left">
                  Delivery
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="right">
                  ${(order.shippingWithTax / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Grid container item sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={9}>
                <Typography className={styles.productPrice} align="left">
                  Total
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={styles.productPrice} align="right">
                  ${((order.subTotalWithTax + order.shippingWithTax) / 100).toFixed(2)}
                </Typography>
              </Grid>
              {/* <Grid item xs={7}>
                <table>
                  <tr>
                    <td><CashIcon /></td>
                    <td>**** 4242</td>
                  </tr>
                </table>
              </Grid> */}
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Grid container sx={{ mt: 2 }} data-html2canvas-ignore="true">
              <Grid item xs={12}>
                <Button className='btnSecondary' onClick={handleDownloadReceipt} fullWidth>Download Receipt</Button>
              </Grid>
            </Grid>
          </Box>
        )
      } else {
        purchaseUI = (
          <>
            <Typography sx={{ pt: 3, pb: 1, pl: 0, pr: 0 }} className="sectionPara">
              Purchases
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableCell} align="left">
                      <InputLabel className="sectionText2">Date</InputLabel>
                    </TableCell>
                    <TableCell className={styles.tableCell} align="left">
                      <InputLabel className="sectionText2">OID / Status</InputLabel>
                    </TableCell>
                    <TableCell className={styles.tableCell} align="center">
                      <InputLabel className="sectionText2">View</InputLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  {orders.map((row: any) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell className={styles.tableCell}>
                        <InputLabel className="sectionText2">{formatDate(row.updatedAt, "DD/MM/YYYY")}</InputLabel>
                      </TableCell >
                      <TableCell align="left" className={styles.tableCell}>
                        <InputLabel className="sectionText2">#{row.customFieldsOrderid}<br />{row.state.replace(/([a-zA-Z])(?=[A-Z])/g, "$1 ")}</InputLabel>
                      </TableCell>
                      <TableCell align="right" className={styles.tableCell}>
                        <MuiButton id={row.id} className="link" onClick={handleOrderDetail}>
                          View
                        </MuiButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableHead>
              </Table>
            </TableContainer>
          </>
        )
      }
    }

    return (
      <Grid container item sx={{ px: 3 }}>
        <Toast {...toast} />
        <Grid item xs={12} md={9}>
          <Typography className="sectionTitle">
            <b>Profile</b>
          </Typography>
          <Box >
            <div>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="nav tabs"
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{
                  '& .MuiTabs-indicator': { backgroundColor: 'var(--TLC-gray)' },
                  '& .Mui-selected': { color: 'var(--TLC-gray)', fontWeight: 700 },
                  // border: '1px solid red'
                }}
              >
                <Tab label="Account" {...a11yProps(0)} onClick={resetOrderId} className={styles.divtabs} />
                <Tab label="Purchases" {...a11yProps(1)} className={styles.divtabs} />
                {/* <Tab label="Other Invitation Accounts" {...a11yProps(2)} onClick={resetOrderId} /> */}
                {/* <Tab label="Logout" onClick={handleLogout} className={styles.divtabs} /> */}
              </Tabs>

            </div>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography
              className="sectionPara"
              sx={{
                pt: 3,
                pb: 3,
                pl: 0,
                pr: 0,
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              Account Details
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText">First Name </InputLabel>
                <CustomTextField error={errors.firstName} helperText={errors.firstName ? "Requires a valid name" : ""} value={userInfo.firstName} id="firstName" name="firstName" placeholder="First Name" size="small" className="formInput" onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText">Last Name </InputLabel>
                <CustomTextField error={errors.lastName} helperText={errors.lastName ? "Require a valid name" : ""} value={userInfo.lastName} id="lastName" name="lastName" placeholder="Last Name" size="small" className="formInput" onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText">Email</InputLabel>
                <CustomTextField error={errors.emailAddress} helperText={errors.emailAddress ? "Require a valid email" : ""} value={userInfo.emailAddress} id="emailAddress" name="emailAddress" placeholder="Email" size="small" className="formInput" onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText">
                  Phone Number <span className="optional">(optional)</span>
                </InputLabel>
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <CustomTextField
                      error={errors.countryCode}
                      helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                      sx={{ width: { xs: '100%' }, mb: 4 }}
                      value={userInfo.countryCode}
                      onChange={handleChange}
                      id='countryCode'
                      name='countryCode'
                      size='small'
                      type=''
                      className={styles.input}
                      placeholder='65'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" >
                            <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}
                    sx={{
                      textAlign: 'end'
                    }}
                  >
                    <CustomTextField
                      sx={{ width: { xs: '98%' }, mb: 4 }}
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                      value={userInfo.phoneNumber}
                      onChange={handleChange}
                      id="phoneNumber"
                      name="phoneNumber"
                      size="small"
                      className="formInput"
                      fullWidth
                      placeholder='Phone number'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText">
                  Relationship to Deceased <span className="optional">(optional)</span>
                </InputLabel>
                <CustomSelect
                  className={styles.input}
                  id="relationship"
                  name="relationship"
                  // sx={{width:  {xs:'300px', md: '300px', lg: '450px'}}}
                  size="small"
                  labelId="action"
                  fullWidth
                  value={userInfo.relationship ?? "-"}
                  onChange={handleChange}
                >
                  {relationships &&
                    relationships.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'var(--TLC-web-grey)',
                            '&:hover': {
                              backgroundColor: 'var(--TLC-web-grey)',
                            },
                          },
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} md={5.5}></Grid>
              <Grid item xs={12} md={4}>
                <MuiButton type="submit" fullWidth onClick={handleSave} className={styles.btnSave} variant="outlined">
                  Save
                </MuiButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="sectionPara"
                  sx={{
                    pt: 3,
                    pb: 4,
                    pl: 0,
                    pr: 0,
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  Change Password
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText" htmlFor="outlined-adornment-password">
                  Current Password
                </InputLabel>
                <CustomTextField
                  size="small"
                  fullWidth
                  className="formInput"
                  name="currentPassword"
                  onChange={handlePassword}
                  value={passwordGroup.currentPassword}
                  type={showCurrentPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={styles.icon}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                      >
                        {showCurrentPassword ? <VisibilityOff sx={{ color: 'var(--TLC-gray)' }} /> : <Visibility sx={{ color: 'var(--TLC-gray)' }} />}
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}></Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText" htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <CustomTextField
                  size="small"
                  className="formInput"
                  fullWidth
                  id="outlined-adornment-password"
                  name="newPassword"
                  onChange={handlePassword}
                  value={passwordGroup.newPassword}
                  type={showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={styles.icon}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                      >
                        {showNewPassword ? <VisibilityOff sx={{ color: 'var(--TLC-gray)' }} /> : <Visibility sx={{ color: 'var(--TLC-gray)' }} />}
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}></Grid>
              <Grid item xs={12} md={5.5}>
                <InputLabel className="sectionText" htmlFor="outlined-adornment-password">
                  Confirm New Password{" "}
                </InputLabel>
                <CustomTextField
                  size="small"
                  className="formInput"
                  fullWidth
                  id="outlined-adornment-password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={handlePassword}
                  value={passwordGroup.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={styles.icon}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <VisibilityOff sx={{ color: 'var(--TLC-gray)' }} /> : <Visibility sx={{ color: 'var(--TLC-gray)' }} />}
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.5}></Grid>
              <Grid item xs={12} md={4}>
                <MuiButton type="submit" fullWidth onClick={handlePasswordChange} className={styles.btnSave} variant="outlined">
                  Save
                </MuiButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="sectionPara"
                  sx={{
                    pt: 3,
                    pb: 4,
                    pl: 0,
                    pr: 0,
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  Email Settings
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ p: 0 }} className="sectionText">
                  Receive notification emails for:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel control={
                  <Switch size="small" className="sectionText" defaultChecked
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                          color: 'white',
                          '& + .MuiSwitch-track': {
                            backgroundColor: 'var(--TLC-gray)',
                            opacity: 1
                          }
                        }
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: '#eeeeee',
                        opacity: 1,
                        border: '1px solid var(--TLC-web-grey)',
                      }
                    }} />
                }
                  label="Events"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      className="sectionText"
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          '&.Mui-checked': {
                            color: 'white',
                            '& + .MuiSwitch-track': {
                              backgroundColor: 'var(--TLC-gray)',
                              opacity: 1
                            }
                          }
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: '#eeeeee',
                          opacity: 1,
                          border: '1px solid var(--TLC-web-grey)',
                        }
                      }}
                    />
                  }
                  label="Messages"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {purchaseUI}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {inviteAccounts &&
              inviteAccounts.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <InviteProfile data={item} />
                  </div>
                )
              })}
          </TabPanel>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default Profilepage
