import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

export interface ProductState {
  items: any[]
}

const initialState: ProductState  = {
  items: []
};

export const productSlice = createSlice({
  initialState,
  name: 'products',
  reducers: {
    setProducts: (state, {payload}) => {
      state.items = payload.items
    }
  },
});

export const getProductState = (state: RootState) => state.product;

export default productSlice.reducer;

export const { setProducts } = productSlice.actions;