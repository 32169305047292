import React, { useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../components/base/Button/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import GuestTable from '../../components/guestlist/GuestTable';
import GuestForm from '../../components/guestlist/GuestForm';
import CustomSelect from '../../components/base/Select/CustomSelect';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useUpdateMainGuestMutation, useGetAvailableListQuery, useUpdateEventGuestMutation, useGetSpecificGuestlistQuery } from '../../features/guestlist/guestlistRestApi';
import Toast from '../../components/base/Toast/Toast';
import { formatDate } from '../../constants';
import styles from './Event.module.css'
import { TroubleshootTwoTone } from '@mui/icons-material';

function EventRsvp() {
  const { id, name, eventId } = useParams()
  const navigate = useNavigate()
  const [guestlist, setGuestlist] = useState<any>([])
  const [rsvplist, setRsvplist] = useState([])
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  //DropDown for selecting guestlist
  const listResult = useGetAvailableListQuery(id, { refetchOnMountOrArgChange: true })
  const [list, setList] = useState<any[]>(listResult.data)
  const [selectedList, setSelectedList] = useState<any>('')
  const [selectedEventId, setEventId] = useState<number>(Number(eventId))
  const [arg, setArg] = useState({eventId: 0, funeralId: id})
  const { data, isLoading} = useGetSpecificGuestlistQuery(arg, {refetchOnMountOrArgChange: true})

  const [updateEventGuest] = useUpdateEventGuestMutation()
  const [updateMainGuest] = useUpdateMainGuestMutation()

  useEffect(() => {
    if(data && !isLoading) {
      setGuestlist(data.invites)
      setRsvplist(data.rsvplist)
    }
  }, [data, isLoading])
  useEffect(() => {
    if(listResult.data) {
      setList([
        {eventId: 0, funeralId: id, type: 'Master'},
        ...listResult.data
      ])
    }
  }, [listResult])

  useEffect(() => {
    if(list) {
      let found = list.find((item: any) => {
        return item.eventId === selectedEventId
      })
      setSelectedList(found)
      setArg({
        ...arg,
        eventId: found.eventId
      })
    }
    
  }, [list])

  const handleListChange = async (event: SelectChangeEvent) => {
    const value: any = event.target.value
    if(value.type === 'Master') {
      setEventId(0)
    }else {
      setEventId(value.eventId)
    }
    
    setSelectedList(value)
  }

  const handleAddGuest = async (guest: any) => {
    try {
      let rv
      let body = {
        type: 'add',
        eventId: selectedEventId,
        funeralId: id,
        guest: guest
      }

      if(selectedEventId !== 0) {
        rv = await updateEventGuest(body).unwrap()
      }else {
        rv = await updateMainGuest(body).unwrap()
      }

      if(rv) {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: rv.message
        })
        return true
      }
      
    }catch(e: any) {
      console.error('rejected', e);
      setToast({
        ...toast,
        open: true,
        type: 'success',
        message: e.data.message
      })
    }

  }

  const handleGuestDelete = async (item: any) => {
    try {
      let rv
      let body = {
        type: 'delete',
        eventId: selectedEventId,
        funeralId: id,
        guest: item
      }

      if(selectedEventId !== 0) {
        rv = await updateEventGuest(body).unwrap()
      }else {
        rv = await updateMainGuest(body).unwrap()
      }

      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
      }
      
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'Error'
      })
      console.error('rejected', e);
    }
  }

  const handleChangeView = () => {
    setArg({
      ...arg,
      eventId: selectedEventId
    })
  }

  const handleNavigate = () => {
    navigate(`/${name}/${id}/rfm/guestlist`)
  }

  return (
    <Box sx={{px: {xs: 2, md:0}}}>
    <Typography component={"div"} variant={"body2"} className="hr"></Typography>
    <Toast {...toast}/>
      <Grid container spacing={2} sx={{my:2, pr: {xs: 2, sm: 0}}} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={4}>
          <div className='sectionTitle'>Event RSVP List</div>
        </Grid>
        <Grid item xs={12} sm={4}  sx={{display: {xs:'block', sm:'flex'}, justifyContent: {xs:'normal', sm:'flex-end'}}}>
          <Button className='btnSecondary' sx={{width: {xs: '100%', sm: '250px'}}} onClick={handleNavigate}>View Master Guest List</Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{pr: {xs: 2, sm: 0}}} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <div className={styles.title}>{selectedList.type}</div>
        </Grid>
        <Grid item xs={12} md={8} sx={{display:'flex', justifyContent:"end", flexDirection: {xs: 'column', sm: 'row'}}}>
        <CustomSelect
            className={styles.input}
            sx={{width:  {xs: '100%', sm: '300px'}, mr:2, mb: {xs: 2, sm: 0}}}
            size='small'
            labelId="action"
            value={selectedList || ""}
            onChange={handleListChange}
            MenuProps={{
              disableScrollLock: true,
            }}
            input={<OutlinedInput />}
          >
            {list && list.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {item.type + ' ' + formatDate(item.startDate, 'DD MMMM YYYY')}
              </MenuItem>
            ))}
          </CustomSelect>
          <Button className='btnTransparent'  sx={{width: {xs: '100%', sm: '250px'}}} onClick={handleChangeView}>View</Button>
        </Grid>
      </Grid>

      <Box sx={{my:4, pb:2}} className={styles.borderBottom}>
        <GuestForm handleAddGuest={handleAddGuest}/>
      </Box>
      
      <GuestTable data={guestlist} rsvplist={rsvplist} isRSVP={true} handleGuestDelete={handleGuestDelete} listType="event"/>
    </Box>
  )
}

export default EventRsvp