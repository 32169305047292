import React from 'react'

function FacebookIcon() {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.8212 13.197C26.8212 6.01719 21.0007 0.197021 13.8212 0.197021C6.64167 0.197021 0.821167 6.01719 0.821167 13.197C0.821167 19.6858 5.57491 25.0637 11.7898 26.0389V16.9546H8.48907V13.197H11.7898V10.3331C11.7898 7.07512 13.7305 5.27524 16.7 5.27524C18.1225 5.27524 19.6101 5.52912 19.6101 5.52912V8.72838H17.9708C16.3559 8.72838 15.8522 9.73031 15.8522 10.7584V13.197H19.4577L18.8814 16.9549H15.8522V26.0392C22.0671 25.0637 26.8212 19.6855 26.8212 13.197Z" fill="#545454"/>
        </svg>
    )
}

export default FacebookIcon