import Box from '@mui/material/Box';
import { Grid, Typography, Tab } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import ListMenu from './ListMenu';
import styles from './Navbar.module.css'

function NavFooter() {

  return (
    <>

        <Grid container justifyContent='space-between'>
            <Grid item sm={12} md={9} className={styles.navGrid}>

              <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                <ListMenu />
              </Box>

            </Grid>
            <Grid item sx={{ sm:{width: "100%"}, md:{display: 'flex'} }} className={styles.navGrid}>
              <Box className={styles.divtabs} aria-label="nav tabs">
                <Tab className={styles.navTab} href='https://www.thelifecelebrant.sg/about-tlc-the-life-celebrant.html' target="_blank" label="About TLC" rel="noreferrer" />
                <Tab className={styles.navTab} href='https://www.thelifecelebrant.sg/the-life-celebrant-services.html' target="_blank" label="TLC Services" rel="noreferrer" />
              </Box>
            </Grid>
        </Grid>

    </>
  )
}

export default NavFooter