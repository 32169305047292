import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Papa from 'papaparse'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../components/base/Select/CustomSelect';
import DragAndDrop from '../../components/base/Upload/DragAndDrop';
import Button from '../../components/base/Button/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './Guest.module.css'
import { useGetDownloadlistQuery } from '../../features/guestlist/guestlistRestApi';

import { 
  useGetAvailableListQuery, 
  useGetSpecificGuestlistQuery, 
  useUpdateMainGuestMutation, 
  useUpdateEventGuestMutation, 
  useAddCsvGuestMutation 
} from '../../features/guestlist/guestlistRestApi';
import Toast from '../../components/base/Toast/Toast';
import GuestTable from '../../components/guestlist/GuestTable';
import GuestForm from '../../components/guestlist/GuestForm';
import { validateEmail, validateName, csvmaker, downloadCsv, formatDate } from '../../constants';

function Guestlist() {
  const { id, name } = useParams() //funeralId
  const [guestList, setGuestList] = useState<any[]>([])
  const [file, setFile] = useState<any>()
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  //DropDown for selecting guestlist
  const listResult = useGetAvailableListQuery(id, { refetchOnMountOrArgChange: true })
  const [list, setList] = useState<any[]>(listResult.data)
  const [selectedList, setSelectedList] = useState<any>("")

  const [eventId, setEventId] = useState<number>(0)
  const [arg, setArg] = useState({eventId: 0, funeralId: id})

  const { data, isLoading} = useGetSpecificGuestlistQuery(arg, {refetchOnMountOrArgChange: true})
  const downloadlist = useGetDownloadlistQuery(id,  { refetchOnMountOrArgChange: true })
  const [updateMainGuest] = useUpdateMainGuestMutation()
  const [updateEventGuest] = useUpdateEventGuestMutation()
  const [csvAddGuest] = useAddCsvGuestMutation()

  //set guetlist when refetch
  useEffect(() => {
    if(data && !isLoading) {
      if (selectedList?.eventId === 0) {
        setGuestList(data.invites)
      } else {
        setGuestList(data.rsvplist);
      }
    }
  }, [data, isLoading])

  useEffect(() => {
    if(listResult.data) {
      setList([
        {eventId: 0, funeralId: id, type: 'Master', startDate: null},
        ...listResult.data
      ])
    }
    
  }, [listResult])

  useEffect(() => {
    if(list) {
      setSelectedList(list[0])
    }
  }, [list])

  //onmounted
  

  const handleAddGuest = async (guest: any) => {
    try {
      let rv
      let body = {
        type: 'add',
        eventId: eventId,
        funeralId: id,
        guest: guest
      }

      if(eventId !== 0) {
        rv = await updateEventGuest(body).unwrap()
      }else {
        rv = await updateMainGuest(body).unwrap()
      }

      if(rv.status === 200) {
        setGuestList([...guestList, guest])
        return true
      }
      
    }catch(e) {
      console.error('rejected', e);
    }

  }

  const handleGuestDelete = async (item: any) => {
    try {
      let rv
      let body = {
        type: 'delete',
        eventId: eventId,
        funeralId: id,
        guest: item
      }

      if(eventId !== 0) {
        rv = await updateEventGuest(body).unwrap()
      }else {
        rv = await updateMainGuest(body).unwrap()
      }

      if(rv) {
        alert('Deleted')
      }
      
    }catch(e) {
      console.error('rejected', e);
    }
  }

  const handleListChange = async (event: SelectChangeEvent) => {
    const value: any = event.target.value

    if(value.type === 'Master') {
      setEventId(0)
    }else {
      
      setEventId(value.eventId)
    }
    
    setSelectedList(value)
  }

  const handleChangeView = () => {
    setArg({
      ...arg,
      eventId: eventId
    })
  }

  // handle File events
  const handleFile = function(e: any) {
    setFile(e[0])
  }

  const clearUpload = () => {
    setFile('')
  }

  const handleAddList = async () => {
    if(!file) {
      return
    }
    let rv = await validateCSV()
    if(rv) {
      let body = {
        data: rv,
        eventId: eventId,
        funeralId: id,
      }
      try {
        let rv0 = await csvAddGuest(body).unwrap();
        if(rv0) {
          setToast({
            ...toast,
            message: rv0.message,
            type: 'success',
            open: true
          })
        }
      }catch(e: any) {
        setToast({
          ...toast,
          message: e.toString(),
          type: 'error',
          open: true
        })
      }
    }
    
  }

  const parseCSV = () => {
    return new Promise<{data: any, fields: any}>((resolve, reject) => {
      const reader = new FileReader();
      // Wait till complete
      reader.onload = function(event: any) {
        const csvOutput = event.target.result;
        const {data, meta} = Papa.parse(csvOutput, {
          header: true,
          delimiter: ',',
          skipEmptyLines: true,
        })
        resolve({
          data: data,
          fields: meta.fields
        });
      };
      // Make sure to handle error states
      reader.onerror = function(e: any) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  const validateCSV = async () => {
    const {data, fields} = await parseCSV()
    //Validate
    if(fields.toString() !== "email,firstName,lastName,phoneNumber,relationship") {
      setToast({
        ...toast,
        message: "Please use the template csv provided!",
        type: 'error',
        open: true
      })
      return false
    }
    for(var guest of data) {
      const hasError = validateGuest(guest)
      if(hasError) {
        setToast({
          ...toast,
          message: `Error in csv file. Please make sure that all required columns are filled and valid!`,
          type: 'error',
          open: true
        })

        return false
      }
    }
    return data
  }

  const validateGuest = (guest: any) => {
    let hasError = false
    const {email, firstName, lastName } = guest
    if(!email || !validateEmail(email)) {
      hasError = true
    }
    if(!firstName || !validateName(firstName)) {
      hasError = true
    }
    if(!lastName || !validateName(lastName)) {
      hasError = true
    }
    
    return hasError
  }

  const handleDownload = async () => {
    const {masterlist, rfmlist } = downloadlist.data
    let masterlistCsv = csvmaker(masterlist)
    let rfmlistCsv = csvmaker(rfmlist)

    let list = 'Master List\n' +  masterlistCsv + '\n\nRFM\n' + rfmlistCsv
    downloadCsv(list, 'master+rfm')
  }

  return (
    <Box sx={{px: {xs: 2, md:0}}}>
      <Typography component={"div"} variant={"body2"} className="hr"></Typography>
      <Toast {...toast}/>
      <Box sx={{ py: 2}}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item>
            <div className={styles.header}>Master Guest Lists</div>
          </Grid>
        </Grid>

        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{my:2}}
        >
          <Link className={styles.link} to={`/${name}/${id}/events`}>
            Event Guest Lists
          </Link>
          <Box>Master Guest List</Box>
        </Breadcrumbs>

        <Grid container spacing={2} justifyContent="space-between" sx={{mb:2, px:{xs: 2, sm: 0}}}>
          <Grid item xs={12} sm={6} className='centerText'>
            <Typography className='sectionText'>Any changes to this list will effect all other event guest lists.</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{display: {xs: 'block', sm: 'flex'}, justifyContent: {xs: 'normal',sm: 'flex-end'}}}>
            <Button className='btnTransparent' sx={{width: {xs: '100%', md: 365}}} onClick={handleDownload}>Download Master Guest List + RFM</Button>
          </Grid>
        </Grid>

        <Box sx={{py:4}} className={styles.outlined}>
          <Typography className='sectionText'>Add Guest</Typography>
          <GuestForm handleAddGuest={handleAddGuest}/>
        
          <Grid container spacing={2} sx={{mt:4, pr:{xs: 2, sm: 2}}}>
            <Grid item xs={12} md={4}>
              <Box>
                <InputLabel>Bulk upload guest list. (.csv file)</InputLabel>
                <DragAndDrop label="guestlist" filetype='.csv' handleFile={handleFile} file={file} clearUpload={clearUpload}/>
                <div className={styles.download}><a href='/template/guestlist_template.csv' download="guestlist_template.csv"></a>Download the csv template <a className={styles.link} href='/template/guestlist_template.csv' download="guestlist_template.csv">here</a>.</div>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{my:2}}>
              <Button className='btnSecondary' sx={{width: {xs: 200, sm: 300}}} onClick={handleAddList}>Add Guest List</Button>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2} alignItems="center" sx={{px:2, mt:2}}>
          <Grid item xs={12} sx={{textAlign: {xs: 'start', sm: 'none', md:'end'}}}>
            <CustomSelect
              className={styles.input}
              sx={{width:  {xs: '100%', sm: '300px'}, mr:{xs:0,md:2}, textAlign: 'start', mb: {xs:2,sm:0}}}
              size='small'
              labelId="action"
              value={selectedList || ""}
              onChange={handleListChange}
              MenuProps={{
                disableScrollLock: true,
              }}
              input={<OutlinedInput />}
            >
              {list && list.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                  }}
                >
                  {item.type +  ' ' + formatDate(item.startDate, 'DD MMMM YYYY')}
                </MenuItem>
              ))}
            </CustomSelect>
            <Button className='btnTransparent'  sx={{my: 0,width: {xs: '100%', sm: '200px'}, ml:{xs:0,md:2}}} onClick={handleChangeView}>View</Button>
          </Grid>
        </Grid>


        <GuestTable data={guestList} handleGuestDelete={handleGuestDelete} listType={selectedList}/>
      </Box>
      
    </Box>
  )
}

export default Guestlist