import React from "react"

function UploadIcon() {
  return (
    <>
      <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.2459 24.6318C10.0358 24.6318 6.82581 24.6327 3.61578 24.6318C1.5386 24.6309 0.141667 23.2403 0.139845 21.1714C0.138935 19.8172 0.138024 18.4622 0.139845 17.1081C0.140756 16.3832 0.587883 15.8896 1.23535 15.8914C1.87918 15.8933 2.32357 16.3905 2.32449 17.1163C2.32631 18.4822 2.32357 19.8482 2.3254 21.2142C2.32631 22.0037 2.76251 22.4463 3.54384 22.4463C10.0204 22.4472 16.4978 22.4472 22.9743 22.4463C23.7402 22.4463 24.1773 22.0046 24.1791 21.2342C24.1818 19.8227 24.1754 18.4112 24.1827 16.9997C24.1855 16.416 24.5943 15.9616 25.1453 15.896C25.6835 15.8322 26.1889 16.1774 26.3264 16.7156C26.3601 16.8458 26.3637 16.9851 26.3637 17.1208C26.3665 18.4977 26.3692 19.8755 26.3646 21.2524C26.3573 23.2158 24.944 24.6282 22.9779 24.63C19.7342 24.6355 16.4896 24.6318 13.2459 24.6318Z"
          fill="#ECE8D2"
        />
        <path
          d="M12.1595 3.97061C12.1595 4.18097 12.1595 4.31574 12.1595 4.45052C12.1595 9.28696 12.1586 14.1234 12.1622 18.9589C12.1622 19.1383 12.1704 19.3259 12.2205 19.4971C12.3698 20.0034 12.8588 20.314 13.3761 20.2548C13.8842 20.1965 14.2967 19.773 14.3405 19.2567C14.3514 19.132 14.3459 19.0063 14.3459 18.8815C14.3459 14.0569 14.3459 9.23141 14.3459 4.40681C14.3459 4.28478 14.3459 4.16184 14.3459 3.98791C14.4652 4.06987 14.5481 4.11995 14.6228 4.17914C15.7611 5.08706 16.8976 5.99679 18.0359 6.90562C18.5813 7.34182 19.2425 7.29538 19.6422 6.79452C20.032 6.3055 19.9482 5.64802 19.4219 5.22548C17.6215 3.77937 15.8184 2.336 14.0117 0.897176C13.4936 0.484654 13.0082 0.488296 12.4918 0.900819C10.7297 2.30504 8.97038 3.71107 7.21374 5.12075C6.70742 5.5269 6.62364 6.18257 7.00156 6.66612C7.38677 7.15878 8.05245 7.22799 8.57789 6.81273C9.66702 5.95217 10.7489 5.08342 11.8344 4.2183C11.9209 4.14909 12.011 4.08353 12.1595 3.97061Z"
          fill="#ECE8D2"
        />
      </svg>
    </>
  )
}

export default UploadIcon
