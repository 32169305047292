import dayjs from "dayjs"
import jsPDFInvoiceTemplate from "../components/invoice/invoice"
import areaCodes from "./areaCode.json"
import utc from "dayjs/plugin/utc"
import * as XLSX from "xlsx"
dayjs.extend(utc)

export type Order = "asc" | "desc"

export const relationships = ["-", "Father", "Mother", "Son", "Daughter", "Grandmother", "Grandfather", "Cousin", "Aunt", "Uncle", "Sister", "Brother", "Husband", "Wife", "Grandchild"]
export const religions = ["-", "Budhhist", "Taoism", "Christian", "Free-Thinker", "Roman Catholic", "Straight", "Soka"]
export const races = ["-", "Chinese", "Malay", "Indian", "Others"]

export const livestreamAction = ["Live Stream Available", "Live Stream Not Available"]

export const eventsList_1 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const eventsList_2 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const eventsList_3 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const eventsList_for_rfm = [{ id: "", type: "Post Funeral" }]

//const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export const formatDate = (date: any, format: string, utc: boolean = true) => {
  /* let selectedDate = date ? date : new Date()
  let strMonth

  let month = new Date(selectedDate).getMonth()
  strMonth = months[month]
  
  let year = new Date(selectedDate).getFullYear()
  let currDate = new Date(selectedDate).getDate()
  return `${currDate} ${strMonth} ${year}` */

  let formatedDate = ""
  if (date) {
    formatedDate = dayjs(date).utc(utc).format(format)
  }
  return formatedDate
}

export const formatTime = (time: any) => {
  let timeArray = time.split(":")
  if (timeArray.length > 1) {
    let ampm = timeArray[0] >= 12 ? "pm" : "am"
    let hours: any = timeArray[0] % 12
    if (hours === 0) {
      if (ampm === "pm") {
        hours = "12"
      } else {
        hours = "00"
      }
    }
    let mins = timeArray[1]
    let strTime = hours + ":" + mins + " " + ampm
    return strTime
  }
  return "-"
}

export const getDateInRange = (start: any, end: any) => {
  let a = dayjs(start)
  let b = dayjs(end)
  let range = [a.format("DD/MM/YYYY")]
  let increment = a.add(1, "day")

  while (increment.format("DD/MM/YYYY") < b.format("DD/MM/YYYY")) {
    range.push(increment.format("DD/MM/YYYY"))
    increment = increment.add(1, "day")
  }
  if (a.format("DD/MM/YYYY") !== b.format("DD/MM/YYYY")) {
    range.push(b.format("DD/MM/YYYY"))
  }
  return range
}

export const getDate = (date: any) => {
  let a = dayjs(date)
  return a.format("DD/MM/YYYY")
}

export const debounce = (fn: any) => {
  let timeout: any = null
  return (...args: any) => {
    const next = () => fn(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, 500)
  }
}

export const validateName = (name: string) => {
  if (name) {
    let regex = /^[a-zA-Z ]{0,50}$/
    return regex.test(name)
  }

  return true
}

export const validateTel = (tel: string) => {
  if (tel) {
    // let regex = /^(6|8|9)\d{7}$/
    let regex = /^(6|8|9)\d{7,11}$/
    return regex.test(tel)
  } else {
    return false
  }
}

export const validateCountryCode = (tel: any) => {
  if (tel) {
    let regex = /^\d{2,3}$/
    return regex.test(tel.trim())
  }

  return true
}

export const validatePw = (pw: string) => {
  if (pw) {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    return regex.test(pw)
  }
  return true
}

export const validateEmail = (email: string) => {
  let regex = /^[a-zA-Z0-9]+([._\-+]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,3})+$/
  return regex.test(email)
}

export const validatePostal = (postalCode: string) => {
  let areaCode = postalCode.slice(0, 2)
  if (!areaCodes.includes(areaCode)) {
    return false
  }
  let regex = /^\d{6}$/
  return regex.test(postalCode.trim())
}

export const validateVideoUrl = (url: string) => {
  if (url) {
    let regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/
    const maxLength = 255

    console.log("")

    return regex.test(url) && url.length <= maxLength
  }

  return true
}

export const getCurrentWidthDimension = () => {
  return window.innerWidth
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly any[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function csvmaker(data: any[]) {
  // Empty array for storing the values
  let csvRows = []

  // Headers is basically a keys of an
  // object which is id, name, and
  // profession
  const headers = Object.keys(data[0])

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(","))

  // Pushing Object values into array
  // with comma separation
  for (var row of data) {
    const values = Object.values(row).join(",")
    csvRows.push(values)
  }

  // Returning the array joining with new line
  return csvRows.join("\n")
}

export function downloadCsv(data: any, filename: string) {
  try {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, filename)
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "sheetjs.xlsx")

    return { message: "Successfully Downloaded" }
  } catch (e: any) {
    console.log("error-downloadCsv", e.toString())
    return { message: e.data.message }
  }
}

export function padZeros(length: any, value: any) {
  let len = value.length
  let pad = length - len
  let newVal = value
  if (pad > 0) {
    while (pad > 0) {
      pad--
      newVal = "0" + String(newVal)
    }
  }

  return newVal
}

type options = {
  order: any
  outputType: string
  filename: string
  customer: any
}

export function generateInvoice(options: options) {
  const { order, outputType, filename, customer } = options
  let props = {
    outputType: outputType || "save",
    returnJsPDFDocObject: true,
    fileName: filename,
    orientationLandscape: true,
    compress: true,
    logo: {
      src: "/images/TLC-MAIN-LOGO.png",
      type: "PNG", //optional, when src= data:uri (nodejs case)
      width: 60, //aspect ratio = width/height
      height: 17,
      margin: {
        top: 0, //negative or positive num, from the current position
        left: 0 //negative or positive num, from the current position
      }
    },
    /* stamp: {
        inAllPages: true, //by default = false, just in the last page
        src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
        type: 'JPG', //optional, when src= data:uri (nodejs case)
        width: 20, //aspect ratio = width/height
        height: 20,
        margin: {
            top: 0, //negative or positive num, from the current position
            left: 0 //negative or positive num, from the current position
        }
    }, */
    business: {
      name: "The Life Celebrant",
      address: "Blk 89 Geylang Bahru Industrial Estate #01-2738, Singapore 339697",
      phone: "+65 6684 8488",
      email: "Care@TheLifeCelebrant.SG",
      website: "www.thelifecelebrant.sg"
    },
    contact: {
      label: "Invoice issued for:",
      name: `${customer.lastName}`,
      address: "",
      phone: customer.phoneNumber,
      email: customer.emailAddress,
      otherInfo: ""
    },
    invoice: {
      label: `Invoice #: `,
      num: order.code,
      invDate: "", //`Payment Date: ${formatDate(new Date(order.date), 'MM/DD/YYYY HH:mm')}`,
      invGenDate: `Invoice Date:${formatDate(new Date(order.date), "MM/DD/YYYY HH:mm")}`,
      headerBorder: false,
      tableBodyBorder: false,
      header: [
        {
          title: "#",
          style: {
            width: 10
          }
        },
        {
          title: "Product Description",
          style: {
            width: 80
          }
        },
        /* { 
          title: "Description",
          style: {
            width: 80
          } 
        },  */
        { title: "Unit Price" },
        { title: "Quantity" },
        { title: "Amount" }
      ],
      table: Array.from(order.lines, (item: any, index: any) => [index + 1, item.productVariant.name, `$${(item.productVariant.price / 100).toFixed(2)}`, item.quantity, `$${((item.quantity * item.productVariant.price) / 100).toFixed(2)}`]),
      additionalRows: [
        {
          col1: "Subtotal:",
          col2: `$${(order.subTotal / 100).toFixed(2)}`,
          col3: "",
          style: {
            fontSize: 10, //optional, default 12
            fontWeight: "normal"
          }
        },
        {
          col1: `GST(${order.gst}%):`,
          col2: `$${((order.subTotalWithTax - order.subTotal) / 100).toFixed(2)}`,
          col3: "",
          style: {
            fontSize: 10, //optional, default 12
            fontWeight: "normal"
          }
        },
        {
          col1: "Delivery:",
          col2: `$${(order.shippingWithTax / 100).toFixed(2)}`,
          col3: "",
          style: {
            fontSize: 10, //optional, default 12
            fontWeight: "normal"
          }
        },
        {
          col1: "TOTAL:",
          col2: `$${((order.subTotalWithTax + order.shippingWithTax) / 100).toFixed(2)}`,
          col3: "",
          style: {
            fontSize: 10, //optional, default 12
            fontWeight: "bold"
          }
        }
      ],
      invDescLabel: "",
      invDesc: ""
    },
    footer: {
      text: "The invoice is created on a computer and is valid without the signature and stamp."
    },
    pageEnable: true,
    pageLabel: "Page "
  }

  const pdf = jsPDFInvoiceTemplate(props)
}

export function getYoutubeVideoId(url: string) {
  // Regular expression to match YouTube URL patterns
  const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

  // Test the input URL against the regular expression
  const match = url.match(regExp)

  // If a match is found, return the YouTube video ID
  if (match) {
    return match[1] // The captured video ID
  }

  // If no match is found, return null or handle the error as needed
  return ""
}

export function getGoogleDriveFileId(url: string) {
  // Regular expression pattern to match Google Drive URLs
  const googleDriveUrlPattern = /(?:https?:\/\/)?(?:www\.)?(?:drive\.google\.com\/(?:file\/d\/|open\?id=)|docs\.google\.com\/(?:uc\?export=download&id=))([^\/\?]+)/

  // Attempt to match the provided URL against the pattern
  const match = url.match(googleDriveUrlPattern)

  if (match && match[1]) {
    // Extracted Google Drive file ID
    return match[1]
  } else {
    // Invalid or unrecognized URL format
    return ""
  }
}
