import React, { useState, useEffect } from "react"
import { Box, Typography, Paper, IconButton, InputBase } from "@mui/material"
import Grid from "@mui/material/Grid"
import { CardMedia } from "@mui/material"
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select"
import { styled } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import styles from "./Product.module.css"
import Button from "../base/Button/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useAppSelector } from "../../app/hooks"
import { useGetProductQuery } from "../../features/products/productGqlApi"
import { useAddItemOrderMutation, useGetOrderQuery, useSetOrderShippingMethodMutation } from "../../features/order/orderGqlApi"
import { getUserState } from "../../features/user/userSlice"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { useParams } from "react-router-dom"

const CustomSelect = styled(Select)({
  "&": {
    background: "transparent"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)",
    borderRadius: "3px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  }
})

const SingleProductComponent: React.FC<any> = (props) => {
  const userState = useAppSelector(getUserState)
  const { id } = useParams()
  const { isLoggedIn } = userState
  const funeralState = useAppSelector(getFuneralState)
  const { events } = funeralState
  const [addItemToOrder] = useAddItemOrderMutation()
  const [setOrderShippingMethod] = useSetOrderShippingMethodMutation()
  const { refetch } = useGetOrderQuery(id)

  const { slug, productId } = props
  const { data, isLoading } = useGetProductQuery({ slug })
  const [quantity, setQuantity] = useState(1)
  const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const [upcomingEvent, setUpcomingEvent] = useState<any>({})

  useEffect(() => {
    let date = new Date().setHours(0, 0, 0, 0)
    for (var item of events) {
      if (new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
        setUpcomingEvent(item)
        break
      }
    }
  }, [events])

  const handleMinus = () => {
    if (quantity > 1 ) {
        setQuantity((prev) => prev - 1)
    }
  }

  const handleAddCart = async (event: any) => {
    if (isLoggedIn) {
      const productVariantId = event.target.value
      try {
        await addItemToOrder({
          productVariantId,
          quantity,
          funeralId: id,
          eventId: upcomingEvent.id
        })
        await setOrderShippingMethod({ funeralId: id })
        refetch()
      } catch (e) {
        console.error("debug", e)
      }
    } else {
      return null
    }
    // const productVariantId = event.target.value;
    // const quantity =
  }

  const handleChange = (event: any) => {
    setQuantity(event.target.value)
  }
  if (!isLoading) {
    let source, id, price, sku, productName
    const variants = data.product.variants
    source = data.product.assets[0].source
    const description = data.product.description

    price = variants[0].price

    for (let i = 0; i < variants.length; i++) {
      if (variants[i].id === productId) {
        id = variants[i].id
        price = variants[i].price
        sku = variants[i].sku
        productName = variants[i].name
        break
      }
    }

    return (
      <>
        <Grid container item sx={{ pt: 3 }}>
          <Grid item xs={12} md={5}>
            <CardMedia component="img" image={`${source}`} alt={`${productName}`} loading="lazy" />
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Typography sx={{ mt: 3, fontSize: { xs: "16px" } }} variant="body1">
                Description
              </Typography>
              <p className={styles.singleProductDetails} dangerouslySetInnerHTML={{ __html: description }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml: { md: 5, xs: 0 } }}>
            <Typography sx={{ mb: { xs: 1, md: 3 }, fontSize: { xs: "32px" } }} variant="body1">
              {productName}
            </Typography>
            <Typography sx={{ mb: { xs: 1, md: 3 }, fontSize: { xs: "16px" } }} variant="body1">
              {sku}
            </Typography>
            <Typography sx={{ mb: { xs: 2, md: 5 }, fontSize: { xs: "20px" } }} variant="body1">
              ${(price / 100).toFixed(2)}
            </Typography>
            {/* <Box>
                        <CustomSelect
                            sx={{mb: 3, width: {xs: '108px'}, height: {xs: '45px'}, pl: 4}}
                            size='medium'
                            labelId="action"
                            input={<OutlinedInput />}
                            defaultValue={1}
                            onChange={handleChange}
                            IconComponent={ExpandMoreIcon}
                            >
                            {range.map((item) => (
                                <MenuItem sx={{pl: 6}} key={item} value={item}>{item}</MenuItem>
                            ))}
                        </CustomSelect>
                    </Box> */}
            <Paper className={styles.numberGroup} component="form" sx={{ p: "2px 4px", mb: 3, display: "flex", alignItems: "center", width: '108px' }}>
              <IconButton sx={{ py: "10px", px: "10px", borderRadius: "0px" }} onClick={(e:any) => handleMinus()}>
                -
              </IconButton>
              <InputBase sx={{ flex: 1, backgroundColor: "#f8f4f0"}} type="number " inputProps={{ min: 0, style: { textAlign: "center" } }} value={quantity} />
              <IconButton sx={{ py: "10px", px: "7px", borderRadius: "0px" }} onClick={(e:any) => setQuantity((prev)=> prev + 1)}>
                +
              </IconButton>
            </Paper>
            <Button sx={{ mb: 2, width: { md: "400px" }, height: { xs: "45px" } }} variant="outlined" onClick={handleAddCart} value={id} className="btnCart" fullWidth>
              Add To Cart
            </Button>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Typography sx={{ mt: 3, fontSize: { xs: "16px" } }} variant="body1">
                Description
              </Typography>
              <p className={styles.singleProductDetails} dangerouslySetInnerHTML={{ __html: description }} />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }
  // const singleProductData = response?.data.product
  // console.log(singleProductData)

  return <Box></Box>
}

export default SingleProductComponent
