import React from 'react'
import  ProductItem  from './ProductItem'
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

interface ProductListProps {
  data: any
};

const ProductList: React.FC<ProductListProps> = (props) => {
  return (
    <Box sx={{ width: '80%', margin: 'auto' }}>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {props.data.items.map((product: any) => {
          return (
            <Grid xs={12} md={4} key={product.id}>
              <ProductItem name={product.name} description={product.description} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default ProductList