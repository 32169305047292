import { ClientError, GraphQLClient } from "graphql-request"
import { createApi } from "@reduxjs/toolkit/query/react"

const API_URL = process.env.REACT_APP_VENDURE_SHOP_API_URL
if (!API_URL) {
  throw new Error(`The environment variable REACT_APP_VENDURE_SHOP_API_URL is missing and it's required to access your store`)
}

const graphQLClient = new GraphQLClient(API_URL, {
  //credentials: 'include',
  mode: "cors"
})

if (localStorage.getItem("vendure-auth-token")) {
  let token = localStorage.getItem("vendure-auth-token")
  graphQLClient.setHeader("authorization", `Bearer ${token}`)
}

export const graphqlBaseQuery =
  () =>
  async ({ body }: { body: string }) => {
    try {
      const result = await graphQLClient.rawRequest(body)
      if (result.headers.get("vendure-auth-token")) {
        graphQLClient.setHeader("authorization", `Bearer ${result.headers.get("vendure-auth-token")}`)
        localStorage.setItem("vendure-auth-token", `${result.headers.get("vendure-auth-token")}`)
      }
      return { data: result.data }
    } catch (error) {
      if (error instanceof ClientError) {
        return { error: { status: error.response.status, data: error } }
      }
      return { error: { status: 500, data: error } }
    }
  }

export const gqlApi = createApi({
  reducerPath: "gqlApi",
  baseQuery: graphqlBaseQuery(),
  tagTypes: ["cart"],
  endpoints: (builder) => ({})
})
