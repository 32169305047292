import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { getFuneralState } from '../features/funeral/funeralSlice';
import { useGetOneQuery } from '../features/funeral/funeralRestApi';
import { useGetOrderQuery, useRemoveAllOrderLinesMutation, useUpdateEventIdMutation, useRemoveOrderLineMutation } from '../features/order/orderGqlApi';
import { getUserState } from '../features/user/userSlice';
import Button from '../components/base/Button/Button';
import Toast from '../components/base/Toast/Toast';
import Styles from './Styles.module.css'

type FuneralProps = {
  children: React.ReactNode;
};

const Funeral: React.FC<FuneralProps> = (props) => {
  const userState = useAppSelector(getUserState);
  const {isLoggedIn} = userState
  const {id, name} = useParams()
  const [meta, setMeta] = useState({
    title: "",
    description: "",
    url: "",
    image: ""
  })
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const [skip, setSkip] = useState(true)
  const funeral = useAppSelector(getFuneralState)
  const { details, events } = funeral
  const { isLoading, isSuccess } = useGetOneQuery({id, name, isLoggedIn}, { refetchOnMountOrArgChange: true })
  const [upcomingEvent, setUpcomingEvent] = useState<any>({})
  const activeOrder = useGetOrderQuery(id)
  const [removeCartItems] = useRemoveAllOrderLinesMutation()
  const [removeOrderLine] = useRemoveOrderLineMutation()
  const [updateEventId] = useUpdateEventIdMutation()
  const [removeCartDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  useEffect(() => {
    let date = new Date().setHours(0,0,0,0)
    for(var item of events) {
      if(new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
        setUpcomingEvent(item)
        break 
      }
    }
  }, [events])

  useEffect(() => {
    if(isLoggedIn) {
      updateCart()
    }
  }, [isLoggedIn, upcomingEvent])

  useEffect(() => {
    if(!funeral.details.id) {
      setSkip(false)
    }else {
      setSkip(true)
    }
  }, [funeral.details.id])

  useEffect(() => {
    let url = `${process.env.REACT_APP_URL}${name}/${id}`
    let image = `${process.env.REACT_APP_FS_BUCKET}/${details.banner_image}`
    let note = details.note
    setMeta({
      url: url,
      title: "TLC - " + name,
      image: image,
      description: note
    })
    /* console.log({
      url: url,
      title: "TLC - " + name,
      description: note,
      image: image
    }) */
  }, [details])

  const updateCart = async () => {
    try {
      let order = await activeOrder.refetch().unwrap()
      let currentEventId = upcomingEvent?.id
      if(!order.activeOrder || !currentEventId) {return}

      if(currentEventId !== +order.activeOrder.customFields.eventId) {
        //Clear items from cart
        let rv = await removeCartItems({funeralId: id}).unwrap()
        if(rv.removeAllOrderLines?.id) {
          //Update eventId
          let rv0 = await updateEventId({orderId: rv.removeAllOrderLines?.id, eventId: currentEventId}).unwrap()
          if(rv0.updateOrderEventId?.id) {
            setOpenDialog(true)
            setDialogMessage("There is a change in the upcoming event. Therefore, your previous items in the cart has been cleared.")
          }
        }
      }

      // this if block causes the products which added to cart is automatically deleted
      // I do not know why this if block mean
      // if(currentEventId === +order.activeOrder.customFields.eventId) {
      //   let eventProducts = upcomingEvent?.products.split(',')
      //   let itemCount = 0
      //   for(var item of order.activeOrder.lines) {
      //     if(!eventProducts.includes(item.productVariant?.id)) {
      //       let rv = await removeOrderLine({adjustId: item.id, funeralId: id}).unwrap()
      //       if(rv.removeOrderLine?.id) {
      //         itemCount++
      //       }
      //     }
      //   }

      //   if(itemCount > 0) {
      //     setOpenDialog(true)
      //     setDialogMessage("There is a change in the product availability. Therefore, your previous items in the cart that are not available anymore will be removed.")
      //   }
      // }

    }catch(e: any) {
      console.log(e.toString())
      setToast({
        ...toast,
        open: true,
        type: 'error',
        message: 'There is an error in updating your cart items.'
      })
    }
  }

  if(!isLoading) {
    return (
      <Box>
      <Toast {...toast}/>
        {
          details.id && details.status === "open" ? (
            <>
              <Helmet>
                <title>TLC - {name}</title>
                <meta property="og:url"          content={meta.url} />
                <meta property="og:type"         content="website" />
                <meta property="og:title"        content={meta.title} />
                <meta property="og:description"  content={meta.description} />
                <meta property="og:image"        content={meta.image} />
              </Helmet>
              {props.children}
            </>
          ) : (
            <Box className={Styles.parent}>
              <Box className={Styles.center}>
                PAGE NOT AVAILABLE
              </Box>
            </Box>
          )
        }
        <Dialog
          open={removeCartDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Removed items from cart"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='btnSecondary' sx={{width: {xs: 150} }} onClick={() => setOpenDialog(false)} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }else {
    return (
      <></>
    )
  }
  
}

export default Funeral