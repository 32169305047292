import { useEffect, useState } from "react"
import { useNavigate, Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Box, Grid, Button as MuiButton, Link as MuiLink, Typography } from "@mui/material"
import Radio from "@mui/material/Radio"
import dayjs from "dayjs"
import Rsvp from "../rsvp/Rsvp"
import WebviewShare from "../share/WebviewShare"
import styles from "./WebviewEvent.module.css"
import CalenderIcon from "../../base/Icons/CalenderIcon"
import LocationIcon from "../../base/Icons/LocationIcon"
import ClockIcon from "../../base/Icons/ClockIcon"
import VideoIcon from "../../base/Icons/VideoIcon"
import VideoDisabledIcon from "../../base/Icons/VideoDisabledIcon"
import { getUserState } from "../../../features/user/userSlice"
import { useGetDownloadRsvpQuery } from "../../../features/rfmEdit/rfmEditRestApi"
import Button from "../../base/Button/Button"
import { formatDate, formatTime, csvmaker, downloadCsv } from "../../../constants"
import { toggleLoginModal } from "../../../features/user/userSlice"
import Toast from "../../base/Toast/Toast"
import React from "react"
import LinkIcon from "../../base/Icons/LinkIcon"
import EmailIcon from "../../base/Icons/EmailIcon"

interface EventProps {
  event: any
  type: string
  isOver: boolean
  handleRadioChange?: (event: any) => void
  selectedRadio?: string
}

const preEvent = ["Wake", "Funeral"]

const WebviewEvent: React.FC<EventProps> = (props) => {
  const { id, name } = useParams()
  const { event, isOver, type, handleRadioChange, selectedRadio } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userState = useAppSelector(getUserState)
  const { isRFM } = userState.funeral
  const [skip, setSkip] = useState(true)
  const [download, setDownload] = useState(false)
  const rsvpDL = useGetDownloadRsvpQuery({ eventId: event.id, funeralId: id }, { skip: skip })
  const [toast, setToast] = useState({
    type: "success",
    open: false,
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const [url, setUrl] = useState("")

  useEffect(() => {
    const link = `${process.env.REACT_APP_URL}${name}/${id}`
    setUrl(link)
  }, [])

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
    setToast({
      ...toast,
      open: true,
      message: "Link copied",
      type: "success"
    })
  }

  function isLinkVirtualAvailable(startdate: string, enddate: string) {
    let yesterday = dayjs(startdate).subtract(1, "days").format("YYYY-MM-DD")
    let until = dayjs(enddate).format("YYYY-MM-DD")
    let today = dayjs().format("YYYY-MM-DD")
    let result = today >= yesterday && today <= until

    return result
  }

  function linkLiveButton(date: string) {
    if (event.livestreamUrl) {
      let today = dayjs().format("YYYY-MM-DD")
      let checkdate = dayjs(date).format("YYYY-MM-DD")
      let result = today > checkdate

      if (result) {
        return (
          <table width="100%">
            <tr>
              <td width="20%" className={styles.videoURL}>
                <VideoIcon />
              </td>
              <td width="80%">
                <Typography className={styles.virtual}>Link no longer available</Typography>
              </td>
            </tr>
          </table>
        )
      } else {
        if (isLinkVirtualAvailable(event.startDate, event.endDate)) {
          return (
            <table width="100%" style={{ tableLayout: "fixed" }}>
              <tr>
                <td width="20%" className={styles.videoURL}>
                  <VideoIcon />
                </td>
                <td width="80%">
                  <MuiLink href={`https://${event.livestreamUrl}`} target="_blank" className={styles.streamUrl}>
                    {event.livestreamUrl}
                  </MuiLink>
                </td>
              </tr>
            </table>
          )
        } else {
          return (
            <table width="100%">
              <tr>
                <td width="20%" className={styles.videoURL}>
                  <VideoIcon />
                </td>
                <td width="80%">
                  <Typography className={styles.upcomingLink}>Link available 24 hours before</Typography>
                </td>
              </tr>
            </table>
          )
        }
      }
    } else {
      return (
        <table width="100%" style={{ tableLayout: "fixed" }}>
          <tr>
            <td width="20%" className={styles.videoURL}>
              <VideoDisabledIcon />
            </td>
            <td width="80%">
              <Typography className={styles.virtual}>No Virtual Option</Typography>
            </td>
          </tr>
        </table>
      )
    }
  }

  const handleSeeMore = () => {
    navigate("../events")
  }

  const handleOpenLogin = () => {
    dispatch(toggleLoginModal(""))
  }

  const handleDownloadRSVP = async () => {
    setSkip(false)
    setDownload(true)
  }

  useEffect(() => {
    if (download && rsvpDL.data) {
      console.log("test")
      if (rsvpDL.data.download.length > 0) {
        let rv = downloadCsv(rsvpDL.data.download, "rsvp")
      } else {
        setToast({
          ...toast,
          open: true,
          message: "RSVP list is empty",
          type: "info"
        })
      }
      setSkip(true)
      setDownload(false)
    }
  }, [rsvpDL, download])

  return (
    <>
      <Toast {...toast} />
      <Box className={styles.container} sx={{ p: 2 }}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={9}>
            <Box style={{ display: "flex", alignItems: "flex-start" }}>
              {isRFM && !isOver && type !== "about" && !preEvent.includes(event.type) && event.owner === "rfm" && <Radio sx={{ pl: 0 }} checked={selectedRadio === String(event.id)} onChange={handleRadioChange} value={event.id} name="radio-buttons" />}
              <h4 className={styles.wvTitle}>{event.title}</h4>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <table width="100%">
                  <tbody>
                    <tr className={styles.itemDetail}>
                      <div className={styles.itemLabel}>
                        <td className={styles.icon}>
                          <CalenderIcon fill={"var(--TLC-gray)"} />
                        </td>
                        <td className={styles.label}>From:</td>
                      </div>
                      <td width="70%" className={styles.text}>
                        {formatDate(event.startDate, "dddd, DD MMMM YYYY")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} md={6}>
                {!event.endDate ? (
                  ""
                ) : (
                  <table width="100%">
                    <tbody>
                      <tr className={styles.itemDetail}>
                        <div className={styles.itemLabel2}>
                          <td className={styles.labelWithPadding}>To:</td>
                        </div>
                        <td width="70%" className={styles.text}>
                          {formatDate(event.endDate, "dddd, DD MMMM YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {
                  <table width="100%">
                    <tbody>
                      <tr className={styles.itemDetail}>
                        <div className={styles.itemLabel}>
                          <td className={styles.icon}>
                            <ClockIcon />
                          </td>
                          <td className={styles.label}>Starts:</td>
                        </div>
                        <td width="70%" className={styles.labelInfo}>
                          {formatTime(event.startTime) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </Grid>
              <Grid item xs={12} md={6}>
                {
                  <table width="100%">
                    <tbody>
                      <tr className={styles.itemDetail}>
                        <div className={styles.itemLabel2}>
                          <td className={styles.labelWithPadding}>Ends:</td>
                        </div>
                        <td width="70%" className={styles.labelInfo}>
                          {formatTime(event.endTime) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </Grid>
              <Grid item xs={12} md={6}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="10%">
                        <LocationIcon />
                      </td>
                      <td width="90%">
                        <div className={styles.text}>{event.address}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} md={6}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="100%" className={styles.labelWithPaddingLive}>
                        {linkLiveButton(event.endDate)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            {!isOver && (
              <>
                {/* <WebviewShare /> */}
                <Box sx={{ py: 2 }}></Box>
                {!isRFM && <Rsvp event={event} />}
              </>
            )}

            {isRFM && (
              <Typography component={"div"} sx={{ mb: 2 }} align={"right"}>
                <Link className={styles.links} to={`/${name}/${id}/rfm/event/rsvp/${event.id}`}>
                  View RSVP List
                </Link>
              </Typography>
            )}

            {isRFM && (
              <Typography component={"div"} sx={{ mb: 2 }} align={"right"}>
                <Button className="btnLink" onClick={handleDownloadRSVP}>
                  Download RSVP List
                </Button>
              </Typography>
            )}
          </Grid>
        </Grid>

        {
          <>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} className={styles.wvDetTitle}>
                Event Details
              </Grid>
              {/* <Grid item xs={12} dangerouslySetInnerHTML={{ __html: event.details }}> */}
              <Grid item xs={12}>
                <div style={{ whiteSpace: "pre-wrap", fontSize: 18 }}>{event.details}</div>
              </Grid>
            </Grid>

            <Box className={styles.copyContainer}>
              <MuiButton
                className="btnIcon"
                href="mailto:default@gmail.com"
                sx={{
                  minWidth: "60px",
                  p: 0
                }}
              >
                <EmailIcon />
              </MuiButton>

              <Button
                className="btnIcon"
                sx={{
                  minWidth: "60px"
                }}
                onClick={() => handleCopy()}
              >
                <Box component={"span"} sx={{ pr: 2, display: { sm: "block" } }} style={{ color: "var(--TLC-gray)" }}>
                  Copy Link
                </Box>
                <Box sx={{ pt: 1 }} style={{ color: "var(--TLC-gray)" }}>
                  <LinkIcon />
                </Box>
              </Button>
            </Box>
          </>
        }
      </Box>
    </>
  )
}
export default WebviewEvent
