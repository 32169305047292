import React, { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { InputLabel } from '@mui/material';
import CustomTextField from '../base/Textfield/CustomTextField';
import styles from './LoginModal.module.css'
import Button from '../base/Button/Button'
import { useRequestPasswordResetMutation } from '../../features/auth/authGqlApi'
import { debounce, validateEmail } from '../../constants';
import Toast from '../base/Toast/Toast';

interface props {
  onClick: (x: number) => void;
  handleClose: () => void
}

const ForgotPwForm: React.FC<props> = (props) => {
  const [requestResetPassword] = useRequestPasswordResetMutation();
  const [toast, setToast] = useState({
    open: false,
    type: 'success',
    message: '',
    handleClose: () => {setToast(prev => ({...prev, open:false}))}
  })
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({
    email: false
  })

  const validateSingleField = (obj: {key: string, value: string}) => {
    const { key, value} = obj
    let rv
    if(key === 'email') {
      rv = validateEmail(value)
    }
    
    if(!rv) {
      setErrors(prev => ({...prev, [key]: true})) 
    }else {
      setErrors(prev => ({...prev, [key]: false})) 
    }
  }

  const debounceHandler = useCallback(
    debounce(validateSingleField)
  , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    
    setEmail(value)

    debounceHandler({key, value:value.trim()})
  }
  

  const handleClick = async () => {
    try {
      const hasError = validate()
      if(hasError) {
        return
      }
      let rv = await requestResetPassword({emailAddress: email}).unwrap()

      if(rv) {
        console.log("rv", rv)
        setToast({
          ...toast,
          open: true,
          message: "Email has been sent. Please check your email for the steps to reset your password!",
          type: 'success'
        })
      }
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: 'error'
      })
    }
  }

  const validate = () => {
    let hasError = false
    if(!email) {
      setErrors(prev => ({...prev, email: true})) 
      hasError = true
    }else {
      errors.email ? hasError = true : setErrors(prev => ({...prev, email: false}))
    }
    return hasError
  }

  return (
    <>
      <Toast {...toast} />
      <div className={styles.forgotPwModal}>
        <Card className={styles.card}>
          <h4 className='sectionTitle'>Forgot Password</h4>
          <p className='sectionPara' >Enter your email address and we will send you a link to reset your password.</p>
          <Box sx={{ mt: 4 }}>
            <InputLabel sx={{pb:1}} htmlFor="email">Email</InputLabel>
            <CustomTextField error={errors.email} helperText={errors.email ? 'Requires a valid email' : ''} onChange={handleChange} value={email} size="small" sx={{mb:4}} required id="email" name="email" placeholder='Email' fullWidth className={styles.input}/>

            <Button sx={{width: {xs: '100%', ms: 365}}} variant="contained" className="btnSecondary" onClick={handleClick}>Reset Password</Button>
            <Grid container spacing={2}>
              <Grid item sx={{mb:2, mt:4}}>
                <div className={styles.text}>Already have an account? <Link className={styles.link} sx={{ml:1}} onClick={() => props.onClick(1)}> Login</Link></div>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    </>
      
    )
}

export default ForgotPwForm