import { gqlApi } from '../../api/graphql'
import { gql } from 'graphql-request'
import { userRestApi } from './userRestApi'
import { removeAuthToken, setStatus } from '../auth/authSlice'
import { getUserState } from './userSlice'

import { setUser } from './userSlice'

export const userApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.mutation({
      query: () => ({
        body: gql`
          query {
            activeCustomer {
              id
              firstName
              lastName
              phoneNumber
              emailAddress
              user {
                id
              }
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data.activeCustomer) {
            dispatch(setUser(data));
            dispatch(userRestApi.endpoints.getUserInvites.initiate(null));
          }else {
            dispatch(removeAuthToken(null))
          }
        } catch (error) {}
      },
    }),
  }),
})

export const { useGetMeMutation } = userApi