import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { guest } from '../../types';

export interface GuestlistState {
  selectedList: guest[]
}

const initialState: GuestlistState  = {
  selectedList: []
};

export const guestlistSlice = createSlice({
  initialState,
  name: 'guestlist',
  reducers: {

  }
})

export const getGuestlistState = (state: RootState) => state.guestlist;

export default guestlistSlice.reducer;

export const {  } = guestlistSlice.actions;