import { Box, Typography } from "@mui/material"
import CondolenceMessage from "../../components/webview/condolenceMessage/CondolenceMessage"
import Donate from "../../components/donate/Donate"
import Give from "../give/Give"
import Overlay from '../../components/Overlay/Overlay'
import { getCurrentWidthDimension } from '../../constants'
import { useEffect } from 'react'
import { useState } from 'react'

function Donation() {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])
  return (
    <>
      <div>
        {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
        <Box
          sx={{
            py: 2,
          }}
        >
          <Donate className={'divDonateNormal'} />
        </Box>
        <Overlay>
          {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
          <Box sx={{ py: 2 }}>
            <CondolenceMessage />
          </Box>

          {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

          <Box sx={{ py: 2 }}>
            <Give />
          </Box>

          {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
          {/* <Box sx={{ py: 2 }}>
          <Donate className={'divDonatePink'}/>
        </Box> */}
        </Overlay>
      </div>
    </>
  )
}

export default Donation
