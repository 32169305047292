import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector } from '../../../app/hooks' 
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import CustomTextField from "../../base/Textfield/CustomTextField"
import CustomSearch from "../../base/Textfield/CustomSearch"
import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from "@mui/icons-material/Search"
import Checkbox from "@mui/material/Checkbox"
import { CardMedia } from "@mui/material"
import { CheckedIcon, Icon, IndeterminateIcon } from "../../base/Checkbox/CustomCheckbox"
import { useGetProductsQuery } from "../../../features/products/productGqlApi"
import { getProductState } from '../../../features/products/productSlice'
import { getRfmEditState } from '../../../features/rfmEdit/rfmEditSlice'
import { getFuneralState } from '../../../features/funeral/funeralSlice'
import { useGetFuneralMasterProductsQuery } from '../../../features/funeral/funeralRestApi'
import { useAddEventProductsMutation } from '../../../features/rfmEdit/rfmEditRestApi'
import Button from "../../base/Button/Button"
import Toast from '../../base/Toast/Toast'
import styles from './EventSteps.module.css'

interface EventProductListProps {
  handleStepper: (step: number) => void
}
interface ProductObject {
  [key: string]: any
}

const EventProductList: React.FC<EventProductListProps> = (props) => {
  const {id} = useParams()
  useGetProductsQuery('')
  const masterProducts = useGetFuneralMasterProductsQuery({funeralId: id}, { refetchOnMountOrArgChange: true })
  const funeralState = useAppSelector(getFuneralState)
  let products = useAppSelector(getProductState)
  let rfmEditState = useAppSelector(getRfmEditState)
  const { event_details } = rfmEditState
  const { details } = funeralState
  const [page, setPage] = useState(1)
  const itemPerPage = 8
  const [query, setQuery] = useState("")
  const [selected, setSelected] = useState<readonly any[]>([])
  const [filtered, setFiltered] = useState<readonly any[]>([])
  const [paginated, setPaginated] = useState<readonly any[]>([])
  let [filteredItems, setFilteredItems] = useState([{}])
  let tempItemList: ProductObject[] = []
  const [totalPages, setTotalPages] = useState(0)
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => {setToast({...toast, open: false})}
  })
  const [imageHeight, setImageHeight] = useState(window.innerWidth)
  const [addEventProductsList] = useAddEventProductsMutation()

  useEffect(() => {
    if (window.innerWidth < 768) {
      setImageHeight(window.innerWidth / 2 - 20)
    }else{
      setImageHeight(window.innerWidth / 5 - 20)
    }
  },[window.innerWidth])
  
  //Set previously selected items
  useEffect(() => {
    let x: any = []
    let eventProducts = event_details.products
    products.items.map((item) => {
      item.variants.map((subItem: any) => {
        if(eventProducts?.split(',').includes(subItem.id)) {
          let tempItem: ProductObject = {}
          tempItem.id = subItem.id
          tempItem.name = subItem.name
          tempItem.price = subItem.price
          tempItem.description = item.description
          tempItem.image = subItem.assets[0]?.source
          x.push(tempItem)
        }
      })
    })
    
    setSelected(x)
  }, [event_details, products])

  //display products base on master list
  useEffect(() => {
    let productArray: any[] = []
    productArray = products.items
    setFiltered(productArray)
  }, [products.items])

  useEffect(() => {
    filtered.map((item) => {
      item.facetValues.map((facetItem:any) => {
        if (facetItem.name == details?.religion) {
          item.variants.map((subItem: any) => {
            let tempItem: ProductObject = {}
            if(masterProducts.data?.products.split(',').includes(subItem.id)) {
              tempItem.id = subItem.id
              tempItem.name = subItem.name
              tempItem.price = subItem.price
              tempItem.description = item.description
              tempItem.image = subItem.assets[0]?.source
              tempItemList.push(tempItem)
            }
          })
        }
      })
    })
    setFilteredItems(tempItemList)
    let start = (page - 1) * itemPerPage
    let end = page * itemPerPage
    let paginatedData = tempItemList.slice(start, end)
    setPaginated(paginatedData)
    setTotalPages(Math.ceil(tempItemList.length / itemPerPage))
  }, [filtered, page, details, masterProducts])

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredItems
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map((x) => x.id).indexOf(row.id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected.push(...selected, row)
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    } else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  }

  const isSelected = (id: string | number) => {
    //console.log("selected", selected)
    return selected.map((x) => x.id).indexOf(id) !== -1
  }

  const onSave = async (selected: any) => {
    try {
      let idArr = selected.map((item: any) => item.id)
      let body = {
        funeralId: id,
        eventId: event_details.id,
        query: {
          products: idArr.toString()
        }
      }
      let rv = await addEventProductsList(body).unwrap()
      if(rv) {
        setToast({
          ...toast,
          open: true,
          message: "Successfully updated products for event!",
          type: 'success'
        })
      }

    }catch(e) {
      console.error('rejected', e);
    }
  }

  return (
    <>
    <Toast {...toast}/>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={3}>
          <CustomSearch
            sx={{ mb: {xs:2, sm:4}, width: {xs: '100%'} }}
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "var(--TLC-web-grey)" }} />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} >
          <Checkbox
            icon={<Icon />}
            checkedIcon={<CheckedIcon />}
            indeterminateIcon={<IndeterminateIcon />}
            color="primary"
            indeterminate={selected.length > 0 && selected.length < filteredItems.length}
            checked={filteredItems.length > 0 && selected.length === filteredItems.length}
            onChange={handleSelectAllClick}
          />
          <span style={{ marginRight: 10 }}>Select All</span>
          <Button className="btnPrimary" sx={{ width: { xs: '100%', sm: 300 } }} onClick={() => onSave(selected)}>
            Save
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {paginated &&
            paginated.map((item, index) => {
              //const isItemSelected = isSelected(item.id)
              const labelId = `productlist-checkbox-${item.id}`
              return (
                <Grid key={index} item xs={12} md={3}>
                  <Checkbox
                    icon={<Icon />}
                    checkedIcon={<CheckedIcon />}
                    onClick={(event) => handleClick(event, item)}
                    color="primary"
                    checked={isSelected(item.id)}
                    inputProps={{
                      "aria-labelledby": labelId
                    }}
                  />
                  <Box sx={{height: `${imageHeight}px`, backgroundColor: 'white'}}>
                    <CardMedia
                        component="img"
                        image={`${item.image}`} 
                        className={styles.listImg}
                        alt={item.name} loading="lazy"
                      />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>{item.name}</Typography>
                    <Typography>${item.price / 100}</Typography>
                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                  </Box>
                </Grid>
              )
            })}
        </Grid>
      </Box>
      {totalPages > 1 ? (
        <Stack spacing={2}>
          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={(e, page) => setPage(page)} />
        </Stack>
      ) : (
        <></>
      )}
      <Box>
        <Grid container spacing={2} justifyContent="end" alignItems="center" sx={{mt:2}}>
          {/* <Grid item xs={6} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={prevStep}>Previous</Button>
          </Grid> */}
          <Grid item xs={12} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: '100%', sm: 300}, mb: 4}} onClick={()=>props.handleStepper(2)}>Next</Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EventProductList