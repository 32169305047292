import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Typography } from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import styles from "./Donate.module.css"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { useGetImageUrlMutation } from "../../features/base/baseRestApi"
import { setEditPayNow } from '../../features/rfmEdit/rfmEditSlice'
import DragAndDrop from '../base/Upload/DragAndDrop'
import CustomTextField from '../base/Textfield/CustomTextField'
import { debounce } from '../../constants'
import { getCurrentWidthDimension } from '../../constants'

interface DonateProps {
  className: string
}

const Donate: React.FC<DonateProps> = (props) => {
  const dispatch = useAppDispatch()
  const funeralState = useAppSelector(getFuneralState)
  const { bankDetails, paynow_image, paynow_approval } = funeralState.details
  const isEdit = funeralState.isEdit
  const [paynowImg, setPaynowImg] = useState('')
  const [editDetails, setEditDetails] = useState<any>({
    details: '',
    file: ''
  })
  const [getImageUrl] = useGetImageUrlMutation()

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    try {
      getURL(paynow_image)
    } catch (e: any) {
      console.log(e.toString())
    }
  }, [paynow_image])

  useEffect(() => {
    if (isEdit) {
      setEditDetails({
        details: bankDetails ? bankDetails : '',
        file: ''
      })
    }

  }, [isEdit])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setPaynowImg(rv.imageUrl)
    } catch (e) {

    }
  }

  const storeDonate = () => {
    if (isEdit) {
      dispatch(setEditPayNow({ payNow: editDetails, toSave: 'payNow' }))
    }
  }

  const debounceHandler = useCallback(
    debounce(storeDonate())
    , []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setEditDetails({
      ...editDetails,
      details: value.replace(/\n\r?/g, '<br />')
    })

    debounceHandler()
  }

  // handle File events
  const handleFile = function (e: any) {
    setEditDetails({
      ...editDetails,
      file: e[0]
    })
  }

  const clearUpload = () => {
    setEditDetails({
      ...editDetails,
      file: ''
    })
  }

  return (
    isEdit ? (
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <div className={`${styles[props.className]}`}>
            <Typography className="sectionTitle">
              <b>Donate</b>
            </Typography>
            <Typography className='sectionText'>Scan to donate</Typography>
            <br />
            <DragAndDrop label="paynow" filetype='.jpeg, .png' handleFile={handleFile} currFileName={paynow_image} file={editDetails.file} clearUpload={clearUpload} />
            <br />
            <br />
            <CustomTextField className='text-input' fullWidth sx={{ mb: 4 }}
              multiline
              rows={3}
              value={editDetails.details?.replace(/<br \/>?/g, '\n')}
              onChange={handleChange} id='content'
              name='content' size='small'
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={screenWidth <= 550 ? 0 : 2}>
        <Typography
          className="sectionTitle"
          sx={{
            pl: screenWidth <= 550 ? 0 : 2,
            mb: screenWidth <= 550 ? 1 : 0
          }}
        >
          <b>Donate</b>
        </Typography>
        <Grid item xs={12} md={12}
          sx={{
            margin: '0px auto',
          }}
        >
          {
            paynow_approval ?
              <div className={`${styles[props.className]}`}>
                <Typography
                  className='sectionText'
                  sx={{
                    mb: 1,
                    mt: screenWidth <= 550 ? 3 : 2
                  }}
                >
                  Scan to donate
                </Typography>
                <div className={styles.scanContainer}>
                  {/* {
                paynow_approval ? <img src={paynowImg} className="img" alt='paynow_image' /> : <></>
              } */}
                  <img src={paynowImg} className="img" alt='paynow_image' />
                  <div className={styles.donateDetails}>
                    <p dangerouslySetInnerHTML={{ __html: bankDetails }}></p>
                  </div>

                </div>
                <p className={styles.notes}>
                  All proceedings are credited directly to the family’s account
                </p>
              </div>
              :
              <>Ask admin to approve scan image change.</>
          }

        </Grid>
      </Grid>
    )
  )

}

export default Donate
