import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { invite, event, funeralDetails } from '../../types';

export interface FuneralState {
  details: funeralDetails
  invites: invite[]
  events: event[]
  images: string[]
  isEdit: boolean
}

const initialState: FuneralState  = {
  details: {
    id: null,
    createdAt: null,
    dob: null,
    dod: null,
    firstName: '',
    lastName: '',
    note: '',
    obituary: '',
    race: '',
    religion: '',
    bankDetails: '',
    status: '',
    obituary_image: '',
    banner_image: '',
    video_url: '',
    profile_image: '',
    paynow_image:'',
    paynow_approval: 0,
    showObituary: 1,
    showGifts: 1,
    showGallery: 1,
    showMessage: 1
  },
  invites: [],
  events: [],
  images: [],
  isEdit: false

};

export const funeralSlice = createSlice({
  initialState,
  name: 'funeral',
  reducers: {
    setFuneralDetails: (state, {payload}) => {
      state.details = {...payload.details}
      state.events = payload.events
      state.invites = payload.invites
    },
    setIsEdit: (state, {payload}) => {
      state.isEdit = payload
    }
  },
});

export const getFuneralState = (state: RootState) => state.funeral;

export default funeralSlice.reducer;

export const { setFuneralDetails, setIsEdit } = funeralSlice.actions;