import {useState} from 'react'
import { Grid, Box, Typography, Avatar, ListItem, ListItemButton, ListItemText, Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import styles from "./Checkout.module.css"
import { useGetOrderQuery } from "../../features/order/orderGqlApi"
import { useParams } from 'react-router-dom'

function OrderSummary() {
  const { id } = useParams()
  const { data, isLoading } = useGetOrderQuery(id)
  const activeOrder = data?.activeOrder
  const orderCode = activeOrder?.code
  const lines = activeOrder?.lines

  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false)

  if (!isLoading && lines != undefined) {
    const { subTotal, subTotalWithTax, totalWithTax, shipping, totalQuantity } = activeOrder
    if (window.innerWidth > 768) {
      return (
        <Box sx={{ ml: 3 }}>
          <Typography sx={{ mb: 2, mt: 6 }} component={"div"} variant={"body2"} className="hr"></Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{ mt: 3 }} component={"p"} className="sectionTitle">
                <b>Order Summary</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ mt: 3, mr: 1, float: "right" }} component={"div"} className="sectionTitle">
                <Avatar className={styles.orderAvatar}>{totalQuantity}</Avatar>
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ mb: 2, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
          <Grid>
            {lines.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <Grid sx={{ mb: 2, mt: 2 }}>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="20%">
                            <Box
                              component="img"
                              sx={{
                                height: 50,
                                width: 50
                              }}
                              src={item.featuredAsset.preview}
                            />
                          </td>
                          <td width="50%">
                            <Typography className={styles.productPrice}>
                              {item.quantity} x {item.productVariant.name}
                            </Typography>
                          </td>
                          <td width="30%">
                            <Typography className={styles.productPrice} align="right">
                              ${((item.quantity * item.productVariant.price) / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                </div>
              )
            })}
          </Grid>
          <Grid>
            <Grid sx={{ mb: 2, mt: 2 }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="left">
                        Subtotal
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${(subTotal / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="left">
                        GST(8%)
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${((subTotalWithTax - subTotal) / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="left">
                        Delivery
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${(shipping / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
          </Grid>
          <Grid>
            <Grid sx={{ mb: 2, mt: 2 }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="left">
                        Total
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${((subTotalWithTax + shipping) / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Box>
      )
    }else {
      return (
        <Box sx={{ mx: 3 }}>
          <Grid container>
            <Grid item xs={12}>
              <ListItem disablePadding >
                <ListItemButton sx={{px: 0}} onClick={() => setOrderSummaryOpen(!orderSummaryOpen)}>
                  <ListItemText primary="Order Summary" />
                  {orderSummaryOpen ? <ExpandLess sx={{}} />:<ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Typography component={"div"} variant={"body2"} className="hr"></Typography>
              <Collapse className={styles.orderSummary} in={orderSummaryOpen} timeout="auto" unmountOnExit>
                <Grid>
                  {lines.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <Grid sx={{ mb: 2, mt: 2 }}>
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td width="20%">
                                  <Box
                                    component="img"
                                    sx={{
                                      height: 50,
                                      width: 50
                                    }}
                                    src={item.featuredAsset.preview}
                                  />
                                </td>
                                <td width="50%">
                                  <Typography className={styles.productPrice}>
                                    {item.quantity} x {item.productVariant.name}
                                  </Typography>
                                </td>
                                <td width="30%">
                                  <Typography className={styles.productPrice} align="right">
                                    ${((item.quantity * item.productVariant.price) / 100).toFixed(2)}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                        <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                      </div>
                    )
                  })}
                </Grid>
                <Grid>
                  <Grid sx={{ mb: 2, mt: 2 }}>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="left">
                              Subtotal
                            </Typography>
                          </td>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="right">
                              ${(subTotal / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="left">
                              GST(8%)
                            </Typography>
                          </td>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="right">
                              ${((subTotalWithTax - subTotal) / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="left">
                              Delivery
                            </Typography>
                          </td>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="right">
                              ${(shipping / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                </Grid>
                <Grid>
                  <Grid sx={{ mb: 2, mt: 2 }}>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="left">
                              Total
                            </Typography>
                          </td>
                          <td width="50%">
                            <Typography className={styles.productPrice} align="right">
                              ${((subTotalWithTax + shipping) / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Typography component={"div"} variant={"body2"} className="hr"></Typography>
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      )
    }
  } else {
    return null
  }
}

export default OrderSummary
