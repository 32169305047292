import React from 'react'
import { Box } from '@mui/material'
import { useAppSelector } from '../../app/hooks';
import { getFuneralState } from '../../features/funeral/funeralSlice';
import Styles from './Overlay.module.css'

type OverlayProps = {
  children: React.ReactNode;
};

const Overlay: React.FC<OverlayProps> = (props) => {
  const funeralState = useAppSelector(getFuneralState)
  const {isEdit} = funeralState
  
  
  if(isEdit) {
    return (
      <Box className={Styles.overlay}>
        {props.children}
      </Box>
    )
  }else {
    return (
      <Box>
        {props.children}
      </Box>
    )
  }
  
}

export default Overlay