import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_EXPRESS_API

if (!API_URL) {
  throw new Error(
    `The environment variable REACT_APP_EXPRESS_API is missing and it's required to access your store`
  )
}

export const customFetchQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('vendure-auth-token')
    //const token = (getState() as RootState).auth.auth.access_token

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  }
})

export const restApi = createApi({
  reducerPath: 'restApi',
  baseQuery: customFetchQuery,
  tagTypes: ['condolenceMsg', 'funeral','funeralDashboard','selectedChecklist', 'approvalTable', 'GetEventlist', 'initialiseAccount', 'isRfm', 'messages', 'guestlist', 'mainGuestlist', 'getEvent', 'rfmlist', 'broadcast'],
  endpoints: (builder) => ({})
})