import { Box, Typography } from '@mui/material'
import { useAppSelector } from '../../app/hooks'
import DonationSection from '../../components/donate/DonationSection'
import ObituaryComponent from '../../components/obituary/Obituary'
import CondolenceMessage from '../../components/webview/condolenceMessage/CondolenceMessage'
import Give from '../give/Give'
import Overlay from '../../components/Overlay/Overlay'
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { getCurrentWidthDimension } from '../../constants'
import { useEffect } from 'react'
import { useState } from 'react'

function Obituary() {
  const funeralState = useAppSelector(getFuneralState)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isGiftsEnabled, setIsGiftsEnabled] = useState<number>(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    setIsGiftsEnabled(funeralState.details.showGifts)
  }, [funeralState.details.showGifts])

  return (
    <>
      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

      {
        funeralState.details.showObituary ? (
          <>
            <Box sx={{ py: 2 }}>
              <ObituaryComponent fulltext={true} />
            </Box>
            {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}
          </>
        ) : null
      }

      <Overlay>

        <Box sx={{ py: 2 }}>
          <CondolenceMessage />
        </Box>

        {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

        {
          isGiftsEnabled &&
          <Box sx={{ py: 2 }}>
            <Give />
          </Box>
        }

        {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

        <Box sx={{ py: 2 }}>
          <DonationSection />
        </Box>
      </Overlay>

    </>


  )
}

export default Obituary