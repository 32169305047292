import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

export interface AuthState {
  auth: {
    access_token: string
    refresh_token: string
  },
  loggedInFailed: boolean
}

const initialState: AuthState  = {
  auth: {
    access_token: '',
    refresh_token: ''
  },
  loggedInFailed: false
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    backendLogout: (state) =>{
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      state = initialState
    },
    setAuthToken: (state, {payload}) => {
      const {access_token, refresh_token} = payload
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      state.auth = {
        access_token: access_token, 
        refresh_token: refresh_token
      }
    },
    removeAuthToken: (state, {payload}) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      state.auth = {
        access_token: '', 
        refresh_token: ''
      }
    },
    setStatus: (state, {payload}) => {
      state.loggedInFailed = true
    },
    resetStatus: (state, {payload}) => {
      state.loggedInFailed = false
    },
  },
});

export const getAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;

export const { backendLogout, setAuthToken, removeAuthToken, setStatus, resetStatus } = authSlice.actions;