import React from 'react'
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const TlcTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid var(--TLC-web-grey)',
      borderRadius: '3px',
    },
    '&:hover fieldset': {
      border: '1px solid #545454'
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #545454'
    },
  },
});



const CustomTextField: React.FC<TextFieldProps> = (props) => {
  return (
    <TlcTextField {...props} />
  )
}

export default CustomTextField