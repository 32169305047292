import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import { CheckoutForm } from "./CheckoutForm"

let _stripe: Promise<Stripe | null>
function getStripe(publishableKey: string) {
  if (!_stripe) {
    _stripe = loadStripe(publishableKey)
  }
  return _stripe
}

const stripePromise = getStripe(process.env.REACT_APP_STRIPE_PK || "")

type StripePaymentsProps = {
  clientSecret: string
  orderCode: string
}

export function PayNowPayments({ clientSecret, orderCode }: StripePaymentsProps) {
  const options = {
    clientSecret
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm orderCode={orderCode} clientSecret={clientSecret} />
    </Elements>
  )
}
