import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import MenuItem from "@mui/material/MenuItem"
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import DonationSection from '../../components/donate/DonationSection'
import SurvivedbyCard from '../../components/survivedby/SurvivedbyCard'
import CondolenceMessage from '../../components/webview/condolenceMessage/CondolenceMessage'
import CustomSelect from '../../components/base/Select/CustomSelect'
import { SelectChangeEvent } from "@mui/material/Select"
import Give from '../give/Give'
import Overlay from '../../components/Overlay/Overlay'
import styles from './Survivedby.module.css'
import { useGetRfmlistQuery } from '../../features/funeral/funeralRestApi'
import { getRfmEditState } from '../../features/rfmEdit/rfmEditSlice'
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { setExecutorRfm } from '../../features/rfmEdit/rfmEditSlice'


function Survivedby() {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const rfmEditState = useAppSelector(getRfmEditState)
  const funeralState = useAppSelector(getFuneralState)
  const { executorRfm } = rfmEditState
  const { isEdit } = funeralState
  const { data, isLoading } = useGetRfmlistQuery(id, {refetchOnMountOrArgChange: true})
  const [rfmlist, setRfmlist] = useState<any[]>([])
  const [mainRfm, setMainRfm] = useState<any>('')

  useEffect(() => {
    if(data && !isLoading) {
      setRfmlist(data)
      let found = data.find((item: any) => {
        return item.main === 1
      })
      setMainRfm(found)
    }
  }, [data, isLoading])

  const formatName = (first: string, last: string) => {
    return first.charAt(0).toUpperCase() + first.slice(1) + ' ' + last.charAt(0).toUpperCase() + last.slice(1)
  }

  const handleExecutorChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setMainRfm(value)
    dispatch(setExecutorRfm(value))
  }
  
  return (
    <>
      <Typography  component={'div'} variant={'body2'} className="hr"></Typography>
      <Typography sx={{my:4}}  className="sectionTitle">Survived By</Typography>

      <Box className={styles.flexContainer} style={{}}>
        {
          rfmlist.map((item, index) => {
            return (
              <Box sx={{p: 2 }} key={index}>
                <SurvivedbyCard data={item} index={index} key={index} />
              </Box>
            )
          })
        }
        {/* <Box sx={{mt:4}}>
          <Button className='btnSecondary' sx={{width: {md: 400}}} onClick={addNewRFM}>Add RFM</Button>
        </Box> */}
        
      </Box>
      
      <Box sx={{py:4, mx: {xs: 2, sm: 0}}}>
        <Typography className='sectionTitle'><b>Executor Registered Family Member</b></Typography>
        {
         isEdit ? (
          <CustomSelect
            className={styles.input}
            sx={{width: {xs:'100%', sm:300}}}
            size="small"
            value={mainRfm}
            onChange={handleExecutorChange}
          >
            {rfmlist.map((item, index) => (
              <MenuItem 
                key={index} 
                value={item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'var(--TLC-web-grey)',
                    '&:hover': {
                      backgroundColor: 'var(--TLC-web-grey)',
                    },
                  },
                }}
              >
                {formatName(item.firstName, item.lastName)}
              </MenuItem>
            ))}
          </CustomSelect>
         )
         : <Typography className={styles.execRFM}>{mainRfm ? formatName(mainRfm.firstName, mainRfm.lastName) : ''}</Typography>
        }
        
      </Box>
        
      <Overlay>
      <Typography  component={'div'} variant={'body2'} className="hr"></Typography>

      <Box sx={{py: 2 }}>
        <CondolenceMessage />
      </Box>

      <Typography  component={'div'} variant={'body2'} className="hr"></Typography>
      
      <Box sx={{py: 2 }}>
        <Give />
      </Box>

      <Typography  component={'div'} variant={'body2'} className="hr"></Typography>

      <Box sx={{py: 2 }}>
        <DonationSection />
      </Box>
      </Overlay>
    </>


  )
}

export default Survivedby