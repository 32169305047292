import React, { useState, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom"
import { Typography, InputLabel, Box, Grid, Button, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material"
import { StripePayments } from "../../components/checkout/stripe/StripePayments"
import { PayNowPayments } from "../../components/checkout/paynow/PayNowPayments"
import { useGetOrderQuery } from "../../features/order/orderGqlApi"
import { useGetCreateStripePaymentIntentQuery, useGetPaymentMethodsQuery } from "../../features/payment/paymentGqpApi"

interface PaymentProps {
  handleStepper: (step: number) => void
}

const Payment: React.FC<PaymentProps> = (prop) => {
  const { id } = useParams()
  const response = useGetPaymentMethodsQuery("")
  // const paymentMethods = response?.data?.eligiblePaymentMethods
  const paymentMethods = ["Credit Card/PayNow"]

  const [paymentMethod, setPaymentMethod] = useState(0)

  const stripeResponse = useGetCreateStripePaymentIntentQuery("")
  console.log(stripeResponse)
  const stripe_payment_intent = stripeResponse?.data?.createStripePaymentIntent
  const requestId = stripeResponse?.requestId

  const { data, isLoading } = useGetOrderQuery(id)
  const activeOrder = data?.activeOrder
  const orderCode = activeOrder?.code

  if (paymentMethods != undefined && stripe_payment_intent != undefined) {
    return (
      <div>
        <Box>
          <InputLabel className="sectionText">Payment Method</InputLabel>
        </Box>
        <FormControl>
          <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={0} onChange={(e: any) => setPaymentMethod(e.target.value)}>
            {paymentMethods.map((item: any, index: any) => {
              return <FormControlLabel value={index} key={index} control={<Radio />} label={item} />
            })}
          </RadioGroup>
        </FormControl>
        <Typography sx={{ mt: 3 }} component={"div"} variant={"body2"} className="hr"></Typography>

        {(() => {
          if (paymentMethod == 0) {
            return (
              <Grid item xs={12} md={6}>
                <Box sx={{}}>
                  <StripePayments clientSecret={stripe_payment_intent} orderCode={orderCode}></StripePayments>
                </Box>
              </Grid>
            )
          } else {
            return (
              <Grid item xs={12}>
                <PayNowPayments clientSecret={stripe_payment_intent} orderCode={orderCode}></PayNowPayments>
              </Grid>
            )
          }
        })()}
      </div>
    )
  } else {
    return null
  }
}

export default Payment
