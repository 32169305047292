import { restApi } from "../../api/restApi";

export const profileRestApi = restApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrderHistory: builder.query({
            query: (data) => ({
                url: `/api/app-template/profile/orders/${data.userId}`,
                method: 'GET',
            }),
        }),
        getInviteAccounts: builder.query({
            query: (data) => ({
                url: `/api/app-template/profile/inviteAccounts/${data.id}/${data.userEmail}`,
                method: 'GET'
            }),
        }),
        getRelationship: builder.query({
            query: (data) => ({
                url: `/api/app-template/profile/getRelationship/${data.id}/${data.userEmail}`,
                method: 'GET'
            })
        }),
        setUpdateRelationship: builder.mutation({
            query: (data) => ({
                url: `/api/app-template/profile/setUpdateRelationship/${data.id}/${data.userEmail}/${data.relationship}`,
                method: 'POST'
            })
        }),
        updateInvitesName: builder.mutation({
            query: (payload) => ({
                url: `/api/app-template/profile/update/invites`,
                method: 'POST',
                body: payload
            })
        }),
    })
})

export const { useGetOrderHistoryQuery, useGetInviteAccountsQuery, useGetRelationshipQuery, useSetUpdateRelationshipMutation, useUpdateInvitesNameMutation } = profileRestApi