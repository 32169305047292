import React from 'react'
import { useAppSelector } from '../../app/hooks';
import { getAuthState } from '../../features/auth/authSlice';
import { getFuneralState } from '../../features/funeral/funeralSlice';
import { getUserState } from '../../features/user/userSlice';

function Test() {
  const tokens = useAppSelector(getAuthState);
  const funeral = useAppSelector(getFuneralState)
  const user =  useAppSelector(getUserState)

  console.log("tokens", tokens)
  console.log("funeral", funeral)
  console.log("user", user)
  return (
    <div>test</div>
  )
}

export default Test