import { gqlApi } from "../../api/graphql"
import { gql } from "graphql-request"
import { userApi } from "../user/userGqlAPI"
import { logout } from "../user/userSlice"
import { backendLogout } from "./authSlice"

export const authGqlApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    setUpdateCustomer: builder.mutation({
      query: (data) => ({
        body: gql`
          mutation {
            updateCustomer(input: {
              firstName: "${data.firstName}"
              lastName: "${data.lastName}"
              phoneNumber: "${data.phoneNumber}"
            }){
              __typename
            }
          }
        `
      })
    }),
    getActiveCustomer: builder.query({
      query: () => ({
        body: gql`
          query {
            activeCustomer {
              id
              firstName
              lastName
              phoneNumber
              emailAddress
            }
          }
        `
      })
    }),
    requestPasswordReset: builder.mutation({
      query: (body) => ({
        body: gql`
          mutation {
            requestPasswordReset (
              emailAddress: "${body.emailAddress}"
            ) {
              ... on Success{
                success
              }
              ... on NativeAuthStrategyError{
                errorCode,
                message
              }
            }
          }
        `,
        headers: {
          "X-Fc-Invocation-Type": `Async`
        }
      })
    }),
    login: builder.mutation({
      query: (user) => ({
        body: gql`
          mutation {
            login (
              username: "${user.email}",
              password: "${user.password}",
            ) {
              ... on CurrentUser{
                id,
                identifier,
              }
              ... on InvalidCredentialsError{
                errorCode,
                message
              }
              ... on NotVerifiedError{
                errorCode,
                message
              }
              ... on NativeAuthStrategyError{
                errorCode,
                message
              }
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {}
      }
    }),
    logout: builder.mutation({
      query: () => ({
        body: gql`
          mutation {
            logout {
              success
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled
          if (data.logout.success) {
            dispatch(logout())
            dispatch(backendLogout())
          }
        } catch (error) {}
      }
    }),
    register: builder.mutation({
      query: (data) => ({
        body: gql`
        mutation {
          registerCustomerAccount (
            input: {
              emailAddress: "${data.email}"
              firstName: "${data.firstName}"
              lastName: "${data.lastName}"
              phoneNumber: "${data.phoneNumber}"
              password: "${data.password}"
            }
          ) {
            ... on Success {
              success
            }
            ... on MissingPasswordError {
              errorCode
              message
            }
            ... on PasswordValidationError {
              errorCode
              message
              validationErrorMessage
            }
            ... on NativeAuthStrategyError{
              errorCode
              message
            }
          }
        } 
        `
      })
    }),
    resetPasword: builder.mutation({
      query: (body) => ({
        body: gql`
          mutation {
            resetPassword (
              token: "${body.token}",
              password: "${body.password}"
            ) {
              ... on PasswordResetTokenInvalidError{
                errorCode
                message
              }
              ... on PasswordResetTokenExpiredError{
                errorCode,
                message
              }
            }
          }
        `
      })
    }),
    updateCustomerPassword: builder.mutation({
      query: (data) => ({
        body: gql`
          mutation {
            updateCustomerPassword (
              currentPassword: "${data.currentPassword}",
              newPassword: "${data.newPassword}"
            ){
              __typename
              ... on Success{
                success
              }
              ... on InvalidCredentialsError{
                errorCode
                message
                authenticationError
              }
              ... on PasswordValidationError{
                errorCode
                message
                validationErrorMessage
              }
              ... on NativeAuthStrategyError{
                errorCode
                message
              }
            }
          }
        `
      })
    })
  })
})

export const { useLoginMutation, useRequestPasswordResetMutation, useLogoutMutation, useRegisterMutation, useResetPaswordMutation, useGetActiveCustomerQuery, useSetUpdateCustomerMutation, useUpdateCustomerPasswordMutation } = authGqlApi
