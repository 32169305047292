import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import styles from './Styles.module.css'

function ScrollToTop() {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const handleClick = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
  }

  window.addEventListener('scroll', toggleVisible);

  return (
    <IconButton disableRipple onClick={handleClick} className={styles.scrollToTopBtn} style={{display: visible ? 'inline' : 'none'}}>
      <img
        src='/images/scroll-top.svg'
        loading="lazy"
        className={styles.image}
      />
    </IconButton>
  )
}

export default ScrollToTop