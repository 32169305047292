import { useEffect, useState } from "react"
import { Avatar, Button, Grid, Typography } from "@mui/material"
import styles from "./Profile.module.css"
import { Link } from "react-router-dom"
import { useGetImageUrlMutation } from "../../features/base/baseRestApi"
import { formatDate } from '../../constants'

const InviteProfile = (props: any) => {
  const [imageURL, setImageURL] = useState("")
  const [getImageUrl] = useGetImageUrlMutation()
  const data = props.data

  useEffect(() => {
    if (data.profile_image) {
      getURL(data.profile_image)
    }
  }, [data.profile_image])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setImageURL(rv.imageUrl)
    } catch (e) {}
  }

  return (
    <Grid container sx={{mt: 1, backgroundColor: {xs: 'white', md: 'transparent'}, p: {xs: 1, md: 0} }}>
      <Grid item sx={{ p: 0,}} xs={3} md={1}>
        <Avatar className={styles.avatar} alt={data.lastName} src={imageURL} />
      </Grid>
      <Grid item container xs={9} md={5} sx={{height:{xs: '70px', md: 'auto'}}}>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography className={styles.wvProfile} sx={{ fontSize: { xs: "18px", md: "18px" } }} gutterBottom component="div">
              {data.lastName}
            </Typography>
            <Typography className={styles.wvText} gutterBottom sx={{ pt: 1, fontSize: { xs: "16px", md: "16px" } }}>
              {formatDate(data.dob, "DD MMM YYYY") + " - " + formatDate(data.dod, "DD MMM YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{mt:{md:1, xs: 0}, pt: {xs:'0px!important'}}} xs={12} md={6}>
        <Link to={`../../../../${data.lastName}/${data.id}`} className='link' relative="path" style={{ textDecoration: 'none'}}>
            <Button  sx={{ mb:2 }} variant="outlined" className={styles.btnView} fullWidth>View</Button>
        </Link>
      </Grid>
    </Grid>
  )
}

export default InviteProfile
