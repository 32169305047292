import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './EventCreate.module.css'
import Stepper from '../../../components/base/Stepper/Stepper';
import CreateEventPage from '../../../components/events_rfm/EventSteps/CreateEventPage';
import EventShare from '../../../components/events_rfm/EventSteps/EventShare';
import EventProductList from '../../../components/events_rfm/EventSteps/EventProductList';

interface StepContainerProps {
  step: number
  handleStepper: (step: number) => void
}

const steps = ['Create Event', 'Products', 'Share'];
const headers = ['Create Event','Products', 'Share']

function StepContainer (props:StepContainerProps)  {
  switch(props.step) {
    case 0:
      return (<CreateEventPage handleStepper={props.handleStepper}/>)
    case 1:
      return (<EventProductList handleStepper={props.handleStepper} />)
    case 2:
      return (<EventShare handleStepper={props.handleStepper} />)
    default:
      return (<div>Default</div>)
  }
}

function EventCreate() {
  const { id, name } = useParams()
  const [activeStep, setActiveStep] = useState(0)


  const handleStepper = (step: number) => {
    setActiveStep(step)
  }

  const handleLabelClick = (step: number) => {
    if(activeStep !== 0 ) {
      setActiveStep(step)
    }
  }

  return (
    <Box sx={{mx: 1, py: 2, px:1}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <div className={styles.header}>{headers[activeStep]}</div>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link className={styles.link} to={`/${name}/${id}/events`}>
          Events
        </Link>,
        <div>Create Event</div>
      </Breadcrumbs>
      
      <Stepper steps={steps} active={activeStep} handleClick={handleLabelClick} sx={{width: '345px', pt: 2}}/>
      <StepContainer step={activeStep} handleStepper={handleStepper}/>
    </Box>
  )
}

export default EventCreate