import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { getCurrentWidthDimension, getYoutubeVideoId, getGoogleDriveFileId } from '../../constants'

function Gallery() {
    const navigate = useNavigate()
    const selectedFuneral: any = useAppSelector(getFuneralState)
    const [videoUrl, setvideoUrl] = useState('')

    const [screenWidth, setScreenWidth] = useState(getCurrentWidthDimension());

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(getCurrentWidthDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenWidth])

    useEffect(() => {
        if (!selectedFuneral.details.showGallery) {
            navigate(`/${selectedFuneral.details.lastName}/${selectedFuneral.details.id}/about`)
        }

        if (selectedFuneral.details.video_url) {
            const url: string = selectedFuneral.details.video_url

            if (url.includes('www.youtube.com')) {
                setvideoUrl(`https://www.youtube.com/embed/${getYoutubeVideoId(url)}?autoplay=1`)
            } else if (url.includes('drive.google.com')) {
                setvideoUrl(`https://drive.google.com/file/d/${getGoogleDriveFileId(url)}/preview?start=1`)
            } else {
                setvideoUrl(url)
            }
        }
    }, [selectedFuneral, navigate])

    return (
        <Box width={'100%'}
            sx={{
                display: 'flex',
                mt: screenWidth < 600 ? 'auto' : '10px',
                justifyContent: 'center',
                position: 'relative',
                paddingTop: '56.25%',
                overflow: 'hidden',
            }}
        >
            <iframe
                src={videoUrl}
                title="Embedded Video"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    border: 'transparent'
                }}
            />
        </Box>
    )
}

export default Gallery