import React from 'react'

function LinkIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.374023 19.6345C0.489742 19.1392 0.573091 18.6335 0.727652 18.1512C0.994694 17.3177 1.47537 16.6031 2.09119 15.9849C3.82453 14.2459 5.56274 12.5109 7.29932 10.7751C7.31631 10.7581 7.33654 10.7452 7.377 10.7136C7.38348 10.7614 7.39643 10.7962 7.39238 10.8277C7.23134 11.9768 7.38267 13.0968 7.71121 14.1981C7.76543 14.3786 7.73063 14.4903 7.59711 14.6222C6.4642 15.7429 5.33534 16.8678 4.21377 17.9998C2.95867 19.2663 3.0056 21.1728 4.30925 22.3284C5.38875 23.2849 7.07193 23.2889 8.14657 22.3251C8.50344 22.0055 8.83441 21.6567 9.17347 21.3176C10.6228 19.8691 12.0753 18.4239 13.5182 16.9697C14.6745 15.8052 14.7207 14.0201 13.6387 12.8565C13.6169 12.833 13.5975 12.8039 13.5716 12.786C13.3215 12.6137 13.1605 12.4106 13.1629 12.0739C13.1653 11.637 13.1605 11.2105 13.4583 10.8496C13.5052 10.7929 13.5505 10.7355 13.6015 10.6837C13.8799 10.3989 14.1599 10.1164 14.4463 9.82593C15.0889 10.1715 15.6229 10.6254 16.0607 11.1887C17.6185 13.1931 17.7649 15.8506 16.4184 18.0087C16.1716 18.4045 15.8536 18.7654 15.5242 19.0988C13.8435 20.7981 12.1465 22.4821 10.4585 24.1742C9.4656 25.1695 8.28495 25.7764 6.88176 25.9367C6.82593 25.9431 6.77252 25.9642 6.7183 25.9787C6.39299 25.9787 6.06769 25.9787 5.74238 25.9787C5.66389 25.9618 5.58539 25.9415 5.50609 25.9294C3.16907 25.5628 1.56196 24.2818 0.725224 22.0702C0.549624 21.6065 0.489742 21.0992 0.376451 20.612C0.374023 20.2851 0.374023 19.9598 0.374023 19.6345Z" fill="#545454" />
            <path d="M25.3538 7.3393C25.2648 7.75767 25.2008 8.18412 25.0803 8.59278C24.8092 9.51448 24.3131 10.3099 23.6358 10.9889C22.0408 12.5895 20.441 14.1869 18.8428 15.7851C18.8153 15.8126 18.7846 15.8369 18.7214 15.8927C18.7214 15.7973 18.715 15.7366 18.7222 15.6791C18.872 14.5705 18.7166 13.491 18.401 12.4301C18.3387 12.2213 18.3824 12.0959 18.5345 11.947C19.5363 10.9597 20.5333 9.96764 21.5213 8.96664C22.935 7.5327 22.647 5.28388 20.9322 4.30392C19.8632 3.69296 18.4746 3.8192 17.5756 4.66079C16.7688 5.41579 16.0017 6.21368 15.2191 6.99457C14.3387 7.87338 13.455 8.74815 12.5819 9.63505C11.4401 10.7947 11.3915 12.5685 12.4589 13.7313C12.4807 13.7556 12.4985 13.7855 12.5252 13.8017C12.8182 13.9806 12.942 14.2266 12.9428 14.5818C12.9436 15.0245 12.9315 15.4541 12.6094 15.79C12.2954 16.1177 11.9539 16.4187 11.61 16.7448C10.838 16.3249 10.1842 15.7212 9.71888 14.9419C8.48158 12.8695 8.42413 10.7623 9.64766 8.66399C9.88962 8.24805 10.2182 7.87096 10.5572 7.52623C12.1206 5.94016 13.6889 4.35733 15.2814 2.80039C17.0917 1.03063 19.2361 0.512731 21.6031 1.41096C23.7337 2.21937 24.9476 3.84995 25.302 6.10848C25.3158 6.19506 25.336 6.28003 25.353 6.36581C25.3538 6.68869 25.3538 7.01399 25.3538 7.3393Z" fill="#545454" />
        </svg>
    )
}

export default LinkIcon