import React from 'react'

function ClockIcon() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2645 22.5591C11.8014 22.5591 11.3382 22.5591 10.8751 22.5591C10.6042 22.5255 10.3321 22.4966 10.0618 22.4578C7.85795 22.1413 5.90882 21.2622 4.24103 19.786C2.30174 18.0702 1.09186 15.9348 0.628167 13.3839C0.55349 12.973 0.51181 12.5563 0.455078 12.1426C0.455078 11.6796 0.455078 11.2167 0.455078 10.7537C0.468393 10.6762 0.484602 10.5992 0.495022 10.5217C0.546543 10.1426 0.575488 9.75893 0.650165 9.38451C1.24469 6.40192 2.78975 4.02405 5.28767 2.2978C7.7358 0.605696 10.463 0.00790298 13.395 0.49285C16.2808 0.970274 18.63 2.40313 20.4031 4.72601C22.2585 7.15711 22.9728 9.91865 22.5792 12.9481C22.2891 15.1802 21.371 17.1437 19.87 18.8213C18.1593 20.7339 16.0354 21.9289 13.505 22.3861C13.0952 22.4607 12.6784 22.5024 12.2645 22.5591ZM11.5692 21.1702C16.9234 21.1708 21.2853 16.8155 21.2952 11.4597C21.305 6.10215 16.9338 1.72721 11.5704 1.72663C6.21679 1.72605 1.85252 6.08305 1.84442 11.4366C1.83689 16.7953 6.20753 21.1697 11.5692 21.1702Z" fill="#545454" />
      <path d="M10.6489 8.7522C10.6489 7.85753 10.6431 6.96229 10.6506 6.06762C10.6564 5.36682 11.2266 4.92065 11.7991 5.15444C12.1476 5.2968 12.3479 5.63765 12.3485 6.10813C12.3502 7.67409 12.3525 9.24004 12.345 10.806C12.3439 10.9981 12.4006 11.1098 12.5407 11.2226C13.4785 11.975 14.4105 12.7359 15.3414 13.4975C15.6742 13.7701 15.7981 14.1323 15.7009 14.5067C15.5364 15.1427 14.8759 15.3927 14.3694 14.9957C13.8333 14.5756 13.3089 14.1381 12.7798 13.7076C12.2165 13.2492 11.6585 12.7834 11.0882 12.3355C10.7774 12.0913 10.6431 11.777 10.6465 11.3673C10.6535 10.4958 10.6489 9.62429 10.6489 8.7522Z" fill="#545454" />
    </svg>

  )
}

export default ClockIcon