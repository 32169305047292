import React, { useState, useEffect, useCallback } from "react"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { Grid, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { useGetImageUrlMutation } from "../../features/base/baseRestApi"
import { setEditObituary } from '../../features/rfmEdit/rfmEditSlice'
import { debounce } from '../../constants'
import DragAndDrop from '../base/Upload/DragAndDrop'
import CustomTextField from '../base/Textfield/CustomTextField'
import { getCurrentWidthDimension } from "../../constants"
import styles from './Orbituary.module.css'

interface ObituaryProps {
  fulltext: boolean
}

const ObituaryComponent: React.FC<ObituaryProps> = (props) => {
  const { fulltext } = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const funeralState = useAppSelector(getFuneralState)
  const { obituary, obituary_image } = funeralState.details
  const isEdit = funeralState.isEdit
  const [obituaryImg, setObituaryImg] = useState("")
  const [obituaryDetails, setObituaryDetails] = useState({
    title: "Obituary",
    message: "",
    image: "",
  })
  const [ObituaryEdit, setObituaryEdit] = useState<any>({
    message: '',
    file: null
  })

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  const [getImageUrl] = useGetImageUrlMutation()

  useEffect(() => {
    setObituaryDetails({
      ...obituaryDetails,
      message: obituary,
      image: obituary_image
    })
    if (obituary_image) {
      getURL(obituary_image)
    }
  }, [obituary_image])

  useEffect(() => {
    if (isEdit) {
      setObituaryEdit({ message: obituary ? obituary : '', file: '' })
    }

  }, [isEdit])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setObituaryImg(rv.imageUrl)
    } catch (e) { }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setObituaryEdit({
      ...ObituaryEdit,
      message: value.replace(/\n\r?/g, '<br />')
    })
    debounceHandler()
  }

  const storeObituary = () => {
    if (isEdit) {
      dispatch(setEditObituary({ obituary: ObituaryEdit, toSave: 'obituary' }))
    }
  }

  const debounceHandler = useCallback(
    debounce(storeObituary())
    , []);

  // handle File events
  const handleFile = function (e: any) {
    setObituaryEdit({
      ...ObituaryEdit,
      file: e[0]
    })
  };

  const clearUpload = () => {
    setObituaryEdit({
      ...ObituaryEdit,
      file: ''
    })
  }


  return (
    <>
      <Typography sx={{ pt: 1, tb: 1 }} component={'p'} className='sectionTitle'>
        Obituary
      </Typography>
      {
        isEdit && location.pathname.includes('/obituary') ? (
          <Grid container spacing={2} sx={{ py: 2 }} justifyContent='space-between'>
            <Grid item xs={12} md={9}>
              <CustomTextField className='text-input' fullWidth sx={{ mb: 4 }}
                multiline
                minRows={4}
                value={ObituaryEdit.message?.replace(/<br \/>?/g, '\n')}
                onChange={handleChange} id='content'
                name='content' size='small'
              />

            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 1, pr: { xs: 0, sm: 2 } }}>
              <DragAndDrop label="obituary" filetype='.jpeg, .png' handleFile={handleFile} currFileName={obituary_image} file={ObituaryEdit.file} clearUpload={clearUpload} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              py: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {obituary ? (
              <>

                <Grid
                  item
                  sx={{ py: 1, pr: 2 }}
                  className={styles.showInMobile}
                >
                  {
                    obituary_image ?
                      <img src={obituaryImg} className="img" style={{ maxWidth: '200px' }} />
                      :
                      <></>
                  }
                </Grid>


                <Grid item xs={12} lg={9}>
                  <div dangerouslySetInnerHTML={{ __html: obituary }}></div>
                  <br />
                </Grid>

                <Grid
                  item
                  sx={{ py: 1, pr: 2 }}
                  className={styles.showInWeb}
                >
                  {
                    obituary_image ?
                      <img src={obituaryImg} className="img" style={{ maxWidth: '200px' }} />
                      :
                      <></>
                  }
                </Grid>
              </>
            ) : (
              <Grid item sx={{ py: 1, pr: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
                {obituary_image ? <img src={obituaryImg} className="img" style={{ maxWidth: '200px' }} /> : <></>}
              </Grid>
            )}
          </Grid>
        )
      }

    </>

  )
}

export default ObituaryComponent
