import { useAppSelector } from '../../app/hooks'
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Pagination, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import CustomSelect from '../../components/base/Select/CustomSelect'
import WebviewNotes from '../../components/webview/notes/WebviewNotes'
import { WebviewTitle } from '../../components/webview/title/WebviewTitle'
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from 'react';
import ProductList from '../../components/product/ProductList';
import OrderList from '../../components/order/OrderList';
import { getUserState } from '../../features/user/userSlice'
import { getCurrentWidthDimension } from "../../constants"
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { useNavigate } from 'react-router-dom'

function GiveList() {
  const userState = useAppSelector(getUserState)
  const { isLoggedIn } = userState
  const isRfm = true;
  const selectedFuneral: any = useAppSelector(getFuneralState)
  const navigate = useNavigate()

  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    if (!selectedFuneral.details.showGifts) {
        navigate(`/${selectedFuneral.details.lastName}/${selectedFuneral.details.id}/about`)
    }
}, [selectedFuneral, navigate])

  return (
    <>
      { screenWidth > 550 && <Typography  component={'div'} variant={'body2'} className="hr"></Typography>}
      <Grid container item>
        <Grid item xs={12} md={9}>
          <Box sx={{ pr: { xs: 0, sm: 3 }, pt: { xs: 1, sm: 3 } }}>
            <Typography sx={{ pt: 1, tb: 1, pb: 2 }} component={"p"} className="sectionTitle">
              <b>Send Gifts</b>
            </Typography>
            <ProductList onSave={function (selected: readonly any[]): void {
              throw new Error('Function not implemented.');
            }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <OrderList isProductPage={false} isLoggedIn={isLoggedIn} />
        </Grid>
      </Grid>
    </>
  )
}




export default GiveList