import React from 'react';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


interface ProductProps extends MuiCardProps {
  name: string
  description: string
};

const ProductItem: React.FC<ProductProps> = (props) => {
  return (
    <MuiCard variant="outlined" sx={{ maxWidth: 345 }}>
      <CardContent>
      <Typography gutterBottom variant="h5" component="div">{props.name}</Typography>
      <Typography variant="body2">
        {props.description}
      </Typography>
      </CardContent>
    </MuiCard>
  )
}

export default ProductItem