import { gqlApi } from '../../api/graphql';
import { gql } from 'graphql-request';

export const checkoutGqlApi = gqlApi.injectEndpoints({
    endpoints: (builder) => ({
        setCustomerForOrder: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        setCustomerForOrder(input: {
                            title: ""
                            firstName: "${data.firstName}"
                            lastName: "${data.lastName}"
                            phoneNumber: "${data.phoneNumber}"
                            emailAddress: "${data.email}"
                            }
                            activeOrderInput: {
                                orderToken: {
                                    token: "${data.funeralId}"
                                }
                            }
                        )
                        {
                            __typename
                            ... on AlreadyLoggedInError {
                                errorCode
                                message
                            }
                            ... on EmailAddressConflictError {
                                errorCode
                                message
                            }
                            ... on NoActiveOrderError {
                                errorCode
                                message
                            }
                        }
                    }
                `
            })
        }),
        setOrderShippingAddress: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        setOrderShippingAddress(input: {
                            streetLine1: "${data.addressLine1}"
                            streetLine2: "${data.addressLine2}"
                            postalCode: "${data.postcode}"
                            countryCode: "SG"
                            customFields: {
                                rfmId: "${data.rfmId}"
                                rfmName: "${data.rfmName}"
                                deceaseId: "${data.deceaseId}"
                                eventId: "${data.eventId}"
                                eventDate: "${data.eventDate}"
                                message: "${data.message}"
                            }
                          
                          }
                          activeOrderInput: {
                            orderToken: {
                                token: "${data.funeralId}"
                            }
                          }
                        ){
                            __typename
                            ...on Order {
                                id
                            }
                            ... on NoActiveOrderError {
                                errorCode
                                message
                            } 
                        }
                    }
                `
            })
        })
    })
})

export const { useSetCustomerForOrderMutation, useSetOrderShippingAddressMutation } = checkoutGqlApi