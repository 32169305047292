import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { Avatar, Box, Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import InputLabel from "@mui/material/InputLabel"
import IconButton from '@mui/material/IconButton';
import Badge, { BadgeProps } from "@mui/material/Badge"
import CancelIcon from "@mui/icons-material/Cancel"
import MenuItem from "@mui/material/MenuItem"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import CustomTextField from "../base/Textfield/CustomTextField"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SelectChangeEvent } from "@mui/material/Select"
import CustomSelect from '../base/Select/CustomSelect'
import Button from '../base/Button/Button'
import { relationships } from '../../constants'
import styles from "./SurvivedbyCard.module.css"
import { useGetImageUrlMutation } from "../../features/base/baseRestApi"
import { useDeleteRfmMutation } from '../../features/rfmEdit/rfmEditRestApi'
import { setSurvivedBy } from '../../features/rfmEdit/rfmEditSlice'
import Toast from '../base/Toast/Toast'
import { getUserState } from '../../features/user/userSlice'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "transparent"
  }
}))

interface SurvivedbyProps {
  data: any
  index: any
}

const SurvivedbyCard: React.FC<SurvivedbyProps> = (props) => {
  const { data, index } = props
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const funeralState = useAppSelector(getFuneralState)
  const userState = useAppSelector(getUserState)
  const { email } = userState.user
  const { isEdit } = funeralState
  const [survivedByInfo, setSurvivedByInfo] = useState<any>(data)
  const [imageURL, setImageURL] = useState("")
  const [preview, setPreview] = useState("")
  const [toast, setToast] = useState({
    message: "",
    type: "success",
    open: false,
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  const [imageHeight, setImageHeight] = useState(window.innerWidth)
  const [file, setFile] = useState<any>('')
  const [openConfirmDel, setOpenConfirmDel] = useState(false)

  const [getImageUrl] = useGetImageUrlMutation()
  const [deleteRfm] = useDeleteRfmMutation()

  useEffect(() => {
    window.addEventListener("resize", () => setImageHeight(window.innerWidth));
  },[])

  useEffect(() => {
    if (data.image) {
      getURL(data.image)
      //setImage(data.image)
    }
    setSurvivedByInfo(data)
  }, [data])

  useEffect(() => {
    if(!isEdit) {
      setPreview('')
      setFile('')
    }
  }, [isEdit])

  useEffect(() => {
    if(file) {
      const objectUrl = window.URL.createObjectURL(file)
      setPreview(objectUrl)
    }else {
      setPreview('')
    }
  }, [file])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setImageURL(rv.imageUrl)
    } catch (e) {}
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const key = event.target.id
    setSurvivedByInfo({
      ...survivedByInfo,
      [key]: value
    })
    dispatch(setSurvivedBy(
      {
        toSave: 'survivedBy',
        data: {
          ...survivedByInfo,
          [key]: value
        }
      }))
  }

  const validateFiletype = (type: string) => {
    let allowedtypes = ['jpeg', 'png']
    let uploadedType = type.split("/")

    if (allowedtypes.includes(uploadedType[1])) {
      return true
    } else {
      return false
    }
  }

  // triggers when file is selected with click
  const handleFileChange = function (e: any) {
    e.preventDefault()
    const check = validateFiletype(e.target.files[0].type)
    if (!check) {
      setToast({
        ...toast,
        message: `Only files of type .jpeg, .png is allowed!`,
        type: "error",
        open: true
      })
      return
    }
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      let file = e.target.files[0]
      setFile(e.target.files[0])
      dispatch(setSurvivedBy(
        {
          index: index, 
          toSave: 'survivedBy',
          data: {
            ...survivedByInfo,
            file: file
          }
        })
      )
    }
  }

  const removeFile = () => {
    setFile('')
    dispatch(setSurvivedBy(
      {
        index: index, 
        toSave: 'survivedBy',
        data: {
          ...survivedByInfo,
          file: ''
        }
      })
    )
  }

  const handleRelationshipChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setSurvivedByInfo({
      ...survivedByInfo,
      relationship: value
    })
    dispatch(setSurvivedBy(
      {
        index: index, 
        toSave: 'survivedBy',
        data: {
          ...survivedByInfo,
          relationship: value
        }
      }))
  }

  const handleDelete = async () => {
    try {
      let body = {
        funeralId: id,
        rfmId: survivedByInfo.id
      }
      let rv = await deleteRfm(body).unwrap()
      if(rv) {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: rv.message
        })
        setOpenConfirmDel(false)
      }
    }catch(e: any) {
      setToast({
        ...toast,
        open: true,
        type: 'error',
        message: e.data.message
      })
    }
  }

  const formatName = (first: string, last: string) => {
    return first.charAt(0).toUpperCase() + first.slice(1) + ' ' + last.charAt(0).toUpperCase() + last.slice(1)
  }

  return (
    <Grid container spacing={2}>
      <Toast {...toast} />
      <Grid container spacing={2} sx={{ display: "flex" }}>
        <Grid item>
          {isEdit ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <InputLabel className={styles.label_file_upload} htmlFor={`image_${index}`}>
                  <Avatar sx={{ bgcolor: "#cbdcfd", ml: 1, cursor: 'pointer' }}>
                    <CustomTextField id={`image_${index}`} type="file" name={`image_${index}`} className={styles.input_file_upload} onChange={handleFileChange} />
                    <CameraAltIcon sx={{ color: "#6397ff"}} />
                  </Avatar>
                </InputLabel>
              }
            > {
                file ? (
                  <Badge 
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    badgeContent={
                      <IconButton onClick={removeFile}>
                        <CancelIcon sx={{ color: "#6397ff" }}/>
                      </IconButton>
                      
                    }
                  >
                    <Avatar sx={{ width: 105, height: 105 }} alt={data.name} src={preview} />
                  </Badge>
                ) : <Avatar sx={{ width: 105, height: 105 }} alt={data.name} src={imageURL} />
              }
              
            </StyledBadge>
          ) : (
            <Avatar sx={{ width: 105, height: 105 }} alt={'profile'} src={imageURL} />
          )}
        </Grid>
        <Grid item>
          {isEdit ? (
            <Box>
              <Grid container spacing={2} sx={{ pt: 1, pl: 2 }}>
                <Grid item xs={10}>
                  {
                    email !== data.email && imageHeight < 768 && <IconButton onClick={() => setOpenConfirmDel(true)}>
                      <CancelIcon />
                    </IconButton>
                  }
                  <CustomTextField id="firstName" value={survivedByInfo.firstName} onChange={handleChange} size="small" sx={{mb: {xs:2, sm:0},width: {xs: '100%',sm: 150}}}/>
                  <CustomTextField id="lastName" value={survivedByInfo.lastName} onChange={handleChange} size="small" sx={{width: {xs: '100%',sm: 150}}}/>
                  {
                    email !== data.email && imageHeight > 768 && <IconButton onClick={() => setOpenConfirmDel(true)}>
                      <CancelIcon />
                    </IconButton>
                  }
                </Grid>
                <Grid item xs={10} sx={{ pt: 1 }}>
                  <CustomSelect
                    className={styles.input}
                    sx={{width:  {xs: '100%',sm: 300}}}
                    /* MenuProps={{
                      disableScrollLock: true
                    }} */
                    size="small"
                    value={survivedByInfo.relationship}
                    onChange={handleRelationshipChange}
                  >
                    {relationships.map((item) => (
                      <MenuItem 
                        key={item} 
                        value={item}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'var(--TLC-web-grey)',
                            '&:hover': {
                              backgroundColor: 'var(--TLC-web-grey)',
                            },
                          },
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Typography className={styles.name} gutterBottom sx={{ pt: 3, pb: 0, pl: 0, pr: 0 }}>
                {formatName(survivedByInfo.firstName, survivedByInfo.lastName)}
              </Typography>
              <Typography className={styles.relationship} gutterBottom>
                <i>{survivedByInfo.relationship}</i>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* Confirmation dialog for deleteing rfm */}
      <Dialog
        open={openConfirmDel}
        onClose={() => setOpenConfirmDel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className='sectionText'>Are you sure you want to delete {formatName(survivedByInfo.firstName, survivedByInfo.lastName)}? 
            <br/>
            This action cannot be undone, and you will have to add the RFM again if you delete unintentionally.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='btnSecondary' onClick={() => setOpenConfirmDel(false)}>Cancel</Button>
          <Button className='btnSecondary' onClick={handleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default SurvivedbyCard
