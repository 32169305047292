import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import Modal from "@mui/material/Modal"

import CustomTextField from "../../base/Textfield/CustomTextField"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import SquareIcon from "../../base/Icons/SquareIcon"
import SquareCheckedIcon from "../../base/Icons/SquareCheckedIcon"
import Button from "../../base/Button/Button"
import { getUserState, toggleLoginModal } from "../../../features/user/userSlice"
import { usePostCondolenceMessageMutation } from "../../../features/webview/condolenceList/condolenceListRestApi"
import DragAndDrop from "../../base/Upload/DragAndDrop"
import Toast from "../../base/Toast/Toast"
import LoginModal from "../../login/LoginModal"
import styles from "./CondolenceMessage.module.css"
import { getCurrentWidthDimension } from "../../../constants"

const CondolenceMessage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const userState = useAppSelector(getUserState)
  const user = useAppSelector(getUserState)
  const { isLoggedIn } = userState
  const [toast, setToast] = useState({
    message: "",
    open: false,
    type: "success",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const [errors, setErrors] = useState({
    message: false
  })

  const [screenWidth, setScreenWidth] = useState(getCurrentWidthDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenWidth])

  const [file, setFile] = useState<any>("")
  const [message, setMessage] = useState("")
  const [toggle, setToggle] = useState(false)
  const [isPrivate, setIsPrivate] = useState(true)
  const [submittingMessage, setSubmittingMessage] = useState(false)
  const [clear, setClear] = useState(false)

  const handleOpenLoginModal = () => dispatch(toggleLoginModal(""))
  const handleCloseLoginModal = () => dispatch(toggleLoginModal(""))

  const [postMessage] = usePostCondolenceMessageMutation()

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    let str = value.slice(0, 1000)
    if (value.length > 1000) {
      setErrors({
        message: true
      })
    } else {
      setErrors({
        message: false
      })
    }
    let htmlstr = str.replace(/\n\r?/g, "<br />")
    setMessage(htmlstr)
  }

  // handle File events
  const handleFile = function (e: any) {
    setClear(false)
    setFile(e[0])
  }

  const handleMessageSubmit = () => {
    if (!message) {
      setToast({
        ...toast,
        open: true,
        message: "Message cannot be empty!",
        type: "error"
      })
      return
    }

    if (isLoggedIn) {
      submitMessage();
    } else {
      handleOpenLoginModal();
      setSubmittingMessage(true);
    }
  }

  const submitMessage = async () => {
    try {
      let body = {
        message: message,
        isInitials: toggle,
        isPrivate: isPrivate,
        funeralId: id
      }
      const formData = new FormData()
      formData.append("file", file)
      formData.append("body", JSON.stringify(body))

      let rv = await postMessage(formData).unwrap()

      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: "success"
        })

        reset()
      }
    } catch (e: any) {
      setToast({
        ...toast,
        open: true,
        message: e.data.message,
        type: "error"
      })
    }
  }

  const clearUpload = () => {
    setFile("")
  }

  const reset = () => {
    setMessage("")
    setFile("")
    setClear(true)
  }

  useEffect(() => {
    if (isLoggedIn && submittingMessage && user.toggleLoginModal === false) {
      submitMessage();
    }
  }, [submittingMessage, user.toggleLoginModal, isLoggedIn])

  return (
    <Box sx={{ mx: { xs: 0, md: 0 } }}>
      <Toast {...toast} />
      <Typography component={"p"} className="sectionTitle">
        Send A Heartfelt Message
      </Typography>
      <Typography sx={{ pt: 1 }} component={"div"}>
        Message
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} style={{ flexDirection: "row" }}>
          <CustomTextField
            error={errors.message}
            // helperText={`${message.replace(/<br \/>?/g, "\n").length}/1000`}
            sx={{ width: "100%", pt: 2, "& p": { textAlign: "end", padding: 0, margin: 0 } }}
            multiline
            rows={4}
            value={message.replace(/<br \/>?/g, "\n")}
            onChange={handleMessageChange}
            id="message"
            name="message"
            placeholder="Your Message"
            size="small"
          />

          {
            screenWidth > 550 &&
            <Box
              sx={{ pt: 3, pb: 0, display: { xs: 'block', md: 'none' } }}
            >
              {isLoggedIn ?
                <DragAndDrop label="message-image" filetype="jpeg, png" handleFile={handleFile} file={file} clearUpload={clearUpload} />
                :
                <></>
              }
            </Box>
          }

          <Box sx={{ mt: { xs: 0, md: 2 } }}>
            <Box className={styles.checkboxContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<SquareIcon />}
                    checkedIcon={<SquareCheckedIcon />}
                    checked={toggle}
                    onChange={() => setToggle(!toggle)}
                  />
                }
                label={
                  <Box component="div" fontSize={16}>
                    Submit with initials only.
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<SquareIcon />}
                    checkedIcon={<SquareCheckedIcon />}
                    checked={isPrivate}
                    onChange={() => setIsPrivate(!isPrivate)}
                  />
                }
                label={
                  <Box component="div" fontSize={16}>
                    Mark as Private
                  </Box>
                }
              />
            </Box>

            {
              screenWidth <= 550 &&
              <Box
                sx={{
                  pt: 0, 
                  pb: 0, 
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {isLoggedIn ?
                  <DragAndDrop label="message-image" filetype="jpeg, png" handleFile={handleFile} file={file} clearUpload={clearUpload} />
                  :
                  <></>
                }
              </Box>
            }

            <Box sx={{ mt: 1.5 }}>
              <Button className='btnPrimary' sx={{ width: { md: "400px" } }} fullWidth onClick={handleMessageSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ py: 2 }}>{isLoggedIn ? <DragAndDrop label="message-image" filetype="jpeg, png" handleFile={handleFile} file={file} clearUpload={clearUpload} /> : <></>}</Box>
        </Grid>
      </Grid>

      <Modal open={user.toggleLoginModal} onClose={handleCloseLoginModal}>
        <LoginModal handleClose={handleCloseLoginModal} />
      </Modal>
    </Box>
  )
}

export default CondolenceMessage
