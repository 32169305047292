import React from "react"

function RefreshIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9042 2.93013C13.879 3.15182 13.8504 3.37317 13.8297 3.5952C13.7684 4.24683 13.7128 4.89915 13.6491 5.55044C13.6073 5.97417 13.1753 6.17552 12.8268 5.93004C12.0841 5.40666 11.3456 4.87743 10.6081 4.34682C10.3644 4.17133 10.3123 3.86172 10.4754 3.62692C10.6409 3.38834 10.9543 3.3349 11.2063 3.50487C11.3204 3.5821 11.4311 3.6645 11.5432 3.74449C11.6552 3.82414 11.7669 3.90378 11.8948 3.97274C11.8745 3.93826 11.8569 3.90206 11.8338 3.8693C10.8643 2.49502 9.54862 1.68479 7.8768 1.48999C5.6585 1.23176 3.48537 2.394 2.42794 4.38095C2.34692 4.533 2.26003 4.67229 2.08833 4.734C1.89112 4.80468 1.67495 4.75193 1.53221 4.59747C1.38395 4.43681 1.3512 4.21373 1.45463 4.00963C1.69494 3.53487 1.98835 3.09459 2.33933 2.69465C3.42124 1.46069 4.77484 0.721831 6.39874 0.481177C6.55561 0.458077 6.71318 0.441527 6.87039 0.421875C7.14622 0.421875 7.42204 0.421875 7.69786 0.421875C7.91472 0.450147 8.13331 0.469454 8.34845 0.507725C10.203 0.837332 11.6542 1.79099 12.7189 3.34007C12.7395 3.37041 12.7626 3.39903 12.8037 3.4535C12.8275 3.19457 12.8492 2.9653 12.8695 2.73602C12.8995 2.40159 13.1991 2.18196 13.518 2.26333C13.7204 2.31505 13.8283 2.46468 13.9039 2.64569C13.9042 2.7405 13.9042 2.83532 13.9042 2.93013Z"
        fill="#49B360"
      />
      <path
        d="M1.76509 10.6221C1.74061 10.8841 1.7182 11.1058 1.69993 11.3279C1.67407 11.6402 1.44307 11.8512 1.1438 11.8316C0.849362 11.8123 0.641462 11.5671 0.668354 11.2524C0.727311 10.5614 0.793164 9.87119 0.856603 9.18094C0.876255 8.96649 0.894873 8.75204 0.918663 8.53794C0.966931 8.09903 1.3948 7.90492 1.7582 8.16213C2.44016 8.64447 3.11834 9.13233 3.79824 9.61812C3.85444 9.65812 3.91167 9.69673 3.96615 9.73845C4.2037 9.92015 4.25542 10.2267 4.08958 10.4604C3.92443 10.6928 3.61344 10.7473 3.36796 10.5801C3.15799 10.437 2.95354 10.286 2.74667 10.1384C2.72771 10.1249 2.70702 10.1132 2.68737 10.1008C2.6791 10.1087 2.67048 10.1163 2.6622 10.1243C2.81942 10.3263 2.96664 10.537 3.13592 10.7287C4.08579 11.8037 5.27423 12.4374 6.70058 12.5932C8.93094 12.837 11.0782 11.6892 12.1391 9.70466C12.2201 9.5533 12.3049 9.41229 12.4766 9.34954C12.6742 9.27783 12.89 9.32851 13.0334 9.48194C13.1827 9.64157 13.2303 9.87395 13.1131 10.0677C12.8797 10.4535 12.6569 10.8524 12.3756 11.202C11.2889 12.5518 9.88115 13.3883 8.15898 13.5931C5.58902 13.8985 3.52208 12.9694 1.96368 10.9059C1.90162 10.8245 1.84611 10.7383 1.76509 10.6221Z"
        fill="#49B360"
      />
    </svg>
  )
}

export default RefreshIcon
