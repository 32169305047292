import { StyledEngineProvider, createTheme, ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from "react-router-dom";
import { router } from './router'
import './App.css';
import Authenticate from './router/Autenticate';

const theme = createTheme({
  typography: {
    fontFamily: ['Kumbh Sans', 'sans-serif'].join(','),
    fontSize: 16,
    button: {
      fontFamily: ['Kumbh Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none'
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: '#6397ff',
          color: 'white'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '18px',
        }
      }
    },
    
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
          marginTop: '0px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'EB Garamond',
          fontSize: 18
        }
      }
    }
   }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Authenticate>
          <RouterProvider router={router}/>
          {/* <Footer /> */}
        </Authenticate>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
