import { useEffect, useState } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import styles from './Profile.module.css';
import { useGetImageUrlMutation } from '../../features/base/baseRestApi';

interface ProfileProps {
    // onClick?: () => void;
    Name: string
    LifeTime: string
    AltName: string
    ImgUrl: string
    Height: number
    Width: number
    IsCenter?: boolean
};

const Profile: React.FC<ProfileProps> = ({
    Name,
    LifeTime,
    AltName,
    ImgUrl,
    Height,
    Width,
    IsCenter = false,
}) => {
    const [imageURL, setImageURL] = useState('')
    const [getImageUrl] = useGetImageUrlMutation()

    useEffect(() => {
        if (ImgUrl) {
            getURL(ImgUrl)
        }
    }, [ImgUrl])

    const getURL = async (filename: string) => {
        try {
            let rv = await getImageUrl({ image: filename }).unwrap()
            setImageURL(rv.imageUrl)
        } catch (e) { }
    }

    if (IsCenter) {
        return (
            <Grid container spacing={0} className={styles.profileGrid}>
                <Grid item xs={12}>
                    <Avatar className={styles.avatarCenter} alt={AltName} src={imageURL} sx={{ margin: "auto" }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={styles.wvProfile} sx={{ fontSize: { xs: '32px', md: '64px' }, display: 'flex', justifyContent: 'center' }} gutterBottom component="div">
                        {Name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        className={styles.wvText}
                        gutterBottom
                        sx={{
                            pt: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 3
                        }}
                    >
                        {LifeTime}
                    </Typography>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={2} className={styles.profileGrid}>
                <Grid item xs={3} sx={{ p: 2 }}>
                    <Avatar className={styles.avatar} alt={AltName} src={imageURL} />
                </Grid>
                <Grid item xs={8} sm container>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography className={styles.wvProfile} sx={{ fontSize: { xs: '32px', md: '64px' } }} gutterBottom component="div">
                                {Name}
                            </Typography>
                            <Typography className={styles.wvText} gutterBottom sx={{ pt: 1 }}>
                                {LifeTime}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Profile




