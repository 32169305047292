import { Box, Grid, Typography } from '@mui/material'
import Stepper from '../../components/base/Stepper/Stepper';
import React, { useEffect, useState } from 'react'
import OrderDetail from '../../components/checkout/OrderDetail';
import OrderSummary from '../../components/checkout/OrderSummary'
import Payment from '../../components/checkout/Payment';

const steps = ['Order Details', 'Payment'];

interface StepContainerProps {
    step: number
    handleStepper: (step: number) => void
}

function StepContainer (props:StepContainerProps) {
    switch(props.step) {
        case 0:
            return (<OrderDetail handleStepper={props.handleStepper} />)
        case 1:
            return (<Payment handleStepper={props.handleStepper} />)
        default:
            return (<div>Default</div>)
    }
}

function Checkout() {

    const [activeStep, setActiveStep] = useState(0);

    const handleStepper = (step: number) => {
        setActiveStep(step);
    }

    const handleClick = (step: number) => {
        if (activeStep != 0) {
            setActiveStep(step)
        }
    }

    return (
        <div>
            <Box sx={{width: '300px', display: {xs: 'none', sm:'block'}}}>
                <Stepper steps={steps} active={activeStep} handleClick={handleClick} sx={{mt:2}} />
            </Box>
            <Grid container item sx={{pr: 0}}>
                <Grid item xs={12} md={4} sx={{display: {xs: 'block', sm:'none'}}}>
                    <OrderSummary />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{width: '300px', display: {xs: 'block', sm:'none'}}}>
                        <Stepper steps={steps} active={activeStep} handleClick={handleClick} sx={{mt:2}} />
                    </Box>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Typography className='sectionTitle' sx={{mt:2}}><b>Checkout</b></Typography>
                        <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                        <br />
                    </Box>
                    <StepContainer step={activeStep} handleStepper={handleStepper} />
                </Grid>
                <Grid item xs={12} md={4} sx={{display: {xs: 'none', sm:'block'}}}>
                    <OrderSummary />
                </Grid>
            </Grid>
        </div>
    );
}

export default Checkout;
