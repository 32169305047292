import { restApi } from '../../api/restApi'
import {setInvites, setIsRfm} from './userSlice'

export const userRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInvites: builder.query({
      query: () => ({
        url: '/api/app-template/funeral/invites',
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInvites(data))
        } catch (error) {}
      },
    }),
    checkIsRfm: builder.query({
      providesTags: ['isRfm'],
      query: ({id, isLoggedIn}) => ({
        url: `/api/app-template/isRfm/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIsRfm(data))
        } catch (error) {}
      },
    }),
    getUser: builder.query({
      query: (payload) => ({
        url: `/api/app-template/user`,
        method: 'GET',
        params: payload
      })
    }),
  })
})

export const { useGetUserInvitesQuery, useCheckIsRfmQuery, useGetUserQuery } = userRestApi