import React from 'react'

function EditIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7643 7.42967C18.3655 7.42967 18.0423 7.75 18.0423 8.14534V16.3889C18.0423 17.3553 17.2497 18.141 16.2747 18.141H3.82386C2.84894 18.141 2.05626 17.3553 2.05626 16.3889V4.04678C2.05626 3.08038 2.84894 2.29462 3.82386 2.29462H12.0683C12.4672 2.29462 12.7903 1.9743 12.7903 1.57895C12.7903 1.18361 12.4672 0.863281 12.0683 0.863281H3.82386C2.05277 0.863281 0.612305 2.29166 0.612305 4.04678V16.3889C0.612305 18.1445 2.05277 19.5724 3.82386 19.5724H16.2747C18.0458 19.5724 19.4863 18.1445 19.4863 16.3889V8.14534C19.4863 7.75 19.1632 7.42967 18.7643 7.42967Z" fill="#545454" />
      <path d="M5.15089 11.3204L3.81797 15.0844L3.80353 15.1253L3.79407 15.1678C3.71241 15.536 3.82942 15.9091 4.10725 16.1667C4.30891 16.3538 4.56633 16.4525 4.83172 16.4525C4.9318 16.4525 5.03338 16.4382 5.13346 16.4096L5.1708 16.3987L8.85936 14.8899C9.185 14.7566 9.47628 14.5636 9.72574 14.3169L19.1179 5.00674C20.0495 4.08328 20.0495 2.58087 19.1179 1.65741L18.5125 1.05723C18.0613 0.60957 17.4614 0.363281 16.823 0.363281C16.1847 0.363281 15.5847 0.60957 15.1336 1.05674L5.77975 10.3294C5.49943 10.6067 5.28184 10.9498 5.15089 11.3204ZM16.1548 2.06904C16.3336 1.89235 16.5706 1.79462 16.823 1.79462C17.0755 1.79462 17.313 1.89235 17.4912 2.06904L18.0967 2.66922C18.2749 2.84641 18.3735 3.08135 18.3735 3.33158C18.3735 3.58182 18.2749 3.81725 18.0967 3.99395L16.9032 5.17702L14.9608 3.25163L16.1548 2.06904ZM6.80098 11.3412L13.9401 4.26442L15.8825 6.18982L8.70451 13.3051C8.59049 13.4181 8.45754 13.5059 8.30817 13.5671L5.47503 14.726L6.51318 11.7943C6.57293 11.625 6.67252 11.4685 6.80098 11.3412Z" fill="#545454" />
    </svg>
  )
}

export default EditIcon