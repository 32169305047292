import React from 'react'
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import styles from './CustomSelect.module.css'

const TlcSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--TLC-web-gray)',
    borderRadius: '3px',
    [theme.breakpoints.down('sm')]: {
      // Styles for mobile view
      border: '2px solid #545454',
    },
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #545454',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #545454',
  },
}));

interface CustomSelectProps extends SelectProps {
  onChange: (event: SelectChangeEvent<any>) => void;
  className: string
};

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  return (
    <TlcSelect 
      {...props} 
      className={`${styles[props.className]}`}
    />
  )
}

export default CustomSelect