import { restApi } from "../../../api/restApi";
import { condolence_message } from "../../../types";
import { setCondolenceMessagelist } from "./condolenceListSlice";


type condolenceMessagelistResponse = condolence_message[]

export const condolenceMessagelistRestAPi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getCondolenceMessagelist: builder.query({
      providesTags: ['condolenceMsg'],
      query: (payload) => ({
        url: `/api/app-template/funeral/messagelist`,
        method: 'GET',
        params: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCondolenceMessagelist(data))
        } catch (error) {}
      },
    }),

    postCondolenceMessage: builder.mutation({
      invalidatesTags: ['condolenceMsg'],
      query: (payload) => ({
        url: '/api/app-template/funeral/message/submit',
        method: 'POST',
        body: payload,
      }),
    }),
    

  }),
})

export const { useGetCondolenceMessagelistQuery , usePostCondolenceMessageMutation } = condolenceMessagelistRestAPi

