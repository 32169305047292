import { useState, useEffect } from "react"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import AppBar from "@mui/material/AppBar"
import MuiLink from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Modal from "@mui/material/Modal"
import Logo from "./Logo"
import Button from "../../components/base/Button/Button"
import LoginModal from "../../components/login/LoginModal"
import AccountIcon from "../../components/base/Icons/AccountIcon"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { ExpandLess } from "@mui/icons-material"
import { ExpandMore } from "@mui/icons-material"
import Collapse from '@mui/material/Collapse'
import { setCurrentInvite, getUserState } from "../../features/user/userSlice"
import { getFuneralState, setIsEdit } from "../../features/funeral/funeralSlice"
import { useCheckIsRfmQuery } from "../../features/user/userRestApi"
import styles from "./Navbar.module.css"
import { WebviewTitle } from "../../components/webview/title/WebviewTitle"
import { Tab, Tabs } from "@mui/material"
import MenuItem from '@mui/material/MenuItem'
import { toggleLoginModal } from "../../features/user/userSlice"
import WebviewShare from "../../components/webview/share/WebviewShare"
import Profile from "../../components/profile/Profile"
import { formatDate, getCurrentWidthDimension } from "../../constants"
import Menu from '@mui/material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useLogoutMutation } from "../../features/auth/authGqlApi"
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

const externalItems = [
  { label: 'About TLC', path: 'https://www.thelifecelebrant.sg/about-tlc-the-life-celebrant.html' },
  { label: 'TLC Services', path: 'https://www.thelifecelebrant.sg/the-life-celebrant-services.html' }
]

const profileItems = [
  { label: 'Account', path: './profile/0' },
  { label: 'Purchases', path: './profile/1' },
  // { label: 'Other Invitation Accounts', path: './profile/2' },
  { label: 'Logout', path: './profile/3' },
]

const mobileProfileItems = [
  { label: 'Profile', path: './profile' },
  { label: 'Approval', path: './rfm/approval' },
  { label: 'Logout', path: './profile/3' },
]

function Navbar() {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUserState)
  const selectedFuneral = useAppSelector(getFuneralState)
  useCheckIsRfmQuery({ id, isLoggedIn: user.isLoggedIn }, { refetchOnMountOrArgChange: true })
  const handleOpen = () => dispatch(toggleLoginModal(""))
  const handleClose = () => dispatch(toggleLoginModal(""))
  const [logoutUser] = useLogoutMutation()
  const [screenWidth, setScreenWidth] = useState(0)
  const [isGiftsEnabled, setIsGiftsEnabled] = useState<number>(0)
  const [isGalleryEnabled, setIsGalleryEnabled] = useState<number>(0)

  const [navItems, setNavItems] = useState([
    { label: 'About', path: './' },
    { label: 'Obituary', path: './obituary' },
    { label: 'Approval', path: './rfm/approval' },
    { label: 'Services', path: './events' },
    { label: 'Gifts', path: './give' },
    { label: 'Gallery', path: './gallery' },
    { label: 'Heartfelt Messages', path: './messages' },
    // {label: 'Profile', path: './profile' },
  ])

  useEffect(() => {
    if (isGiftsEnabled && isGalleryEnabled) {
      setNavItems([
        { label: 'About', path: './' },
        { label: 'Obituary', path: './obituary' },
        { label: 'Approval', path: './rfm/approval' },
        { label: 'Services', path: './events' },
        { label: 'Gifts', path: './give' },
        { label: 'Gallery', path: './gallery' },
        { label: 'Heartfelt Messages', path: './messages' },
      ])
    }

    if (isGiftsEnabled && !isGalleryEnabled) {
      setNavItems([
        { label: 'About', path: './' },
        { label: 'Obituary', path: './obituary' },
        { label: 'Approval', path: './rfm/approval' },
        { label: 'Services', path: './events' },
        { label: 'Gifts', path: './give' },
        { label: 'Heartfelt Messages', path: './messages' },
      ])
    }

    if (!isGiftsEnabled && isGalleryEnabled) {
      setNavItems([
        { label: 'About', path: './' },
        { label: 'Obituary', path: './obituary' },
        { label: 'Approval', path: './rfm/approval' },
        { label: 'Services', path: './events' },
        { label: 'Gallery', path: './gallery' },
        { label: 'Heartfelt Messages', path: './messages' },
      ])
    }

    if (!isGiftsEnabled && !isGalleryEnabled) {
      setNavItems([
        { label: 'About', path: './' },
        { label: 'Obituary', path: './obituary' },
        { label: 'Approval', path: './rfm/approval' },
        { label: 'Services', path: './events' },
        { label: 'Heartfelt Messages', path: './messages' },
      ])
    }
  }, [isGiftsEnabled, isGalleryEnabled])

  const [name, setName] = useState("")
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const path = location.pathname;
  const isAboutPage = path.includes("/about");
  //Drawer for mobile
  const [open, setOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openProfileMenu = Boolean(anchorEl);

  const avatarProps = {
    Name: selectedFuneral.details.lastName,
    LifeTime: formatDate(selectedFuneral.details.dob, "DD MMM YYYY") + " - " + formatDate(selectedFuneral.details.dod, "DD MMM YYYY"),
    AltName: selectedFuneral.details.lastName,
    ImgUrl: selectedFuneral.details.profile_image,
    Height: 138,
    Width: 138,
    IsCenter: true,
  }

  const handleClickProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (url: string) => {
    navigate(url)
    handleCloseProfileMenu()
  }

  const handleLogout = () => {
    logoutUser(null)
    window.location.href = "./logout"
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    //map user invites to selectedFuneral
    let found = user.invites.find((item) => {
      return item.id === selectedFuneral.details.id
    })

    if (found) {
      let data = {
        isInvited: true,
        relationship: found.relationship
      }
      dispatch(setCurrentInvite(data))
    }

    setIsGiftsEnabled(selectedFuneral.details.showGifts)
    setIsGalleryEnabled(selectedFuneral.details.showGallery)

    setName(user.user.lastName)
  }, [user.isLoggedIn, selectedFuneral])

  useEffect(() => {
    if (selectedFuneral.isEdit) {
      dispatch(setIsEdit(false))
    }
    updateTab()
  }, [location.pathname, user.funeral.isRFM])

  const updateTab = () => {
    if (path.includes("/about")) {
      setValue(0)
    }
    if (path.includes("/obituary")) {
      setValue(1)
    }
    if (path.includes("/events")) {
      setValue(2)
    }
    if (path.includes("/gallery")) {
      setValue(3)
    }
    if (path.includes("/messages")) {
      setValue(4)
    }
    if (path.includes("/give") || path.includes("/checkout")) {
      setValue(5)
    }
  }

  return (
    <>
      {isAboutPage ? (
        <>
          <AppBar position="static" sx={{ pt: 2, height: { xs: '145px' } }} className={styles.nav} style={{ backgroundImage: 'url(/images/banner-imgs.png)' }}>
            <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
              <Toolbar>

                {screenWidth > 550 ? (<WebviewShare />) : (<Logo />)}

                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }} align="right">
                  <MuiLink className={styles.link} href="https://www.thelifecelebrant.sg/about-tlc-the-life-celebrant.html" target="_blank" rel="noreferrer">
                    About TLC
                  </MuiLink>
                  <MuiLink className={styles.link} href="https://www.thelifecelebrant.sg/the-life-celebrant-services.html" target="_blank" rel="noreferrer">
                    TLC Services
                  </MuiLink>
                </Typography>

                {user.isLoggedIn ? (
                  <>
                    <Typography variant="h6" component={'div'} sx={{ display: { xs: "none", sm: "block" } }} ><span className={styles.name}>{name}</span></Typography>
                    <Box
                      sx={{ textAlign: 'right', flexGrow: { xs: 0, sm: 0 }, ml: 'auto' }}
                      onClick={(e: any) => handleClickProfileMenu(e)}
                    >
                      <Box sx={{ pl: 1, pt: { md: 1 }, cursor: 'pointer' }} style={{ color: 'var(--TLC-bodytxt)' }}><AccountIcon /></Box>
                    </Box>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openProfileMenu}
                      onClose={handleCloseProfileMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        className={styles.menuItem}
                        onClick={() => handleNavigate("./profile")}
                      >
                        Profile
                      </MenuItem>
                      {
                        user.funeral.isRFM &&
                        <MenuItem onClick={() => handleNavigate("./rfm/approval")} className={styles.menuItem}
                        >
                          Approval
                        </MenuItem>
                      }
                      <MenuItem
                        onClick={() => handleLogout()}
                        className={styles.menuItem}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                    <IconButton
                      onClick={() => setOpen(true)}
                      size="large"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{
                        color: 'black',
                        paddingRight: '0px',
                        display: { xs: 'block', sm: 'none' }
                      }}
                    >
                      <MenuIcon sx={{ width: '45px', height: '40px' }} />
                    </IconButton>
                  </>

                ) : (
                  <Typography component={'div'} sx={{ display: 'flex', flexGrow: { xs: 1, sm: 0 }, justifyContent: { xs: 'flex-end', sm: 'none' } }}>
                    <Button className="btnLogin" onClick={handleOpen} sx={{ marginRight: "2%", width: { xs: "75px !important", sm: '108px !important' } }}>
                      Login
                    </Button>
                  </Typography>
                )}
              </Toolbar>
            </Container>
          </AppBar>
          <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
            <Profile {...avatarProps} />
            <p></p>
          </Container>
          <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
            <Box className={styles.btnStyleTabs} sx={{ display: { sm: "block" } }}>
              <Tabs value={value} onChange={handleChange} className={styles.navTabs} aria-label="nav tabs"
                sx={{
                  "& .MuiTabs-flexContainer": { justifyContent: "center", gap: "20px", display: 'flex', flexWrap: 'wrap' },
                  "& .MuiTabs-indicator": { backgroundColor: "transparent" },
                  "& .Mui-selected": {
                    backgroundColor: "var(--TLC-gray)",
                    color: "white !important",
                  },
                }}
              >
                <Tab
                  label="About"
                  disableRipple
                  component={Link}
                  to={"./"}
                  icon={
                    // <img src={`/images/icons/home_gray.svg`} alt="img" loading="lazy" />
                    <HomeOutlinedIcon />
                  }
                  iconPosition="start" className={styles.tab}
                />

                <Tab
                  label="Obituary"
                  disableRipple
                  component={Link}
                  to={"./obituary"}
                  icon={
                    // <img src={`/images/icons/orbituary.svg`} alt="img" loading="lazy" />
                    <AutoStoriesOutlinedIcon />
                  }
                  iconPosition="start"
                  className={styles.tab}
                />

                <Tab
                  label="Services"
                  disableRipple
                  component={Link}
                  to={"./events"}
                  icon={
                    // <img src={`/images/icons/services_gray.svg`} alt="img" loading="lazy" />
                    <EventAvailableOutlinedIcon />
                  }
                  iconPosition="start"
                  className={styles.tab}
                />

                {
                  isGalleryEnabled &&
                  <Tab
                    label="Gallery"
                    disableRipple
                    component={Link}
                    to={"./gallery"}
                    icon={
                      // <img src={`/images/icons/gifts_gray.svg`} alt="img" loading="lazy" />
                      <PhotoCameraBackIcon />
                    }
                    iconPosition="start"
                    className={styles.tab}
                  />
                }

                <Tab
                  label={screenWidth <= 550 && screenWidth !== 0 ? 'Messages' : 'Heartfelt Messages'}
                  disableRipple
                  component={Link}
                  to={"./messages"}
                  icon={
                    // <img src={`/images/icons/messages_gray.svg`} alt="img" loading="lazy" />
                    <SmsOutlinedIcon />
                  }
                  iconPosition="start"
                  className={styles.tab}
                />

                {
                  isGiftsEnabled &&
                  <Tab
                    label="Gifts"
                    disableRipple
                    component={Link}
                    to={"./give"}
                    icon={
                      // <img src={`/images/icons/gifts_gray.svg`} alt="img" loading="lazy" />
                      <CardGiftcardOutlinedIcon />
                    }
                    iconPosition="start"
                    className={styles.tab}
                  />
                }

              </Tabs>
            </Box>
          </Container>
        </>
      ) : (
        <>
          <AppBar position="static" sx={{ pt: 2 }} className={styles.nav}>
            <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
              <Toolbar>
                <Logo />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }} align="right">
                  <MuiLink className={styles.link} href="https://www.thelifecelebrant.sg/about-tlc-the-life-celebrant.html" target="_blank" rel="noreferrer">
                    About TLC
                  </MuiLink>
                  <MuiLink className={styles.link} href="https://www.thelifecelebrant.sg/the-life-celebrant-services.html" target="_blank" rel="noreferrer">
                    TLC Services
                  </MuiLink>
                </Typography>

                {user.isLoggedIn ? (
                  <>
                    <Typography variant="h6" component={'div'} sx={{ display: { xs: "none", sm: "block" } }} ><span className={styles.name}>{name}</span></Typography>
                    <Box
                      sx={{ textAlign: 'right', flexGrow: { xs: 0, sm: 0 }, ml: 'auto' }}
                      onClick={(e: any) => handleClickProfileMenu(e)}
                    >
                      <Box sx={{ pl: 1, pt: { md: 1 }, cursor: 'pointer' }} style={{ color: 'var(--TLC-bodytxt)' }}><AccountIcon /></Box>
                    </Box>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openProfileMenu}
                      onClose={handleCloseProfileMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => handleNavigate("./profile")} className={styles.menuItem}>Profile</MenuItem>
                      {user.funeral.isRFM && <MenuItem onClick={() => handleNavigate("./rfm/approval")} className={styles.menuItem}>Approval</MenuItem>}
                      <MenuItem onClick={() => handleLogout()} className={styles.menuItem}>Logout</MenuItem>
                    </Menu>
                    <IconButton
                      onClick={() => setOpen(true)}
                      size="large"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{
                        color: 'black',
                        paddingRight: '0px',
                        display: { xs: 'block', sm: 'none' }
                      }}
                    >
                      <MenuIcon sx={{ width: '45px', height: '40px' }} />
                    </IconButton>
                  </>

                ) : (
                  <Typography component={'div'} sx={{ display: 'flex', flexGrow: { xs: 1, sm: 0 }, justifyContent: { xs: 'flex-end', sm: 'none' } }}>
                    <Button className="btnLogin" onClick={handleOpen} style={{ marginRight: "2%" }}>
                      Login
                    </Button>
                    <IconButton
                      onClick={() => setOpen(true)}
                      size="large"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{
                        color: 'black',
                        paddingRight: '0px',
                        display: { xs: 'block', sm: 'none' }
                      }}
                    >
                      <MenuIcon sx={{ width: '45px', height: '40px' }} />
                    </IconButton>
                  </Typography>
                )}
              </Toolbar>
            </Container>
          </AppBar>
          <Container maxWidth="xl" className={styles.container} sx={{ px: { md: 4, lg: 0 } }}>
            <Box className={styles.tabs} sx={{ borderBottom: 1, borderColor: "divider", display: { xs: "none", sm: "block" } }}>
              <Tabs
                value={value}
                onChange={handleChange}
                className={styles.navTabs}
                aria-label="nav tabs"
                variant="scrollable"
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "transparent" }, "& .Mui-selected": { color: "var(--TLC-gray) !important", fontWeight: 700 } }}
              >
                <Tab label="About" disableRipple component={Link} to={"./"} />
                <Tab label="Obituary" disableRipple component={Link} to={"./obituary"} />
                <Tab label="Services" disableRipple component={Link} to={"./events"} />
                {
                  isGalleryEnabled &&
                  <Tab label="Gallery" disableRipple component={Link} to={"./gallery"} />
                }
                <Tab label="Hearfelt Messages" disableRipple component={Link} to={"./messages"} />
                {
                  isGiftsEnabled &&
                  <Tab label="Gifts" disableRipple component={Link} to={"./give"} />
                }
              </Tabs>
            </Box>
            <Box sx={{ pt: 2 }}>
              <WebviewTitle />
            </Box>
          </Container>
        </>
      )}
      <Modal open={user.toggleLoginModal} onClose={handleClose}>
        <LoginModal handleClose={handleClose} />
      </Modal>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {<Box
          component={'div'}
          sx={{ width: 300 }}
          // onClick={() => setOpen(false)}
          className={styles.drawer}
        >
          <List>
            <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                sx={{
                  paddingTop: '0px',
                  color: '#FFF',
                  marginLeft: 'auto'
                }}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </ListItem>

            {navItems.map((item, index) => {
              if (!user.funeral.isRFM && item.label === 'Approval') return <></>

              if (item.label === 'Profile') {
                return (
                  <Box key={index}>
                    <ListItem disablePadding sx={{ width: '120px' }}>
                      <ListItemButton onClick={() => setProfileOpen(!profileOpen)}>
                        <ListItemText
                          className={styles.draweritem}
                          primary={item.label}
                        />
                        {profileOpen ? <ExpandLess sx={{ mt: 0.5 }} /> : <ExpandMore sx={{ mt: 0.5 }} />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={profileOpen} timeout="auto" unmountOnExit>
                      {
                        profileItems.map((subItem, subIndex) => {
                          return (
                            <ListItem key={10 + subIndex} disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => {
                                setOpen(false)
                                navigate(subItem.path)
                              }}>
                                <ListItemText className={styles.drawerItem} primary={subItem.label} />
                              </ListItemButton>
                            </ListItem>
                          )
                        })
                      }
                    </Collapse>
                  </Box>
                )
              } else {
                return (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{
                      paddingLeft: '50px',
                      paddingRight: '50px',
                    }}
                  >
                    <ListItemButton onClick={() => {
                      navigate(item.path)
                      setOpen(false)
                    }}>
                      <ListItemText className={styles.drawerItem} primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                )
              }
            })}
            <div
              className={styles.divider}
              style={{
                display: user.isLoggedIn ?
                  ''
                  :
                  'none'
              }}
            ></div>
            {mobileProfileItems.map((item, index) => {
              return (
                <ListItem
                  key={2 + index}
                  disablePadding
                  sx={{
                    paddingLeft: '50px',
                    paddingRight: '50px',
                  }}
                >
                  {
                    item.label === 'Logout' ?
                      <ListItemButton
                        onClick={() => handleLogout()}
                        sx={{
                          display: user.isLoggedIn ?
                            ''
                            :
                            'none'
                        }}
                      >
                        <ListItemText className={styles.drawerItem} primary={item.label} />
                      </ListItemButton>
                      :
                      <ListItemButton onClick={() => {
                        setOpen(false)
                        window.location.href = item.path
                      }}
                        sx={{
                          display: item.label === 'Approval' ?
                            'none'
                            :
                            user.isLoggedIn ?
                              ''
                              :
                              'none'
                        }}
                      >
                        <ListItemText className={styles.drawerItem} primary={item.label} />
                      </ListItemButton>
                  }
                </ListItem>
              )
            })}
            {
              user.funeral.isRFM &&
              <ListItem
                disablePadding
                sx={{
                  paddingLeft: '50px',
                  paddingRight: '50px',
                }}
              >

                <ListItemButton onClick={() => {
                  setOpen(false)
                  window.location.href = mobileProfileItems[1].path
                }}>
                  <ListItemText className={styles.drawerItem} primary={mobileProfileItems[1].label} />
                </ListItemButton>
              </ListItem>
            }
            <div className={styles.divider}></div>
            {externalItems.map((item, index) => {
              return (
                <ListItem
                  key={2 + index}
                  disablePadding
                  sx={{
                    paddingLeft: '50px',
                    paddingRight: '50px',
                  }}
                >
                  <ListItemButton onClick={() => {
                    setOpen(false)
                    window.location.href = item.path
                  }}>
                    <ListItemText className={styles.drawerItem} primary={item.label} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
          <Box>
            <Typography sx={{ fontSize: '12px', textAlign: 'center', pt: 8, pb: 5, color: '#DCDCDC' }}>
              v1.001
              <br />
              © {new Date().getFullYear()} The Life Celebrant. All Rights Reserved.
              <br />
              Powered by VisionTech.
            </Typography>
          </Box>
        </Box>}
      </SwipeableDrawer >
    </>

  )
}

export default Navbar
