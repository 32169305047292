import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import CondolenceList from '../../components/webview/condolenceList/CondolenceList'
import Give from '../give/Give'
import DonationSection from '../../components/donate/DonationSection'
import { getCurrentWidthDimension } from "../../constants"
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { useAppSelector } from '../../app/hooks'

function Messages() {
  const funeralState = useAppSelector(getFuneralState)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isGiftsEnabled, setIsGiftsEnabled] = useState<number>(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    setIsGiftsEnabled(funeralState.details.showGifts)
  }, [funeralState.details.showGifts])

  return (
    <>
      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

      <Box sx={{ py: 2 }}>
        <CondolenceList pagination={true} />
      </Box>

      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

      {
        isGiftsEnabled &&
        <Box sx={{ py: 2 }}>
          <Give />
        </Box>
      }

      {screenWidth > 550 && <Typography component={'div'} variant={'body2'} className="hr"></Typography>}

      <Box sx={{ py: 2 }}>
        <DonationSection />
      </Box>
    </>
  )
}

export default Messages