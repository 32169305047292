import { Alert, Avatar, Grid, Snackbar, Typography } from '@mui/material';
import styles from './Toast.module.css';

interface ToastProps  {
    message:string 
    type:any
    open:any
    handleClose?:any
};

const Toast: React.FC<ToastProps> = (props) => {
    const { open, handleClose, type } = props;
    return (
        <div>
        {" "}
        <Snackbar open={open} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={type}>
            {props.message}
          </Alert>
        </Snackbar>
      </div>
    );
}  
  
export default Toast




