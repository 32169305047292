import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography, Link, Button as MuiButton } from '@mui/material';
import Button from '../../base/Button/Button';
import styles from './WebviewShare.module.css';
import Toast from '../../base/Toast/Toast';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import LinkIcon from '../../base/Icons/LinkIcon';
import EmailIcon from '../../base/Icons/EmailIcon';
import FacebookIcon from '../../base/Icons/FacebookIcon';

const WebviewShare: React.FC = () => {
  const { id, name } = useParams()
  const [url, setUrl] = useState('')
  const [facebookShare, setFacebokoShare] = useState('')
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  useEffect(() => {
    let link = `${process.env.REACT_APP_URL}${name}/${id}`
    setUrl(link)
    let fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${link}`
    setFacebokoShare(fbUrl)
  }, [])

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setToast({
      ...toast,
      open: true,
      message: "Link copied",
      type: 'success'
    })
  }

  const handleShare = () => {
    const width = 550
    const height = 400
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(facebookShare, "_blank", `width=${width},height=${height},top=${top},left=${left}`);
  }


  return (
    <>
      <Toast {...toast} />
      <Typography component={'div'} className={styles.wvDivShare} sx={{ textAlign: { xs: 'center', sm: 'right' } }}  >

        <Button className='btnIcon' sx={{ minWidth: '60px', pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }} onClick={handleShare}>
          <FacebookIcon />
          {/* <FacebookOutlinedIcon sx={{ fontSize: 28, color: '#1877F2' }} /> */}
        </Button>

        <MuiButton className='btnIcon' sx={{ minWidth: '60px', pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }} href="mailto:default@gmail.com">
          <EmailIcon />
          {/* <EmailOutlinedIcon sx={{ fontSize: 28, color: 'var(--TLC-blue-stroke)' }} /> */}
        </MuiButton>

        <Button className='btnIcon' sx={{ minWidth: '60px', pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }} onClick={handleCopy}>
          <Box component={'span'} sx={{ pr: 2, display: { sm: 'block' } }} style={{ color: 'var(--TLC-gray)' }}>Copy Link</Box>
          <Box sx={{ pt: 1 }} style={{ color: 'var(--TLC-gray)' }}><LinkIcon /></Box>
        </Button>

      </Typography>
    </>

  );
}

export default WebviewShare