import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box, Grid, Button } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import CustomSearch from '../base/Textfield/CustomSearch';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
// import Button from '../base/Button/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useGetProductsQuery } from '../../features/products/productGqlApi';
import { getProductState } from '../../features/products/productSlice';
import styles from './Product.module.css';
import { CardMedia } from '@mui/material';
import { getComparator, stableSort } from '../../constants';
import { Order } from '../../constants';
import { useAddItemOrderMutation, useGetOrderQuery, useSetOrderShippingMethodMutation } from '../../features/order/orderGqlApi';
import { getOrderState } from '../../features/order/orderSlice';
import { getFuneralState } from '../../features/funeral/funeralSlice';
import { getUserState } from '../../features/user/userSlice';

const CustomSelect = styled(Select)({
  '&': {
    background: 'transparent'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--TLC-web-grey)',
    borderRadius: '3px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--TLC-web-grey)'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--TLC-web-grey)'
  },
}
);

const StyledPagination = styled(Pagination)({
  '& button': {
    height: '45px',
    width: '45px'
  },
  '& .MuiButtonBase-root': {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-gray)'
  },
  '& .MuiButtonBase-root.Mui-disabled': {
    border: '1px solid #CCCCCC',
    color: '#CCCCCC',
    backgroundColor: 'transparent',
    opacity: '1',
  },
  '& .Mui-selected': {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-white)',
    backgroundColor: 'var(--TLC-gray)'
  },
  '& li:last-child': {
    color: 'var(--TLC-gray)',
    '& button': {
      backgroundColor: 'var(--TLC-gray)',
      width: 100,
      color: 'var(--TLC-white)'
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Next"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  },
  '& li:first-of-type': {
    border: '1px solid var(--TLC-web-gray)',
    color: 'var(--TLC-gray)',
    '& button': {
      backgroundColor: 'var(--TLC-gray)',
      width: 120,
      color: 'var(--TLC-white)'
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Previous"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  }
});

interface ProductListProps {
  selectedEvent?: any
  onSave: (selected: readonly any[]) => void
}

interface ProductObject {
  [key: string]: any
}

const sorts = ['Price Highest - Lowest', 'Price Lowest - Highest']

const ProductList: React.FC<ProductListProps> = (props) => {
  const userState = useAppSelector(getUserState);
  const { isLoggedIn } = userState

  const getVendureProducts = useGetProductsQuery('')
  useEffect(() => {
    if (!getVendureProducts.isLoading && getVendureProducts.data) {
    }
  }, [getVendureProducts])

  const { id } = useParams()
  let products = useAppSelector(getProductState)
  let event_products = [];
  const [sort, setSort] = useState('Price Highest - Lowest')
  const [page, setPage] = useState(1);
  const itemPerPage = 9
  const [query, setQuery] = useState('')
  // const [selected, setSelected] = useState<readonly any[]>([]);
  // const [filtered, setFiltered] = useState<readonly any[]>([]);
  // const [paginated, setPaginated] = useState<readonly any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);
  const [paginated, setPaginated] = useState<any[]>([]);

  const funeralState = useAppSelector(getFuneralState);
  const { events, details } = funeralState;
  const [upcomingEvent, setUpcomingEvent] = useState<any>({
    id: '',
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    address: '',
    products: ''
  })

  const [imageHeight, setImageHeight] = useState(window.innerWidth)

  useEffect(() => {
    if (window.innerWidth < 768) {
      setImageHeight(window.innerWidth / 2 - 20)
    } else {
      setImageHeight(window.innerWidth / 5 - 20)
    }
  }, [window.innerWidth])

  let tempItemList: ProductObject[] = []
  let [filteredItems, setFilteredItems] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    let date = new Date().setHours(0, 0, 0, 0);
    let start_flag = true;
    for (var item of events) {
      /* if (new Date(item.startDate).getTime() >= date) {
        if ( start_flag ) {
          setUpcomingEvent(item);
          start_flag = false;
        }else{
          if (new Date(upcomingEvent.startDate).getTime() > new Date(item.startDate).getTime()) {
            setUpcomingEvent(item)
          }
        }
      } */
      if (new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
        setUpcomingEvent(item)
        break
      }
    }

    let productArray = upcomingEvent.products.split(",");

    products.items.map((item) => {
      item.variants.map((subItem: any) => {
        if (productArray.indexOf(subItem.id) > -1) {
          let tempItem: ProductObject = {}
          tempItem.id = subItem.id
          tempItem.name = subItem.name
          tempItem.slug = item.slug
          tempItem.price = subItem.price
          tempItem.description = item.description
          tempItem.image = subItem.assets[0]?.source
          tempItemList.push(tempItem)
        }
      })
    })

    event_products = tempItemList
    // setFiltered(event_products)
    setFiltered([...products.items])
  }, [events, products, upcomingEvent])

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('price');
  const [addItemToOrder] = useAddItemOrderMutation()
  const { refetch } = useGetOrderQuery(id)
  const [setOrderShippingMethod] = useSetOrderShippingMethodMutation();

  const handleAddCart = async (event: any) => {

    if (isLoggedIn) {

      const productVariantId = event.target.value;

      const quantity = 1;

      try {
        await addItemToOrder({
          productVariantId,
          quantity,
          funeralId: id,
          eventId: upcomingEvent.id
        })

        await setOrderShippingMethod({ funeralId: id })

        refetch()
      } catch (e) {
        console.error('error adding to cart', e)
      }

    } else {
      return null;
    }
  }

  useEffect(() => {
  }, [props.selectedEvent])

  useEffect(() => {

    const filteredItemsList = filtered.filter(item => {
      return item.name.toLowerCase().includes(query.toLowerCase())
    })

    setFilteredItems(filteredItemsList)
    setTotalPages(Math.ceil(filteredItemsList.length / itemPerPage))

    let start = (page - 1) * itemPerPage
    let end = (page) * itemPerPage

    const sortedItems = filteredItemsList.sort((a, b) => order === 'desc' ? parseFloat(b.variants[0].price) - parseFloat(a.variants[0].price) : parseFloat(a.variants[0].price) - parseFloat(b.variants[0].price))

    let paginatedData = sortedItems.slice(start, end)

    setPaginated(paginatedData)

  }, [filtered, page, query, order])

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredItems;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map(x => x.id).indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected.push(...selected, row)
    }
    else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    }
    else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  };

  const handleSortChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    if (value === 'Price Highest - Lowest') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }
    setSort(value)
  }

  const isSelected = (id: string | number) => {
    return selected.map(x => x.id).indexOf(id) !== -1
  }

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomSearch sx={{ mb: { md: 3, xs: 1 } }}
            placeholder='Search for a gift'
            value={query}
            onChange={e => setQuery(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: { md: 3, xs: 0 }, pt: '0px!important' }}></Grid>
        <Grid item xs={12} md={4} sx={{ pt: { xs: '0px!important', md: 3 }, }}>
          <Box sx={{ width: { xs: '100%', md: 'auto' } }}>
            <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>Sort: </Box>
            <CustomSelect
              sx={{ ml: { xs: '0px!important', md: 1 }, mr: { xs: '0px!important' }, width: { xs: '100%', md: 'auto' } }}
              size='small'
              labelId="action"
              value={sort}
              onChange={handleSortChange}
              input={<OutlinedInput />}
            >
              {sorts.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ pt: 3 }} >
        {paginated && stableSort(paginated, getComparator(order, orderBy))
          .map((item, index) => {
            return (
              <Grid item xs={6} md={4} key={item.id} sx={{}}>
                <Link className='link' to={`./product/${item.slug}/${item.id}`} relative="path">
                  <Box sx={{ height: `${imageHeight}px`, backgroundColor: 'white' }}>
                    <CardMedia
                      component="img"
                      className={styles.listImg}
                      image={`${item.assets[0].source}`}
                      alt={item.name} loading="lazy"
                    />
                  </Box>
                </Link>
                <Typography className={styles.productName} sx={{ mt: 1 }}>
                  {item.name}
                </Typography>
                <Typography className={styles.productPrice} sx={{ mt: 1 }}>
                  ${(item.variants[0].price / 100).toFixed(2)}
                </Typography>
                <p
                  className={styles.productDetails}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <Button sx={{ height: { xs: '45px' } }} variant="outlined" className={styles.btnCart} onClick={handleAddCart} value={item.variants[0].id} fullWidth>Add To Cart</Button>
              </Grid>
            )
          })
        }
      </Grid>
      <Stack className={styles.pagination} spacing={2}>
        <StyledPagination count={totalPages} variant="outlined" shape="rounded" onChange={(e, page) => setPage(page)} />
      </Stack>
    </>
  )
}

export default ProductList