import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store';
import { condolence_message, create_condolence_message } from '../../../types';


export interface CondolenceMessagelistState {
  condolenceMessagelist: condolence_message[]
  createCondolenceMessage: create_condolence_message
  editCondolenceMessage: condolence_message
}

const initialState: CondolenceMessagelistState  = {
condolenceMessagelist: [],

createCondolenceMessage: {
    funeralId: '',
    userId: '',
    createdAt: '', // new Date(),
    message: '',
    image: '',
    approve: '',
    private: '',
    broadcast: ''
  },
editCondolenceMessage: {
    id: '',
    funeralId: '',
    userId: '',
    createdAt: '', // new Date(),
    message: '',
    image: '',
    approve: '',
    private: '',
    broadcast: '',
    firstName: '',
    lastName: ''
  }
};

export const condolenceMessageListSlice = createSlice({
  initialState,
  name: 'condolencemessagelist',
  reducers: {
    setCondolenceMessagelist: (state, {payload}) => {
      state.condolenceMessagelist = payload
    }
  },
});

export const getCondolenceMessagelistState = (state: RootState) => state.condolenceMessageList;

export default condolenceMessageListSlice.reducer;

export const { setCondolenceMessagelist } = condolenceMessageListSlice.actions;