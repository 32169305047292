import React, { useEffect } from 'react'
import { useGetMeMutation } from '../features/user/userGqlAPI'

type AuthenticateProps = {
  children: React.ReactNode;
};

const Authenticate: React.FC<AuthenticateProps> = (props) => {
  
  const [getUser, {isSuccess}] = useGetMeMutation()
  useEffect(() => {
    getUser(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  if(isSuccess) {
    return (
      <div>{props.children}</div>
    )
  }else {
    return  <></>
  }
  
}

export default Authenticate