import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import styles from './EventSteps.module.css'
import CreateEvent from '../Create/CreateEvent';
import { getRfmEditState } from '../../../features/rfmEdit/rfmEditSlice';

interface CreateEventPageProps {
  handleStepper: (step: number) => void
}

const initial = {
  type: 'Ash collection : Collection of cremated remains',
  title: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  address: '',
  livestream: 'Live Stream Not Available',
  livestreamUrl: '',
  details: '',
  products: ''
}

const CreateEventPage: React.FC<CreateEventPageProps> = (props) => {
  const { id, eventId } = useParams()
  const rfmEditState = useAppSelector(getRfmEditState)
  const { event_details, guestlist } = rfmEditState
  const [errors, setErrors] = useState({
    event: false
  })
  const [eventList, setEventlist] = useState([initial])

  useEffect(() => {
    let initial = {
      id: event_details.id,
      type: event_details.type,
      title: event_details.title,
      startDate: event_details.startDate,
      endDate: event_details.endDate,
      startTime: event_details.startTime,
      endTime: event_details.endTime,
      address: event_details.address,
      livestream: event_details.livestream,
      livestreamUrl: event_details.livestreamUrl,
      details: event_details.details,
      products: event_details.products
    }
    setEventlist([initial])
  }, [event_details])

  const nextStep = () => {
    if(event_details.id) {
      setErrors({event: false})
      props.handleStepper(1)
    }else {
      setErrors({event: true})
    }
    
  }

  return (
    <>
      {
        eventList.map((item, index) => {
          return (
            <Box key={index} className={styles.container} sx={{mb:2, mt:2}}>
              <CreateEvent eventDetails={item} funeralId={event_details.funeralId || id} handleStepper={props.handleStepper}/>
            </Box>
            
          )
        })
      }
      {/* <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        <Grid item sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 300}, mb: 4}} onClick={nextStep}>Next</Button>
        </Grid>
      </Grid> */}
    </>
    
  )
}

export default CreateEventPage