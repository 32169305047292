import React from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useNavigate, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '../../base/Button/Button';
import Share from '../../share/Share';
import { getFuneralState } from '../../../features/funeral/funeralSlice';
import { getRfmEditState } from '../../../features/rfmEdit/rfmEditSlice';
import { reset } from '../../../features/rfmEdit/rfmEditSlice';
import styles from 'EventSteps.module.css'

interface EventShareProps {
  handleStepper: (step: number) => void
}

const EventShare: React.FC<EventShareProps> = (props) => {
  const { id, name } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const funeralState = useAppSelector(getFuneralState)
  const rfmEditState = useAppSelector(getRfmEditState)

  const prevStep = () => {
    props.handleStepper(0)
  }

  const onDone = () => {
    dispatch(reset())
    navigate(`/${name}/${id}/events`)
  }
  return (
    <>
      <Share account={funeralState.details} eventlist={funeralState.events} email={rfmEditState.email}/>
      <Box>
        <Grid container spacing={2} justifyContent="end" alignItems="center" sx={{mt:2}}>
          {/* <Grid item xs={6} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: 200, sm: 300}, mb: 4}} onClick={prevStep}>Previous</Button>
          </Grid> */}
          <Grid item xs={12} md={2.2} sx={{mt: 2}}>
            <Button className='btnPrimary' sx={{width: {xs: '100%', sm: 300}, mb: 4}} onClick={onDone}>Done</Button>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}

export default EventShare