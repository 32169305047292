import React, { useEffect } from 'react'
import { Typography, InputLabel, Box } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useAppSelector } from '../../app/hooks'
import { getUserState } from '../../features/user/userSlice'
import styles from './Profile.module.css'

const Logout = () => {
  const userState = useAppSelector(getUserState)
  const navigate = useNavigate()

  useEffect(() => {
    if(userState.isLoggedIn) {
      navigate('../about')
    }
  }, [userState.isLoggedIn])
  return (
    <Box sx={{minHeight: 500}}>
      <Typography component={"div"} variant={"body2"} className="hr"></Typography>
      <InputLabel className={styles.logoutText} sx={{ mt: 4 }}>
        Logged out successfully.
      </InputLabel>
      <InputLabel className={styles.returnText} sx={{ mt: 2, color: "var(--TLC-bodytxt)" }}>
        Return to{" "}
        <Link to="../about" style={{ color: "var(--TLC-gray)" }}>
          About
        </Link>{" "}
        page.
      </InputLabel>
    </Box>
  )
}

export default Logout
