import React, { useState, useEffect, useCallback } from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import { InputLabel, Typography } from "@mui/material"
import { debounce, validateEmail, validateTel } from "../../../constants"
import CustomTextField from "../../base/Textfield/CustomTextField"
import Button from "../../base/Button/Button"
import styles from "./WebviewRsvp.module.css"

interface props {
  handleClose: () => void
  submitRsvp: (data: any) => void
}

const GuestRsvpModal: React.FC<props> = (props) => {
  const [loading, setLoading] = useState(false)
  const { handleClose, submitRsvp } = props
  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: ""
  })
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false
  })

  const validateSingleField = (obj: { key: string; value: string }) => {
    const { key, value } = obj
    let rv
    if (key === "email") {
      rv = validateEmail(value)
    }
    if (key === "name") {
      value ? (rv = true) : (rv = false)
    }
    if (key === "phoneNumber") {
      rv = validateTel(value)
    }

    if (!rv) {
      setErrors((prev) => ({ ...prev, [key]: true }))
    } else {
      setErrors((prev) => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(debounce(validateSingleField), [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setForm({
      ...form,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const validate = () => {
    let hasError = false
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        case "email":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.email ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        case "name":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.name ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        case "phoneNumber":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.phoneNumber ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        default:
          break
      }
    }

    return hasError
  }

  const handleSubmit = async () => {
    setLoading(true)
    const hasError = validate()
    if (hasError) {
      setLoading(false)
      return
    }
    try {
      submitRsvp(form)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return (
    <>
      <div className={styles.guestRsvpModal}>
        <Card className={styles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel htmlFor="name">Name</InputLabel>
              <CustomTextField error={errors.name} helperText={errors.name ? "Requires a valid name" : ""} onChange={handleChange} size="small" sx={{ width: { xs: "100%", md: "365px" } }} required id="name" name="name" placeholder="Name" />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <CustomTextField error={errors.email} helperText={errors.email ? "Requires a valid email" : ""} onChange={handleChange} size="small" sx={{ width: { xs: "100%", md: "365px" } }} required id="email" name="email" placeholder="Email" />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="email">Phone number</InputLabel>
              <CustomTextField error={errors.phoneNumber} helperText={errors.phoneNumber ? "Requires a valid phone number" : ""} onChange={handleChange} size="small" sx={{ width: { xs: "100%", md: "365px" } }} required id="phoneNumber" name="phoneNumber" placeholder="Phone number" />
            </Grid>
          </Grid>
          <Button fullWidth sx={{ marginTop: "5%" }} variant="contained" className="btnSecondary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>
        </Card>
      </div>
    </>
  )
}

export default GuestRsvpModal
