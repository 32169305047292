import React from 'react'
import { Box } from '@mui/material'

function LocationIcon(props: any) {
  return (
    <Box {...props}>
      <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3771 0.316406C15.3607 0.358133 19.5681 4.0405 20.1863 8.98717C20.5862 12.1897 19.6161 14.9652 17.3635 17.2664C15.2779 19.3965 13.1526 21.4884 11.0434 23.5956C10.6066 24.0317 10.1428 24.0282 9.70186 23.588C7.59885 21.4864 5.48957 19.3917 3.39768 17.2796C1.43862 15.3011 0.461528 12.9032 0.474742 10.1194C0.498387 5.40704 4.0208 1.28307 8.67122 0.510435C9.23522 0.41655 9.80827 0.379691 10.3771 0.316406ZM10.373 21.8897C10.414 21.8577 10.4369 21.8445 10.455 21.8264C12.3967 19.8841 14.3634 17.966 16.271 15.9917C18.1668 14.0298 18.9109 11.6493 18.495 8.95588C17.8072 4.50714 13.6575 1.48475 9.18028 2.12177C5.19818 2.68786 2.16675 6.1463 2.14937 10.1799C2.13963 12.5068 2.97903 14.502 4.62444 16.1509C6.47849 18.0091 8.33602 19.8632 10.1922 21.7186C10.2471 21.7729 10.3041 21.825 10.373 21.8897Z" fill="#545454" />
        <path d="M10.3778 13.9292C8.05637 13.9201 6.19676 12.0577 6.20511 9.74956C6.21345 7.44417 8.09254 5.58039 10.4021 5.58595C12.6957 5.59221 14.5574 7.48381 14.549 9.80033C14.54 12.0772 12.6602 13.9382 10.3778 13.9292ZM10.3527 12.258C11.7304 12.2712 12.8619 11.1752 12.8786 9.81145C12.8966 8.40388 11.777 7.26266 10.3729 7.25571C9.01539 7.24945 7.88182 8.37537 7.87278 9.73843C7.86443 11.1258 8.96531 12.2448 10.3527 12.258Z" fill="#545454" />
      </svg>
    </Box>
  )
}

export default LocationIcon