import React from "react"

function SquareCheckedIcon() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.02344" y="0.686646" width="29" height="29" rx="2.5" fill="white" stroke="#545454" />
      <rect x="6.02344" y="5.68665" width="19" height="19" rx="2.5" fill="#545454" stroke="#545454" />
    </svg>
  )
}

export default SquareCheckedIcon
