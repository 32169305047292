import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Pagination as MuiPagination, PaginationProps, PaginationItem, TextField } from '@mui/material';
import styles from './Pagination.module.css'
import Button from '../Button/Button';

const CustomPagination = styled(MuiPagination)({
  '& .MuiButtonBase-root' : {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-gray)'
  },
  '& .MuiButtonBase-root.Mui-disabled' : {
    border: '1px solid #CCCCCC',
    color: '#CCCCCC',
    backgroundColor: 'transparent',
    opacity: '1',
  },
  '& .Mui-selected': {
    border: '1px solid var(--TLC-web-gray)',
    color: '#E9E9E9'
  },
  '& li:last-child': {
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-lightblu)',
      width: 100
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Next"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  },
  '& li:first-of-type': {
    border: '1px solid var(--TLC-web-gray)',
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-lightblu)',
      width: 100
    },
    '& svg': {
      visibility: 'hidden'
    },
    '& button:before': {
      content: '"Previous"',
      verticalAlign: 'sub',
      marginLeft: '5px'
    }
  }
  
})

const MobilePagination = styled(MuiPagination)({
  '& .MuiButtonBase-root' : {
    border: '1px solid var(--TLC-gray)',
    color: 'var(--TLC-gray)'
  },
  '& .MuiButtonBase-root.Mui-disabled' : {
    border: '1px solid #CCCCCC',
    color: '#CCCCCC',
    backgroundColor: 'transparent',
    opacity: '1',
  },
  '& .Mui-selected': {
    border: '1px solid var(--TLC-web-gray)',
    color: '#E9E9E9'
  },
  '& li:last-child': {
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-lightblu)',
    },
    
  },
  '& li:first-of-type': {
    border: '1px solid var(--TLC-web-gray)',
    color: 'var(--TLC-gray)',
    '& button': { 
      backgroundColor: 'var(--TLC-lightblu)',
    },
  }
  
})

const PageSelector = styled(TextField)({
  '&': {
      marginRight: '4%'
    },
  '& .MuiOutlinedInput-root': {
    background: 'transparent',
    width: 40,
    '& fieldset': {
      border: '1px solid var(--TLC-web-grey)',
      borderRadius: '5px',
    },
    '& input': {
      padding: '8px 5px',
      textAlign: 'center'
    },
  }
})

interface CustomPaginationProps extends PaginationProps {
  handleViewChange?: (rowPerPage: number) => void
  type?: string
  rpp: number
}

const Pagination: React.FC<CustomPaginationProps> = (props) => {
  const { handleViewChange, type, rpp, sx, ...rest } = props
  const [rowPerPage, setRowPerPage] = useState<any>(rpp)
  const [imageHeight, setImageHeight] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", () => setImageHeight(window.innerWidth));
  },[])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value

    let regex = /^\d*$/
    if(regex.test(value)) {
      setRowPerPage(value)
    }else {
      return
    }
    
  }

  return (
    <Grid container spacing={2} justifyContent={'space-between'} sx={{...sx}}>
      <Grid item xs={12} md={5}>
        {
          !type && <Box sx={{display:'flex', alignItems:'center', justifyContent: {xs:'center', sm:'normal'}}}>
            <Typography className={styles.text} sx={{width: {xs: 120}}}>View Per Page</Typography>
            <PageSelector size="small" value={rowPerPage} onChange={handleChange}/>
            {
              handleViewChange && <Button className='btnTransparent' sx={{width: {xs: 150, sm:300}}} onClick={() => handleViewChange(+rowPerPage)}>View</Button>
            }
          </Box>
        }
        
      </Grid>
      <Grid item sx={{width: {xs:'100%', sm: 'auto'}, alignItems:{xs:'center', sm:'normal'}, justifyContent: {xs:'center', sm:'normal'}}}>
        <Box sx={ {display: {xs: 'flex', sm:'block'}, justifyContent: {xs:'center', sm:'normal'}}}>
          {
            imageHeight < 768 ? (
              <MobilePagination {...rest}/>
            ) : (
              <CustomPagination {...rest} />
            )
          }
        </Box>
        
        
      </Grid>
    </Grid>
    
  )
}

export default Pagination