import React, { useState, useEffect } from 'react'
import { Avatar, Box, Grid, Typography, Chip } from "@mui/material"
import styles from './Condolence.module.css'
import { formatDate } from '../../../constants'
import { useGetImageUrlMutation } from '../../../features/base/baseRestApi'

interface CondolenceCardProps {
  data: any
  isSimple?: boolean
}

const CondolenceCard: React.FC<CondolenceCardProps> = (props) => {
  const { data, isSimple = false } = props
  const [initial, setInitial] = useState('')
  const [name, setName] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  const [getImageUrl] = useGetImageUrlMutation()

  useEffect(() => {
    setInitial(data.firstName.charAt(0).toUpperCase() + data.lastName.charAt(0).toUpperCase())
    setName(data.lastName.charAt(0).toUpperCase() + data.lastName.substring(1))
    if(data.image) {
      getURL(data.image)
    }else {
      setImageUrl('')
    }
    
  }, [data])

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({image: filename}).unwrap()
      setImageUrl(rv.imageUrl)
    }catch(e) {

    }
  }

  return (
    
    <Box>
      {isSimple ? (
        <Grid container spacing={0} sx={{border: "solid 2px var(--TLC-gray)", p: 4}}>
          <Grid item xs={12} sx={{display: 'flex'}}>
            <div className={styles.msgDate}>{formatDate(data.createdAt, 'DD MMM YYYY')}</div>
            <Box sx={{display: 'flex'}} alignItems={'center'}>
              {
                data.private ? <Chip label="Private" size="small" className={styles.chip} sx={{ml:3}} /> : <></>
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: 1 }} textAlign={'justify'} dangerouslySetInnerHTML={{ __html: data.message }} className="sectionText"></Typography>
          </Grid>
          <Grid item xs={12} sx={{fontWeight: "bold", display: "flex", pb: 1, ml: "auto", justifyContent: "end"}}>
            -&nbsp;&nbsp;{data.initial ? <div className={styles.name}>{initial}</div> : <div className={styles.name}>{name}</div>}
          </Grid>
          {imageUrl ? (
            <Grid item xs={12} sx={{mx:1}}>
              <Box component={'div'} sx={{width: {xs: '100%', md: '30%'}}}>
                <img src={imageUrl} style={{objectFit: 'cover', height: '110px'}}/>
              </Box>
            </Grid>
          ) : <></>}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Grid item xs={7}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar sx={{ bgcolor: '#fcedf0', color: "#707070", fontSize: 20 }}>{initial}</Avatar>
                  </Grid>
                  <Grid item sx={{display: 'flex'}}>
                    <Box>
                      {data.initial ? <div className={styles.name}>{initial}</div> : <div className={styles.name}>{name}</div>}
                      <div className={styles.msgDate}>{formatDate(data.createdAt, 'DD MMM YYYY')}</div>
                    </Box>
                    <Box sx={{display: 'flex'}} alignItems={'center'}>
                      {data.private ? <Chip label="Private" size="small" className={styles.chip} sx={{ml:3}} /> : <></>}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              </Grid>
            </Grid>
          </Grid>
          {imageUrl ? (
            <Grid item xs={12} sx={{mx:1}}>
              <Box component={'div'} sx={{width: {xs: '100%', md: '30%'}}}>
                <img src={imageUrl} style={{objectFit: 'cover', width: '100%'}}/>
              </Box>
              
            </Grid>
          ) : <></>}
          <Grid item xs={12}>
            <Typography sx={{ mt: 1, mb: 3, px:1 }} textAlign={'justify'} dangerouslySetInnerHTML={{ __html: data.message }} className="sectionText"></Typography>
          </Grid>

        </Grid>
      )}
    </Box>
    
  )
}

export default CondolenceCard