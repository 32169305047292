import Box from '@mui/material/Box';
import styles from './Navbar.module.css'

function Logo() {
  return (
    <Box>
      <img className={styles.logodiv}
        src='/images/TLC-MAIN-LOGO.png'
        alt="The Life Celebrant"
        loading="lazy"
      />
    </Box>
  )
}

export default Logo