import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import styles from './Approval.module.css'
import { useGetUserQuery } from '../../features/user/userRestApi';
import { formatDate } from '../../constants';
import { useParams } from 'react-router-dom';

interface UserInfoProps {
  userId: string | number | undefined
  type: string | undefined
  date: Date | undefined
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { id } = useParams()
  const { userId, type, date } = props
  const [user, setUser] = useState<any>({
    firstName: '',
    lastName: ''
  })
  const [initial, setInitial] = useState('')
  const [name, setName] = useState('')
  const [msgDate, setMsgDate] = useState('')
  const {data, isLoading} = useGetUserQuery({funeralId: id, userId:userId,type:type}, {refetchOnMountOrArgChange: true})

  useEffect(() => {
    if(data && !isLoading) {
      setUser(data)
    }
    
  }, [data, isLoading])

  useEffect(() => {
    setInitial(user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase())
    setName(user.lastName.charAt(0).toUpperCase() + user.lastName.substring(1))
    setMsgDate(formatDate(date, 'DD MMM YYYY'))
  }, [user])

  return (
    <Box>
      <Grid container spacing={2} sx={{mb:2,pt:1}}>
        <Grid item>
          <Avatar sx={{width: 42, height: 42, bgcolor: '#fcedf0', color: "#707070", fontSize: 20 }}>{initial}</Avatar>
        </Grid>
        <Grid item>
          <div className={styles.msgSender}>{name}</div>
          <div className={styles.msgDate}>{msgDate}</div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserInfo