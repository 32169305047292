import { Avatar, Box, Checkbox, Chip, FormControlLabel, Grid, Link, Table, Typography } from "@mui/material"
import { useAppSelector } from '../../../app/hooks'
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../base/Button/Button"
import WebviewShare from "../share/WebviewShare"
import CondolenceCard from "./CondolenceCard"
import CondolenceMessage from '../condolenceMessage/CondolenceMessage'
import { useGetCondolenceMessagelistQuery } from "../../../features/webview/condolenceList/condolenceListRestApi"
import { getFuneralState } from '../../../features/funeral/funeralSlice'
import { getUserState } from '../../../features/user/userSlice'
import Pagination from '../../base/Pagination/Pagination'
import styles from './Condolence.module.css'

interface CondolenceListProps {
  limit?: number
  pagination?: boolean
  isSimple?: boolean
}

const CondolenceList: React.FC<CondolenceListProps> = (props) => {
  const navigate = useNavigate()
  const { limit, pagination, isSimple = false } = props
  const { id } = useParams()
  const { isLoggedIn, funeral } = useAppSelector(getUserState)
  const [messages, setMessages] = useState<any[]>([])
  const { data, isLoading } = useGetCondolenceMessagelistQuery({ funeralId: id, isLoggedIn: isLoggedIn }, { refetchOnMountOrArgChange: true })
  const rowsPerPage = 5
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (data && !isLoading) {
      if (limit && data.length !== limit) {
        setMessages(data.slice(0, limit))
      } else {
        setMessages(data)
      }
    }
  }, [data, isLoading])

  const handleViewMore = () => {
    navigate('../messages')
  }

  //Pagination
  const totalPages = Math.ceil(messages.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1)
  }

  return (
    <Box>
      {
        !limit && messages.length > 0 ? (
          <CondolenceMessage />
        ) : (
          // <></>
          <CondolenceMessage />
        )
      }

      {
        messages.length === 0 ? (
          <Typography sx={{ fontSize: 16, py: 2 }}>
            No messages yet.
            <br />
            Send the first message.
          </Typography>
        )
          :
          (
            messages
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((message, index) => {
                return (
                  <Box key={index} sx={{ pt: 3 }} className={isSimple ? '' : styles.borderBottom}>
                    <CondolenceCard data={message} isSimple={isSimple} />
                  </Box>
                )
              })
          )
      }

      {
        pagination && <Pagination
          sx={{ mt: 3 }}
          variant="outlined" shape="rounded"
          count={totalPages}
          page={page + 1}
          rpp={rowsPerPage}
          onChange={handleChangePage}
          type="pagesOnly"
        />
      }
      {
        limit /* && limit === messages.length */ ? (
          <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} sm={6}>
              <Button className="btnPrimary" sx={{ width: { sm: '384px' }, height: { xs: '45px' }, display: 'flex' }} onClick={handleViewMore} fullWidth>Write A Heartfelt Message</Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button className="btnSecondary" sx={{ width: { sm: '384px' }, height: { xs: '45px' }, display: 'flex', ml: 'auto' }} onClick={handleViewMore} fullWidth>View More Messages</Button>
            </Grid>
          </Grid>
        ) : <></>
      }
    </Box>
  )
}

export default CondolenceList
