import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, Icon } from '../base/Checkbox/CustomCheckbox';
import UserInfo from './UserInfo';
import Toast from '../base/Toast/Toast';
import Button from '../base/Button/Button';
import styles from './Approval.module.css'

interface ApprovalCardProps {
  data: any
  onDelete: (id:any) => any
  handleCheckbox: (id:string | number | undefined, type: string) => void
}

interface DisplayImageProps {
  url: string
}

function DisplayImage (props: DisplayImageProps) {
  let { url } = props
  if(url) {
    return (
      <Box component={'div'} sx={{width: {xs: '100%', md: '30%'}}}>
        <img 
          src={url}
          alt="img"
          loading="lazy"
          style={{objectFit: 'cover', width: '100%'}}
        />
      </Box>
    )
  }else {
    return (<div></div>)
  }
}

const ApprovalCard: React.FC<ApprovalCardProps> = (props) => {
  const { data, handleCheckbox, onDelete } = props 
  const [dialog, setDialog] = useState(false)
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })


  const handleDelete = async () => {
    try {
    let rv=  await onDelete(data.id)

      if(rv) {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: rv.message
        })
        setDialog(false)
      }
    }catch(e) {
      console.log(e)
    }
    
  }

  return (
    <Box sx={{mt:4}}>
    <Toast {...toast}/>
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid sx={{pl:3}} >
          <FormControlLabel
            label="Approve"
            control={<Checkbox 
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />} checked={data.approve === 0 ? false : true} name="approve" onChange={() => handleCheckbox(data.id, 'approve')} />}
          />
        </Grid>
        <Grid sx={{pl:3}} >
          <FormControlLabel
            className={styles.label}
            label="Private for family members only"
            control={<Checkbox 
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />} checked={data.private === 0 ? false : true} name="private" onChange={() => handleCheckbox(data.id, 'private')} />}
          />
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            label="Broadcast"
            control={<Checkbox 
              icon ={<Icon />}
              checkedIcon={<CheckedIcon />} checked={data.broadcast === 0 ? false : true} name="broadcast" onChange={() => handleCheckbox(data.id, 'broadcast')} />}
          />
        </Grid> */}
        <Grid sx={{pl:{xs:3, sm:0}}}>
          <Button className='btnTransparent' onClick={() => setDialog(true)}>Delete</Button>
        </Grid>

        <Dialog
          open={dialog}
          onClose={() => setDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Message?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this message?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='btnTransparent' onClick={() => setDialog(false)}>Cancel</Button>
            <Button className='btnTransparent' onClick={handleDelete}>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
      
      <Grid item xs={12} md={8}>
          
      </Grid>

      <UserInfo userId={data.userId} type={data.type} date={data.createdAt}/>

      <Grid item xs={12} md={8}>
        <DisplayImage url={data.imageUrl}/> 
      </Grid>
      <Grid item xs={12} md={8}>
        <p dangerouslySetInnerHTML={{ __html: data.message }}></p>
      </Grid>
    </Box>
  )
}

export default ApprovalCard