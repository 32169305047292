import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from "react-router-dom";
import { useAppSelector } from '../app/hooks';
import { getUserState } from '../features/user/userSlice';
import { useCheckIsRfmQuery } from '../features/user/userRestApi';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { id, name } = useParams()
  const user = useAppSelector(getUserState)
  const { data , isLoading } = useCheckIsRfmQuery({id, isLoggedIn:user.isLoggedIn})
  const [isDone, setDone] = useState(false)
  const [isRFM, setIsRFM] = useState('')

  useEffect(() => {
    if(data && !isLoading) {
      setIsRFM(data.isRFM)
      setDone(true)
    } 

  }, [data , isLoading])

  /* if(!isRFM) {
    let path = `/${name}/${id}`
    return <Navigate to={path} />;
  } */

  if(isDone) {
    if(isRFM) {
      return (
        <>{props.children}</>
      )
    }else {
      let path = `/${name}/${id}`
      return <Navigate to={path} />
    }
  }

  

  return <></>
  
}

export default ProtectedRoute