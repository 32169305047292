import { gqlApi } from '../../api/graphql';
import { gql } from 'graphql-request';

export const paymentGqlApi = gqlApi.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentMethods: builder.query({
            query: () => ({
                body: gql`
                    query {
                        eligiblePaymentMethods{
                        id
                        code
                        name
                        description
                        isEligible
                        eligibilityMessage
                        }  
                    }
                `
            })
        }),
        getCreateStripePaymentIntent: builder.query({
            query: (data) => ({
                body: gql`
                    mutation {
                        createStripePaymentIntent
                    }
                `
            })
        }),
        addPaymentToOrder: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        addPaymentToOrder(input :{
                            method: "stripe-payment"
                              metadata : {
                              transactionId : "${data.transactionId}"
                            }
                        }) {
                          __typename
                        }
                    }
                `
            })
        }),
        transitionOrderToState : builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        transitionOrderToState(
                            state: "${data.orderState}"
                            ) 
                        {
                            ... on Order {
                                id
                                }
                            ... on OrderStateTransitionError {
                                errorCode
                                message
                                transitionError
                                fromState
                                toState
                            }
                        }
                    }
                `
            })
        })
    }),
})

export const { useGetPaymentMethodsQuery, useGetCreateStripePaymentIntentQuery, useTransitionOrderToStateMutation, useAddPaymentToOrderMutation } = paymentGqlApi
