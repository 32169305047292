import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';



export interface OrderState {
    order: any
}

const initialState: OrderState = {
    order : null
};

export const orderSlice = createSlice({
    initialState,
    name: 'order',
    reducers: {
        getOrder: (state, {payload}) => {
            state.order = payload
        }
    },
});

export const getOrderState = (state: RootState) => state.order;

export default orderSlice.reducer;

export const { getOrder } = orderSlice.actions;
