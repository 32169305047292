import { restApi } from '../../api/restApi'
import { setAuthToken } from './authSlice'

export const authRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    backendLogin: builder.mutation({
      invalidatesTags: ['isRfm'],
      query: (payload) => ({
        url: '/api/auth/login',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthToken(data))
        } catch (error) {}
      },
    }),
    healthcheck: builder.query({
      query: () => ({
        url: '/api/app-template/healthcheck',
        method: 'GET',
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: '/api/app-template/auth/register',
        method: 'POST',
        body: body
      }),
    }),
    test: builder.query({
      query: () => ({
        url: '/api/app-template/test',
        method: 'GET',
      }),
    })
  }),
})

export const { useBackendLoginMutation, useHealthcheckQuery, useRegisterUserMutation } = authRestApi