import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { dataRsvp } from '../../types';


export interface rsvpState {
  dataRsvp: dataRsvp[]
}

const initialState: rsvpState  = {
    dataRsvp: [],
};

export const dataRsvpSlice = createSlice({
  initialState,
  name: 'dataRsvp',
  reducers: {
    setdataRsvp: (state, {payload}) => {
      state.dataRsvp = payload
    }
  },
});

export const getDataRsvpState = (state: RootState) => state.rsvp;

export default dataRsvpSlice.reducer;

export const { setdataRsvp } = dataRsvpSlice.actions;