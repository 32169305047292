import React, {useEffect} from 'react'
import styles from './Toast.module.css'

interface CustomFailMsgProps {
  error: any
  message: string
}

const CustomFailMsg: React.FC<CustomFailMsgProps> = (props) => {
  let {error, message} = props

  useEffect(() => {
    setTimeout(() => {
      error = false
    },2000)
  }, [error])
  if(error) {
    return <p className={styles.error}>{message}</p>
  }else {
    return <p style={{height:10}}></p>
  }
}

export default CustomFailMsg