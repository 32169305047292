import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper';
import { MobileStepper } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import styles from './Stepper.module.css'
import { blue } from '@mui/material/colors';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    // left: 'calc(-80% + 55px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--TLC-gray)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'var(--TLC-gray)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'var(--TLC-web-grey)',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'var(--TLC-web-grey)',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: 'var(--TLC-gray)',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: 'var(--TLC-gray)',
      zIndex: 1,
      fontSize: 14,
    },
    '& .QontoStepIcon-circle': {
      width: 15,
      height: 15,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-alternativeLabel': {
    cursor: 'pointer'
  },
}))

const SimpleStepLabel = styled(StepLabel)(({ theme }) => ({
  '&': {
    padding: '10px',
    '& .MuiStepLabel-labelContainer': {
      '& .Mui-active': {
        color: 'var(--TLC-gray)'
      },
      '& .Mui-completed': {
        color: 'var(--TLC-gray)'
      }
    }
  },
  '& .MuiStepLabel-iconContainer': {
    display: 'none'
  },
  '&.MuiStepLabel-root': {
    color: 'blue',
    fontSize: '30px'
  }
}))

function SimpleConnector() {
  return (
    <>
      &gt;
    </>
  )
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

interface StepperProps extends MuiStepperProps {
  steps: string[]
  active: number
  handleClick: (index: number) => void
};

const Stepper: React.FC<StepperProps> = (props) => {
  const { steps, active, handleClick } = props
  if (window.innerWidth > 768) {
    return (
      <Stack sx={props.sx} spacing={4}>
        <MuiStepper alternativeLabel activeStep={active} connector={<QontoConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StyledStepLabel className={styles.label} StepIconComponent={QontoStepIcon} onClick={() => handleClick(index)}>{label}</StyledStepLabel>
            </Step>
          ))}
        </MuiStepper>
      </Stack>
    );
  } else {
    return (
      <MuiStepper activeStep={active} sx={{ ml: 1.5, mt: 2 }} connector={<SimpleConnector />}>
        {steps.map((label, index) => (
          <Step key={label} sx={{ px: 0 }}>
            <SimpleStepLabel onClick={() => handleClick(index)}>{label}</SimpleStepLabel>
          </Step>
        ))}
      </MuiStepper>
    );
  }
}

export default Stepper