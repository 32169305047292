import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../app/hooks'
import { Typography, Grid } from '@mui/material'
import styles from './Donate.module.css'
import { getFuneralState } from '../../features/funeral/funeralSlice'
import { useGetImageUrlMutation } from '../../features/base/baseRestApi'


const DonationSection: React.FC = () => {
  const funeralState = useAppSelector(getFuneralState)
  const {bankDetails, paynow_image, paynow_approval } = funeralState.details
  const [paynowImg, setPaynowImg] = useState("")

  useEffect(() => {
    if(paynow_image) {
      getURL(paynow_image)
    }
    
  }, [paynow_image])

  const [getImageUrl] = useGetImageUrlMutation()

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setPaynowImg(rv.imageUrl)
    } catch (e) {}
  }

  return (
        <Grid container spacing={2} sx={{pt:2}}>
          <Grid item xs={12} md={3}>
            <div className={`${styles.divDonatePink}`}>
              <Typography className="sectionTitle">
                <b>Donate</b>
              </Typography>
              <Typography className='sectionText'>Scan to donate</Typography>
              <br />
              {
              paynow_approval ? <img src={paynowImg} className="img" /> : <></>
              }
              <br />
              <br />
              <div dangerouslySetInnerHTML={{ __html: bankDetails }}></div>
            </div>
          </Grid>
        </Grid>
  )
}

export default DonationSection