import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from "@mui/material"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, Icon, IndeterminateIcon } from '../../components/base/Checkbox/CustomCheckbox';
import ApprovalCard from '../../components/approval/ApprovalCard';
import Button from '../../components/base/Button/Button';
import Toast from '../../components/base/Toast/Toast';
import Pagination from '../../components/base/Pagination/Pagination';

import { useGetMessagesQuery, useUpdateMessageMutation, useDeleteMessageMutation } from '../../features/rfmEdit/rfmEditRestApi';

function Approval() {
  const { id } = useParams()
  const {data, isLoading} = useGetMessagesQuery(id, { refetchOnMountOrArgChange: true })
  const [list, setList] = useState<any[]>([]);
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => {setToast(prev => ({...prev, open: false}))}
  })
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [updateMsg] = useUpdateMessageMutation()
  const [deleteMsg] = useDeleteMessageMutation()

  useEffect(() => {
    if(data && !isLoading) {
      setList(data)
    }
  }, [data, isLoading])

  const checkedCount = list.filter((item) => {
    return item.approve === 1
  })

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newList
    if (event.target.checked) {
      newList = list.map((item) => {
        return {
          ...item,
          approve: 1
        }
      })
    }else{
      newList = list.map((item) => {
        return {
          ...item,
          approve: 0
        }
      })
    }
    setList(newList);
  };

  const handleCheckbox = (id: string | number | undefined, type: string) => {
    let newList: any[]
    
    switch(type) {
      case 'approve':
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.approve ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      case 'private': 
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.private ? item.approve : 1,
              private: item.private ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      case 'broadcast': 
        newList = list.map((item) => {
          if(item.id === id) {
            return {
              ...item,
              approve: item.broadcast ? item.approve : 1,
              broadcast: item.broadcast ? 0 : 1
            }
          }else {
            return item
          }
        })
        break;
      default:
        return
    } 
    setList(newList)
  }

  const onApprove = async () => {
    try {
      let rv = await updateMsg({list, funeralId: id}).unwrap()

      if(rv) {
        setToast({
          ...toast,
          open: true,
          type: 'success',
          message: rv.message
        })
      }
    }catch(e) {
      console.log(e)
    }
    
  }

  const onDelete = async (messageId: any) => {
    try {
      let rv = await deleteMsg({messageId: messageId, funeralId:id}).unwrap()
      return rv
    }catch(e) {
      console.log(e)
    }
  }

  //Pagination
  const totalPages = Math.ceil(list.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage-1);
  };

  const handleChangeRowsPerPage = (rpp: number) => {
    //let rpp = parseInt(event.target.value, 10)
    setRowsPerPage(rpp);
    setPage(0);
  };

  return (
    <Box>
      <Toast {...toast} />
      <Typography component={"div"} variant={"body2"} className="hr"></Typography>
      <Typography sx={{mt:4}} className='sectionTitle' component={"div"} variant={"body2"}>Approve Heartfelt Messages</Typography>

      <Box sx={{mt:4}} >
        <Grid container spacing={2} sx={{mb:2}}>
          <Grid sx={{pl:3}} >
            <FormControlLabel
              label="Approve All"
              control={
                <Checkbox 
                  icon ={<Icon />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon/>}
                  checked={list.length > 0 && checkedCount.length === list.length} 
                  indeterminate={checkedCount.length > 0 && checkedCount.length < list.length}
                  name="approveAll" 
                  onChange={handleSelectAllClick} />
              }
            />
          </Grid>
          <Grid >
            <Button className='btnSecondary' sx={{my: 0,width: {xs: 200, sm: 300}}} onClick={onApprove}>Approve</Button>
          </Grid>
        </Grid>
        
        {
          list
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, index) => {
            return (
              <Box key={index}>
                <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                <ApprovalCard data={item} handleCheckbox={handleCheckbox} onDelete={onDelete}/>
              </Box>
              
            )
          })
        }
        <Pagination
          sx={{mt:3}}
          variant="outlined" shape="rounded"
          count={totalPages}
          page={page+1}
          rpp={rowsPerPage}
          onChange={handleChangePage} 
          handleViewChange={handleChangeRowsPerPage}
        />
      </Box>

    </Box>
  )
}

export default Approval