import React, { useState, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { styled } from "@mui/material/styles"
// import Link from '@mui/material/Link';
import { Link, useNavigate, useParams } from "react-router-dom"
import { Grid, Button, InputBase, Paper, IconButton, ButtonGroup, Box, Modal, TextField, Typography, Avatar, Input } from "@mui/material"
// import Button from '../base/Button/Button';
import Stack from "@mui/material/Stack"
import { useGetOrderQuery, useAdjustOrderLineMutation, useSetOrderShippingMethodMutation, useRemoveOrderLineMutation } from "../../features/order/orderGqlApi"
import { getOrderState } from "../../features/order/orderSlice"
import LoginModal from "../../components/login/LoginModal"
import styles from "./Order.module.css"
import { CardMedia } from "@mui/material"
import { Order } from "../../constants"

import { getUserState, toggleLoginModal } from "../../features/user/userSlice"
import { bgcolor } from "@mui/system"
import { getCurrentWidthDimension } from "../../constants"

interface OrderListProps {}

const getQuantities = (linesData: any) => (linesData ? linesData.reduce((result: any, item: any) => ({ ...result, [item.id]: item.quantity }), {}) : {})

export const CustomInput = styled(Input)({
  "input:before": {
    borderColor: "#CCCCCC!important"
  }
})

const OrderList = (props: any) => {
  const { id } = useParams()
  const isProductPage = props.isProductPage
  // useSetOrderQuery(null)
  const [adjustOrderLine] = useAdjustOrderLineMutation()
  const [removeOrderLine] = useRemoveOrderLineMutation()
  const [setOrderShippingMethod] = useSetOrderShippingMethodMutation()

  const { data, isLoading, refetch } = useGetOrderQuery(id, { refetchOnMountOrArgChange: true })

  const updatingTimeout = useRef<any>(null)
  const isLoggedIn = props.isLoggedIn

  const dispatch = useAppDispatch()
  const user = useAppSelector(getUserState)
  const handleOpen = () => dispatch(toggleLoginModal(""))
  const handleClose = () => dispatch(toggleLoginModal(""))

  const activeOrder = data?.activeOrder
  const lines = activeOrder?.lines
  const [orderQuantity, setOrderQuantity] = useState(getQuantities(lines))

  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidthDimension())
    }

    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    setOrderQuantity(getQuantities(lines))
    refetch()
  }, [lines, isLoggedIn])

  const handleMinus = async (index: any) => {
    if (updatingTimeout.current) {
      clearTimeout(updatingTimeout.current)
    }
    const number: number = orderQuantity[index] - 1
    setOrderQuantity((prev: any) => ({
      ...prev,
      [index]: prev[index] - 1
    }))
    updatingTimeout.current = setTimeout(async () => {
      const adjustId: number = Number(index)
      const adjustQuantity: number = number
      try {
        if (adjustQuantity > 0) {
          await adjustOrderLine({
            adjustId,
            adjustQuantity,
            funeralId: id
          })
        } else {
          await removeOrderLine({
            adjustId,
            funeralId: id
          })
        }
        await setOrderShippingMethod({ funeralId: id })
        refetch()
      } catch (e) {
        console.error("debug", e)
      }
    }, 400)
  }

  const handlePlus = async (index: any) => {
    if (updatingTimeout.current) {
      clearTimeout(updatingTimeout.current)
    }
    const number: number = orderQuantity[index] + 1
    setOrderQuantity((prev: any) => ({
      ...prev,
      [index]: prev[index] + 1
    }))
    updatingTimeout.current = setTimeout(async () => {
      const adjustId: number = Number(index)
      const adjustQuantity: number = number
      try {
        if (adjustQuantity > 0) {
          await adjustOrderLine({
            adjustId,
            adjustQuantity,
            funeralId: id
          })
        } else {
          await removeOrderLine({
            adjustId,
            funeralId: id
          })
        }
        await setOrderShippingMethod({ funeralId: id })
        refetch()
      } catch (e) {
        console.error("debug", e)
      }
    }, 400)
  }

  const handleOrderChange = async (index: any, event: any) => {
    const value = event.target.value
    const regex = /^[0-9\b]+$/
    if (!regex.test(value)) return
    if (updatingTimeout.current) {
      clearTimeout(updatingTimeout.current)
    }
    setOrderQuantity((prev: any) => ({
      ...prev,
      [index]: value
    }))
    updatingTimeout.current = setTimeout(async () => {
      const adjustId: number = Number(index)
      const adjustQuantity: number = Number(value)
      try {
        if (adjustQuantity > 0) {
          await adjustOrderLine({
            adjustId,
            adjustQuantity,
            funeralId: id
          })
        } else {
          await removeOrderLine({
            adjustId,
            funeralId: id
          })
        }
        await setOrderShippingMethod({ funeralId: id })
        refetch()
      } catch (e) {
        console.error("debug", e)
      }
    }, 400)
  }

  if (isLoggedIn) {
    if (!isLoading && lines != undefined && Object.keys(orderQuantity).length > 0) {
      const { subTotal, subTotalWithTax, totalWithTax, totalQuantity, shipping } = activeOrder
      return (
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{ mt: 5, mb: 1, tb: 1 }} component={"p"} className="sectionTitle">
                <b>Cart</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ mt: 5, mb: 1, tb: 1, mr: 1, float: "right" }} component={"div"} className="sectionTitle">
                <Avatar className={styles.orderAvatar}>{totalQuantity}</Avatar>
              </Typography>
            </Grid>
          </Grid>
          <Typography component={"div"} variant={"body2"} className="hr"></Typography>
          <br />
          <Grid>
            {lines.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <Grid sx={{ mb: 2, mt: 2 }}>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="15%">
                            <Box
                              component="img"
                              sx={{
                                height: 50,
                                width: 50
                              }}
                              src={item.featuredAsset.preview}
                            />
                          </td>
                          <td width="50%">
                            <Typography className={styles.productName}>{item.productVariant.name}</Typography>
                          </td>
                          <td width="20%">
                            <Paper className={styles.numberGroup} component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
                              <IconButton sx={{ py: "5px", px: "3px", borderRadius: "0px", fontFamily: 'Kumbh Sans', fontSize: '14px' }} onClick={(e:any) => handleMinus(item.id)}>
                                -
                              </IconButton>
                              <InputBase sx={{ ml: 0, flex: 1, backgroundColor: "#f8f4f0", fontFamily: 'Kumbh Sans', fontSize: '14px' }} type="number " placeholder="Search Google Maps" inputProps={{ min: 0, style: { textAlign: "center" } }} value={orderQuantity[item.id]} onChange={(e: any) => handleOrderChange(item.id, e)} />
                              <IconButton sx={{ py: "5px", px: "3px", borderRadius: "0px", fontFamily: 'Kumbh Sans', fontSize: '14px' }} onClick={(e:any) => handlePlus(item.id)}>
                                +
                              </IconButton>
                            </Paper>
                          </td>
                          <td width="15%">
                            <Typography className={styles.productPrice} align="right">
                              ${(item.productVariant.price / 100).toFixed(2)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Typography component={"div"} variant={"body2"} className="hr"></Typography>
                </div>
              )
            })}
          </Grid>
          <Grid>
            <Grid sx={{ mb: 2, mt: 2 }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productName} align="left">
                        Subtotal
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${(subTotal / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productName} align="left">
                        GST(8%)
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${((subTotalWithTax - subTotal) / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productName} align="left">
                        Delivery
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${(shipping / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
          </Grid>
          <Grid>
            <Grid sx={{ mb: 2, mt: 2 }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%">
                      <Typography className={styles.productName} align="left">
                        Total
                      </Typography>
                    </td>
                    <td width="50%">
                      <Typography className={styles.productPrice} align="right">
                        ${((subTotalWithTax + shipping) / 100).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            {(() => {
              if (!isProductPage) {
                return (
                  <>
                    <Link to="../checkout" className="link" relative="path" style={{ textDecoration: "none" }}>
                      <Button sx={{ mb: 2 }} variant="outlined" className={styles.btnCheckout} fullWidth>
                        Continue to Checkout
                      </Button>
                    </Link>
                  </>
                )
              } else {
                return (
                  <>
                    <Link to="../../../../checkout" className="link" relative="path" style={{ textDecoration: "none" }}>
                      <Button sx={{ mb: 2 }} variant="outlined" className={styles.btnCheckout} fullWidth>
                        Continue to Checkout
                      </Button>
                    </Link>
                  </>
                )
              }
            })()}
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Typography sx={{ mt: 5, mb: 1, tb: 1 }} component={"p"} className="sectionTitle">
            <b>Cart</b>
          </Typography>
          <Typography component={"div"} variant={"body2"} className="hr"></Typography>
          <br />
          <Typography sx={{ mt: 7, tb: 1 }} component={"p"} className={styles.productName}>
            Your cart is empty.
          </Typography>
          <Typography sx={{ mb: 1, tb: 1 }} component={"p"} className={styles.productName}>
            Send a gift.
          </Typography>
        </>
      )
    }
  } else {
    return (
      <>
        <Typography sx={{ mt: 5, mb: 1, tb: 1 }} component={"p"} className="sectionTitle">
          <b>Cart</b>
        </Typography>
        { screenWidth > 550 && <Typography  component={'div'} variant={'body2'} className="hr"></Typography>}
        <br />
        <Typography sx={{ mt: screenWidth > 550 ? 7 : 0, mb: 1, tb: 1 }} component={"p"} className={styles.productName}>
          Login to send a gift
        </Typography>
        <Button className={styles.loginButton} sx={{ display: { xs: "none", sm: "block" } }} onClick={handleOpen} style={{ marginRight: "2%" }}>
          Login
        </Button>
        <Modal open={user.toggleLoginModal} onClose={handleClose}>
          <LoginModal handleClose={handleClose} />
        </Modal>
      </>
    )
  }
}

export default OrderList
