import { useState, useEffect } from "react"
import { useAppSelector } from "../../app/hooks"
import { Link, useLocation } from "react-router-dom"
import { Tabs, Tab, Hidden, Grid, Box } from "@mui/material"
import { getUserState } from "../../features/user/userSlice"
import styles from "./Navbar.module.css"
import { getFuneralState } from "../../features/funeral/funeralSlice"

function ListMenu() {
  const location = useLocation()
  const [value, setValue] = useState(0)
  const user = useAppSelector(getUserState)
  const selectedFuneral = useAppSelector(getFuneralState)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [toggleDropdown, setDropdown] = useState(false)
  const handleDropdown = (event: React.SyntheticEvent) => {
    setDropdown(!toggleDropdown)
  }

  const [isGiftsEnabled, setIsGiftsEnabled] = useState<number>(0)
  const [isGalleryEnabled, setIsGalleryEnabled] = useState<number>(0)

  useEffect(() => {
    updateTab()
  }, [location.pathname, user.funeral.isRFM])

  useEffect(() => {
    setIsGiftsEnabled(selectedFuneral.details.showGifts)
    setIsGalleryEnabled(selectedFuneral.details.showGallery)
  }, [selectedFuneral])

  const updateTab = () => {
    let path = location.pathname
    if (path.includes("/about")) {
      setValue(0)
    }
    if (path.includes("/guestlist")) {
      setValue(4)
    }
    if (path.includes("/obituary")) {
      setValue(1)
    }
    if (path.includes("/event") || path.includes("/survivedby")) {
      setValue(2)
    }
    // if (path.includes("/donation")) {
    //   setValue(3)
    // }
    if (path.includes("/gallery")) {
      setValue(3)
    }

    if (path.includes("/messages")) {
      setValue(4)
    }
    if (path.includes("/messages") && user.funeral.isRFM) {
      setValue(user.funeral.isRFM ? 6 : 5)
    }

    if (path.includes("/give")) {
      setValue(5)
    }
    // if (path.includes("/give") || path.includes("/checkout")) {
    if (path.includes("/checkout")) {
      setValue(user.funeral.isRFM ? 7 : 6)
    }
    if (path.includes("/profile")) {
      setValue(user.funeral.isRFM ? 8 : 7)
    }
    if (path.includes("/events") && user.funeral.isRFM) {
      setValue(user.funeral.isRFM ? 5 : 4)
    }
    if (path.includes("/rfm/guestlist") || path.includes("/rfm/event")) {
      if (user.funeral.isRFM) {
        setValue(5)
      }
    }
    if (path.includes("/rfm/approval")) {
      setValue(4)
    }
  }

  return (
    <div>
      <Hidden smDown>
        <Tabs className={styles.divtabs} value={value} onChange={handleChange} aria-label="nav tabs" variant="scrollable" sx={{ "& .MuiTabs-indicator": { backgroundColor: "transparent" }, "& .Mui-selected": { color: 'var(--TLC-gray) !important', fontWeight: 700 } }}>
          <Tab label="About" disableRipple component={Link} to={"./about"} />
          <Tab label="Obituary" disableRipple component={Link} to={"./obituary"} />
          <Tab label="Services" disableRipple component={Link} to={"./events"} />
          {
            isGalleryEnabled &&
            <Tab label="Gallery" disableRipple component={Link} to={"./gallery"} />
          }
          <Tab label="Hearfelt Messages" disableRipple component={Link} to={"./messages"} />
          {
            isGiftsEnabled &&
            <Tab label="Gifts" disableRipple component={Link} to={"./give"} />
          }
        </Tabs>
      </Hidden>
      <Hidden smUp>
        <Grid container item>
          <Grid item sx={{ width: "100%" }}>
            <Box className={styles.divtabs} aria-label="nav tabs">
              <Tab className={styles.navTab} label="About" component={Link} to={"./about"} />
              <Tab className={styles.navTab} label="Obituary" component={Link} to={"./obituary"} />
            </Box>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Box className={styles.divtabs} aria-label="nav tabs">
              {/* <Tab className={styles.navTab} label="Survived By" component={Link} to={"./survivedby"} /> */}
              <Tab className={styles.navTab} label="Services" component={Link} to={"./events"} />
              {
                isGalleryEnabled ?
                  <Tab className={styles.navTab} label="Gallery" component={Link} to={"./gallery"} />
                  :
                  isGiftsEnabled ? <Tab className={styles.navTab} label="Gifts" component={Link} to={"./give"} /> : <Tab className={styles.navTab} label="Hearfelt Messages" component={Link} to={"./messages"} />
              }
            </Box>
          </Grid>
          {user.funeral.isRFM && (
            <>
              <Grid item sx={{ width: "100%" }}>
                <Box className={styles.divtabs} aria-label="nav tabs">
                  {
                    !isGalleryEnabled && isGiftsEnabled ? <></> : <Tab className={styles.navTab} label="Gifts" component={Link} to={"./give"} />
                  }
                  {
                    isGalleryEnabled && isGiftsEnabled ?
                      <Tab className={styles.navTab} label="Hearfelt Messages" component={Link} to={"./messages"} /> : <></>
                  }
                </Box>
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Box className={styles.divtabs} aria-label="nav tabs">
                  <Tab className={styles.navTab} label="Approval" component={Link} to={"./rfm/approval"} />
                  <Tab className={styles.navTab} label="Profile" component={Link} to={"./profile"} onClick={handleDropdown} />
                </Box>
              </Grid>
            </>
          )}
          {!user.funeral.isRFM && (
            <>
              <Grid item sx={{ width: "100%" }}>
                <Box className={styles.divtabs} aria-label="nav tabs">
                  {
                    !isGalleryEnabled && isGiftsEnabled ? <></> : isGiftsEnabled ? <Tab className={styles.navTab} label="Gifts" component={Link} to={"./give"} /> : <></>
                  }

                  {
                    isGalleryEnabled && isGiftsEnabled ?
                      <Tab className={styles.navTab} label="Hearfelt Messages" component={Link} to={"./messages"} /> : <></>
                  }
                </Box>
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Box className={styles.divtabs} aria-label="nav tabs">
                  <Tab className={styles.navTab} label="Profile" component={Link} to={"./profile"} onClick={handleDropdown} />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Hidden>
    </div>
  )
}

export default ListMenu
