import { useParams, useLocation, useNavigate } from "react-router-dom"
import { Box, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import styles from "./WebviewTitle.module.css"
import WebviewShare from "../share/WebviewShare"
import Profile from "../../profile/Profile"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { getUserState } from "../../../features/user/userSlice"
import { getFuneralState, setIsEdit } from "../../../features/funeral/funeralSlice"
import { getRfmEditState, reset, resetEvent, setSelectedEvent } from '../../../features/rfmEdit/rfmEditSlice'
import { useUpdateFuneralMutation, useUpdateRfmMutation, useUpdatePaynowMutation, useDeleteEventMutation, useDeleteObituaryMutation } from '../../../features/rfmEdit/rfmEditRestApi'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { formatDate } from "../../../constants"
import Toast from '../../base/Toast/Toast'
import Button from '../../base/Button/Button'
import EditIcon from '../../base/Icons/EditIcon'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export function WebviewTitle() {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userState = useAppSelector(getUserState)
  const funeralState = useAppSelector(getFuneralState)
  const { isRFM } = userState.funeral
  const rfmEditState = useAppSelector(getRfmEditState)
  const { toSave, note, obituary, payNow, survivedBy, selectedEvent, executorRfm } = rfmEditState
  const { events } = funeralState
  const [loading, setLoading] = useState(false)
  const [buttonType, setButtonType] = useState<any>('edit')
  const [toast, setToast] = useState({
    message: '',
    open: false,
    type: 'success',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [openEventDialog, setEventDialog] = useState(false)
  const [openObituaryDialog, setObituaryDialog] = useState(false)
  const [openMessageDialog, setMessageDialog] = useState(false)
  const funeral = useAppSelector(getFuneralState)
  const isEdit = funeral.isEdit
  const [updateFuneral] = useUpdateFuneralMutation()
  const [updatePaynow] = useUpdatePaynowMutation()
  const [updateRfm] = useUpdateRfmMutation()
  const [deleteEvent] = useDeleteEventMutation()
  const [deleteObituary] = useDeleteObituaryMutation()

  const avatarProps = {
    Name: funeral.details.lastName,
    LifeTime: formatDate(funeral.details.dob, "DD MMM YYYY") + " - " + formatDate(funeral.details.dod, "DD MMM YYYY"),
    AltName: funeral.details.lastName,
    ImgUrl: funeral.details.profile_image,
    Height: 138,
    Width: 138
  }
  useEffect(() => {
    if (location.pathname.includes('/events')) {
      setButtonType('guest')
    }
    else if (location.pathname.includes('/obituary')) {
      setButtonType('obituary')
    }
    else if (location.pathname.includes('/give') || location.pathname.includes('/profile') || location.pathname.includes('/approval') || location.pathname.includes('/messages') || location.pathname.includes('/rfm') || location.pathname.includes('/checkout')) {
      setButtonType('')
    }
    else {
      setButtonType('edit')
    }
  }, [location])

  const handleButtonClick = async () => {
    if (isEdit) {
      if (toSave) {
        await handleSave()
      } else {
        dispatch(setIsEdit(!isEdit))
        dispatch(reset())
      }
    } else {
      //Means go to edit
      setObituaryDialog(false)
      dispatch(setIsEdit(!isEdit))
    }
  }

  const handleSave = async () => {
    setLoading(true)
    let rv
    const formData = new FormData()
    try {
      if (toSave === 'note') {
        let body = {
          funeralId: id,
          update: { note: note }
        }
        formData.append('body', JSON.stringify(body));
        rv = await updateFuneral(formData).unwrap()
      }
      if (toSave === 'obituary') {
        let body = {
          funeralId: id,
          fileKey: 'obituary_image',
          update: { obituary: obituary.message }
        }
        formData.append('file', obituary.file);
        formData.append('body', JSON.stringify(body));
        rv = await updateFuneral(formData).unwrap()
      }
      if (toSave === 'payNow') {
        let body = {
          funeralId: id,
          fileKey: 'paynow_image',
          update: { bankDetails: payNow.details }
        }
        formData.append('file', payNow.file)
        formData.append('body', JSON.stringify(body))
        rv = await updatePaynow(formData).unwrap()
      }
      if (toSave === 'survivedBy') {
        let toUpdate = []
        let fileIdentifier = []
        for (var rfm of survivedBy) {
          const { file, ...rest } = rfm
          toUpdate.push(rest)
          if (file) {
            formData.append('file', file)
            fileIdentifier.push(rfm.id)
          }
        }
        let body: {
          funeralId: number | string | undefined
          toUpdate: any[]
          fileIdentifier: any[]
          executorRfm?: number | string
        } = {
          funeralId: id,
          toUpdate: toUpdate,
          fileIdentifier: fileIdentifier
        }
        if (executorRfm) {
          body.executorRfm = executorRfm.id
        }
        formData.append('body', JSON.stringify(body))
        rv = await updateRfm(formData).unwrap()
      }

      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        dispatch(setIsEdit(!isEdit))
        dispatch(reset())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleViewList = async () => {
    navigate('./rfm/guestlist')
  }

  const handleCreateEvent = async () => {
    dispatch(resetEvent(null))
    navigate('./rfm/event/create')
  }

  const handleEventEdit = async () => {
    let found = events.find((item) => {
      return item.id === Number(selectedEvent)
    })

    //dispatch(setEvent(found))

    if (found) {
      navigate(`./rfm/event/edit/${selectedEvent}`)
      setEventDialog(false)
    }

  }

  const handleDeleteEvent = async () => {
    try {
      let body = {
        eventId: selectedEvent,
        funeralId: id
      }
      let rv = await deleteEvent(body).unwrap()
      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        dispatch(setSelectedEvent(''))
        setEventDialog(false)
      }
    } catch (e: any) {
      setToast({
        ...toast,
        open: true,
        message: "Error, please try again or contact our support.",
        type: 'error'
      })
    }
  }

  const handleDeleteObituary = async () => {
    try {
      let rv = await deleteObituary({ funeralId: id }).unwrap()
      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        setObituaryDialog(false)
      }
    } catch (e) {
      setToast({
        ...toast,
        open: true,
        message: 'Error deleting obituary!',
        type: 'error'
      })
    }
  }

  const handleToggleObituary = async () => {
    try {
      let body = {
        funeralId: id,
        update: { showObituary: funeralState.details.showObituary ? 0 : 1 }
      }
      const formData = new FormData()
      formData.append('body', JSON.stringify(body));
      let rv = await updateFuneral(formData).unwrap()
      if (rv) {
        setToast({
          ...toast,
          open: true,
          message: rv.message,
          type: 'success'
        })
        setObituaryDialog(false)
      }
    } catch (e) {
      setToast({
        ...toast,
        open: true,
        message: 'Error toggling obituary!',
        type: 'error'
      })
    }
  }

  return (
    <Grid container className={styles.wvDivTitle} sx={{ minHeight: 150 }}>
      <Toast {...toast} />
      <Grid item xs={12} md={6}>
        <Profile {...avatarProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <WebviewShare />

        <Typography className={styles.wvDivShare} component={'div'} sx={{ mb: { xs: 2, sm: 0 }, textAlign: { xs: 'left', sm: 'right' } }}>
          {isRFM && (
            buttonType === 'guest' ? (
              <Box className={styles.buttonRow} >
                <Button className="btnSecondary" sx={{ width: { xs: '100%', sm: 230 }, marginBottom: { xs: '5px', sm: 0 } }} onClick={handleViewList} >
                  View Master Guest List
                </Button>
                <Button className="btnPrimary" sx={{ width: { xs: '100%', sm: 230 }, marginBottom: { xs: '5px', sm: 0 } }} onClick={handleCreateEvent} >
                  Create Event
                </Button>
                {
                  !selectedEvent ? (
                    <Button className="btnDisabled" disabled loading={loading} sx={{ width: { xs: '100%', sm: 230 }, marginBottom: { xs: 2, sm: 0 } }} onClick={handleEventEdit} >
                      <EditOutlinedIcon sx={{ fontSize: "22px", mr: '7px' }} />
                      <span className={styles.buttonTxt}>Edit</span>
                    </Button>
                  ) : (
                    <Button className="btnSecondary" loading={loading} sx={{ width: { xs: '100%', sm: 230 }, marginBottom: { xs: 2, sm: 0 } }} onClick={() => setEventDialog(true)} >
                      <EditOutlinedIcon sx={{ fontSize: "22px", mr: '7px' }} />
                      {
                        isEdit ? <span className={styles.buttonTxt}>Save</span> : <span className={styles.buttonTxt}>Edit</span>
                      }
                    </Button>
                  )
                }

                <Dialog
                  open={openEventDialog}
                  onClose={() => setEventDialog(false)}
                  aria-labelledby="responsive-dialog-title"
                  sx={{ p: 2 }}
                >
                  <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Button className='btnPrimary' onClick={handleEventEdit} sx={{ width: { xs: '100%', sm: '340px' } }}>Edit Event</Button>
                      <Box sx={{ my: 2 }}></Box>
                      <Button className='btnSecondary' onClick={handleDeleteEvent} sx={{ width: { xs: '100%', sm: '340px' } }}>Delete Event</Button>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            ) : buttonType === 'edit' ? (
              <Button className="btnSecondary" loading={loading} sx={{ width: { xs: '100%', sm: 230 } }} onClick={handleButtonClick} >
                <EditOutlinedIcon sx={{ fontSize: "22px", mr: '7px' }} />
                {
                  isEdit ?
                    <span className={styles.buttonTxt}>Save</span>
                    :
                    <span className={styles.buttonTxt}>Edit</span>
                }
              </Button>
            ) : buttonType === 'obituary' ? (
              <>
                {
                  isEdit ? (
                    <Button className="btnSecondary" loading={loading} sx={{ width: { xs: '100%', sm: 230 } }} onClick={handleButtonClick} >
                      <span className={styles.buttonTxt}>Save</span>
                      <EditIcon />
                    </Button>
                  ) : (
                    <Button className="btnSecondary" loading={loading} sx={{ width: { xs: '100%', sm: 230 }, '&:hover': { '& svg path': { fill: 'white' } } }} onClick={() => setObituaryDialog(true)} >
                      <EditOutlinedIcon sx={{ fontSize: "22px", mr: '7px' }} />
                      <span>Edit</span>
                    </Button>
                  )
                }
                <Dialog
                  open={openObituaryDialog}
                  onClose={() => setObituaryDialog(false)}
                  aria-labelledby="responsive-dialog-title"
                  sx={{ p: 2 }}
                >
                  <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Button className='btnPrimary' onClick={handleButtonClick} sx={{ width: { xs: '100%', sm: '340px' } }}>Edit Obituary</Button>
                      <Box sx={{ my: 2 }}></Box>
                      <Button className='btnSecondary' onClick={handleDeleteObituary} sx={{ width: { xs: '100%', sm: '340px' } }}>Delete Obituary</Button>
                      <Box sx={{ my: 2 }}></Box>
                      {
                        funeralState.details.showObituary ? (
                          <Button className='btnSecondary' onClick={handleToggleObituary} sx={{ width: { xs: '100%', sm: '340px' } }}>Hide Obituary</Button>
                        ) : (
                          <Button className='btnSecondary' onClick={handleToggleObituary} sx={{ width: { xs: '100%', sm: '340px' } }}>Show Obituary</Button>
                        )
                      }
                    </Box>
                  </DialogContent>
                </Dialog>
              </>

            ) : <></>
          )
          }
        </Typography>
      </Grid>
    </Grid>
  )
}
