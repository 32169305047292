import React from 'react'

function EmailIcon() {
    return (
        <svg width="37" height="26" viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.821167 3.68855C0.873014 3.5006 0.91838 3.30988 0.978559 3.12564C1.39889 1.85169 2.58396 0.989735 3.95605 0.983254C6.13083 0.973996 8.30562 0.980477 10.4813 0.980477C18.013 0.980477 25.5438 0.979551 33.0754 0.980477C34.6586 0.980477 35.8807 1.8665 36.2566 3.3034C36.3344 3.60152 36.3686 3.91908 36.3686 4.22831C36.3751 10.3944 36.3751 16.5604 36.3723 22.7274C36.3714 24.6365 35.0252 25.9771 33.1115 25.9771C23.4634 25.979 13.8143 25.978 4.16621 25.978C2.42749 25.978 1.29519 25.0892 0.87394 23.3968C0.86283 23.3533 0.839684 23.3126 0.822093 23.27C0.821167 16.7428 0.821167 10.2157 0.821167 3.68855ZM4.4634 3.08212C4.56247 3.18952 4.62728 3.26544 4.69764 3.3358C9.01852 7.67057 13.3403 12.0053 17.6621 16.3392C18.2565 16.9354 18.9435 16.9308 19.5462 16.3262C23.868 11.9924 28.1898 7.6576 32.5107 3.32284C32.5783 3.25433 32.6375 3.17656 32.719 3.08212C23.2884 3.08212 13.9004 3.08212 4.4634 3.08212ZM4.43841 23.8634C13.9069 23.8634 23.2977 23.8634 32.756 23.8634C29.7498 20.8832 26.7927 17.9529 23.8208 15.006C23.7532 15.0708 23.6606 15.155 23.5727 15.243C22.7061 16.1114 21.8441 16.9835 20.9738 17.8492C19.6101 19.2056 17.5843 19.2037 16.2224 17.8492C15.6975 17.327 15.1781 16.7993 14.655 16.2753C14.2236 15.8429 13.7912 15.4124 13.3783 15.0004C10.4017 17.951 7.44552 20.8822 4.43841 23.8634ZM2.92374 4.64216C2.92374 10.512 2.92374 16.4466 2.92374 22.3117C5.87716 19.3861 8.85557 16.4364 11.8395 13.4802C8.85557 10.5212 5.87808 7.57058 2.92374 4.64216ZM34.2642 22.3293C34.2642 16.4401 34.2642 10.5064 34.2642 4.6866C31.3284 7.5928 28.35 10.5416 25.3558 13.5052C28.3314 16.453 31.3052 19.3981 34.2642 22.3293Z" fill="#545454" />
        </svg>
    )
}

export default EmailIcon