import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useState, useEffect } from 'react';
import CustomTextField from '../../base/Textfield/CustomTextField';
import styles from './WebviewNotes.module.css';
import { useGetImageUrlMutation } from '../../../features/base/baseRestApi';
import { getFuneralState } from '../../../features/funeral/funeralSlice';
import { setEditNote } from '../../../features/rfmEdit/rfmEditSlice';
import { debounce } from '../../../constants';


const WebviewNotes: React.FC = () => {
  const dispatch = useAppDispatch()
  const funeralState = useAppSelector(getFuneralState)
  const { obituary_image, note } = funeralState.details
  const [imageUrl, setImageUrl] = useState("")
  const isEdit = funeralState.isEdit
  const [noteEdit, setNoteEdit] = useState<string>('')
  const [getImageUrl] = useGetImageUrlMutation()
  
  useEffect(() => {
    if(obituary_image) {
      getURL(obituary_image)
    }
  }, [obituary_image])

  useEffect(() => {
    if(isEdit) {
      let value = note ? note : ''
      setNoteEdit(value)
    }
  }, [isEdit, note])

  const storeEdit = () => {
    if(isEdit) {
    dispatch(setEditNote({notes: noteEdit, toSave: 'note'}))
    }
  }

  const debounceHandler = useCallback(
    debounce(storeEdit())
  , []);

  const getURL = async (filename: string) => {
    try {
      let rv = await getImageUrl({ image: filename }).unwrap()
      setImageUrl(rv.imageUrl)
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setNoteEdit(value.replace(/\n\r?/g, '<br />'))
    debounceHandler()
  }

  return (
    <div>
      {
        obituary_image ? 
          <img src={imageUrl} className={styles.wvNotesImg} alt='obituary_image'/>
        : <img src={'/images/banner_example.png'} className={styles.wvNotesImg} alt='obituary_image'/>
      }
 
      {
        isEdit ? (
        <Box sx={{minHeight: 100}}>
          <CustomTextField className='text-input' fullWidth sx={{ mb:4}} multiline rows={2} value={noteEdit?.replace(/<br \/>?/g, '\n')} onChange={handleChange} id='content' name='content' size='small'/>
        </Box>) 
        : (
          <Box sx={{minHeight: 50}}>
            <Typography sx={{ pt:1,tb:1 }} component={'div'} className={styles.sectionPara} >
            <div dangerouslySetInnerHTML={{ __html: note }}></div>
            </Typography>  
          </Box> 
        )
      }
          
    </div>
  );
}

export default WebviewNotes