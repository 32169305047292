import { gqlApi } from "../../api/graphql"
import { gql } from "graphql-request"
import { setProducts } from "./productSlice"

export const productGqlApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        body: gql`
          query {
            products(options: {}) {
              items {
                id
                name
                description
                slug
                variants {
                  id
                  productId
                  name
                  price
                  priceWithTax
                  assets {
                    id
                    source
                  }
                }
                facetValues {
                  facet {
                    name
                  }
                  name
                  code
                }
                assets {
                  name
                  source
                }
              }
              totalItems
            }
          }
        `
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          let { data } = await queryFulfilled
          dispatch(setProducts(data.products))
        } catch (error) {}
      }
    }),
    getProduct: builder.query({
      query: (data) => ({
        body: gql`
          query {
            product(slug: "${data.slug}"){
              id
              name
              slug
              description
              assets {
                id
                source
              }
              variants {
                id
                sku
                name
                price
                assets {
                  id
                  source
                }
              }
            }
          }
        `
      })
    })
  })
})

export const { useGetProductsQuery, useGetProductQuery } = productGqlApi
