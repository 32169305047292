import React, { useState } from 'react'
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwForm from './ForgotPwForm';

interface loginModalProps {
  children?: React.ReactNode;
  eventId?: string | null;
  handleClose: () => void;
}

const LoginModal = React.forwardRef<'',loginModalProps>((props,ref) => {
  const {
    children,
    handleClose,
    eventId = null
  } = props;

  const [formState, setFormState] = useState(1)
  
  const handleFormState = (state: number) => {
    setFormState(state)
  }

  if(formState === 1) {
    return (
      <LoginForm eventId={eventId} onClick={handleFormState} handleClose={handleClose}/>
    )
  }
  if(formState === 2) {
    return (
      <RegisterForm eventId={eventId} onClick={handleFormState} handleClose={handleClose}/>
    )
  }
  if(formState === 3) {
    return (
      <ForgotPwForm onClick={handleFormState} handleClose={handleClose}/>
    )
  }
  return <></>
})

export default LoginModal