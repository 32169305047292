import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '../../components/base/Button/Button';
import Grid from '@mui/material/Grid';
import { InputLabel } from '@mui/material';
import CustomTextField from '../../components/base/Textfield/CustomTextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Toast from '../../components/base/Toast/Toast';
import styles from './ResetPw.module.css'
import { validatePw } from '../../constants';
import { useResetPaswordMutation } from '../../features/auth/authGqlApi';

interface CustomFailMsgProps {
  error: any
  message: string
}

const CustomFailMsg: React.FC<CustomFailMsgProps> = (props) => {
  let { error, message } = props
  if (error) {
    return <p className={styles.error}>{message}</p>
  } else {
    return <p style={{ height: 10 }}></p>
  }
}

function ResetPw() {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const queryUrl = useQuery()
  const [completion, setCompletion] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCfmPassword, setShowCfmPassword] = useState(false);
  const [resetForm, setResetForm] = useState({
    newPassword: '',
    cfmPassword: ''
  })
  const [toast, setToast] = useState({
    open: false,
    message: '',
    type: 'success',
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const [resetError, setResetError] = useState({
    error: false,
    message: ''
  })
  const [resetPw] = useResetPaswordMutation()

  const handleTogglePassword = (type: string) => {
    if (type === 'newPassword') {
      setShowNewPassword(!showNewPassword)
    } else {
      setShowCfmPassword(!showCfmPassword)
    }
  }

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completion]);

  const validate = (data: any) => {
    let password = data.newPassword
    let cfmPassword = data.cfmPassword

    if (!password || !cfmPassword) {
      setResetError({
        error: true,
        message: "Please input all the fields"
      })
      return false
    }
    if (password) {
      let rv = validatePw(password)
      if (!rv) {
        setResetError({
          error: true,
          message: "Password need to fulfill the above requirements!"
        })
        return false
      }
    }
    if (password !== cfmPassword) {
      setResetError({
        error: true,
        message: "New password and confirm password is not the same"
      })
      return false
    }
    setResetError({
      error: false,
      message: ''
    })
    return true
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setResetForm({
      ...resetForm,
      [key]: value.trim()
    })

    //debounceHandler({key, value})
  }

  const handleSubmit = async () => {
    setLoading(true)
    const isValidated = validate(resetForm)

    try {
      if (isValidated) {
        let body = {
          token: queryUrl.get('token'),
          password: resetForm.newPassword
        }
        let rv = await resetPw(body).unwrap()
        if (rv.resetPassword.errorCode) {
          if (rv.resetPassword.errorCode === 'PASSWORD_RESET_TOKEN_INVALID_ERROR' || rv.resetPassword.errorCode === 'PASSWORD_RESET_TOKEN_EXPIRED_ERROR') {
            setToast({
              ...toast,
              open: true,
              message: "Reset password link is expired. Please reset again and use latest reset password link.",
              type: 'error'
            })
          } else {
            setToast({
              ...toast,
              open: true,
              message: rv.resetPassword.message,
              type: 'error'
            })
          }
        } else {
          /* setToast({
            ...toast,
            open: true,
            message: "Successfully reset password!",
            type: 'success'
          }) */
          setCompletion(true)
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log("error", e)
    }
  };

  if (completion) {
    return (
      <Box className={styles.container}>
        <Card className={styles.card} sx={{ width: { xs: '90%', md: '38%' } }}>
          <Typography className='text'>You have successfully reset your password. Please go back to login page to login.</Typography>
        </Card>
      </Box>
    )
  } else {
    return (
      <Box className={styles.container}>
        <Toast {...toast} />
        <Box>
          <img
            src={`/images/TLC-MAIN-LOGO.png`}
            alt="The Life Celebrant"
            loading="lazy"
          />
        </Box>
        <Card className={styles.card} sx={{ width: { xs: '90%', md: '38%' } }}>
          <div className={styles.login}>Forgot Password</div>
          <Box sx={{ mt: 4 }}>
            <InputLabel sx={{ mb: 1 }} htmlFor="newPassword">New Password</InputLabel>
            <CustomTextField id="newPassword" name="newPassword" placeholder='New Password' size="small" onChange={handleChange} fullWidth
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={() => handleTogglePassword('newPassword')}
                  >
                    {showNewPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={styles.input} />
            <Box sx={{ pl: 0.5, mb: 4 }} className={styles.pwhelper}>
              Password must include
              <ul>
                <li>at least 8 characters</li>
                <li>a lowercase letter</li>
                <li>one capital letter</li>
                <li>and one number</li>
              </ul>
            </Box>

            <InputLabel sx={{ mb: 1 }} htmlFor="cfmPassword">Confirm New Password</InputLabel>
            <CustomTextField sx={{ mb: 0 }} id="cfmPassword" name="cfmPassword" size="small" onChange={handleChange} fullWidth
              type={showCfmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={styles.icon}
                    aria-label="toggle password visibility"
                    onClick={() => handleTogglePassword('cfmPassword')}
                  >
                    {showCfmPassword ? <VisibilityOffIcon sx={{ color: 'var(--TLC-web-grey)' }} /> : <VisibilityIcon sx={{ color: 'var(--TLC-web-grey)' }} />}
                  </IconButton>
                )
              }} className={styles.input} />

            <CustomFailMsg error={resetError.error} message={resetError.message} />
            <Button loading={loading} fullWidth variant="contained" className="btnPrimary" onClick={handleSubmit}>Reset Password</Button>

            <Grid container>
              <Grid item xs={12}>

              </Grid>
            </Grid>
          </Box>
        </Card>
        <p className={styles.notice}> &#169; {new Date().getFullYear()} The Life Celebrant. All Rights Reserved. Powered by
          <a href="https://www.visiongroup.co/" target="_blank" rel="noopener noreferrer">VisionTech</a>.</p>
      </Box>
    )
  }
}

export default ResetPw