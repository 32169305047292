import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Pagination, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import SingleProduct from '../../components/product/singleProduct';
import OrderList from '../../components/order/OrderList';
import { useGetProductQuery } from '../../features/products/productGqlApi';
import { getUserState } from '../../features/user/userSlice';

function ProductPage() {
  const userState = useAppSelector(getUserState);
  const {isLoggedIn} = userState

  const isRfm = true;
  const {slug, productId} = useParams();
  const {data, isLoading} = useGetProductQuery({slug})

  if (!isLoading) {
    let productName;
    const variants = data.product.variants;
    for (let i = 0; i < variants.length; i++) {
        if (variants[i].id == productId) {
            productName = variants[i].name;
            break;
        }
    }
    return (
      <>
      <Typography component={'div'} variant={'body2'} className="hr"></Typography>
      <Grid container item>
        <Grid item xs={12} md={8.5}>
          <Box sx={{ pt: 2, px:{xs: 3, md: 0}}}>
            <Typography sx={{pt: 1, tb: 1}} component={"p"} className="sectionTitle"><b>Send Gifts</b></Typography>
            <Typography sx={{pt: 1, tb: 1, fontSize: '16px'}} variant="body1" component={"p"} >
              <Link to={`../give`} style={{ textDecoration: 'none', color: 'var(--TLC-gray)'}}>Give  </Link>
              &gt; {productName}
            </Typography>
            <SingleProduct slug={slug} productId={productId} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5}>
          {(() => {
            if (isLoggedIn) {
              return (
                <>
                <Box sx={{p: 3}}> 
                  <OrderList isProductPage={true} isLoggedIn={true}/>
                </Box>
                </>
              )
            } else {
              return (
                <>
                  <Box sx={{p:3}}>
                    <OrderList isProductPage={true} isLoggedIn={false}/>
                  </Box>
                </>
              )
            }
          })()}
        </Grid>
      </Grid>
      </>
    )
  } else {
    return null;
  }
}

export default ProductPage