import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice';
import authReducer from '../features/auth/authSlice'
import funeralReducer from '../features/funeral/funeralSlice'
import rfmEditReducer from '../features/rfmEdit/rfmEditSlice'
import condolenceMessageListReducer from '../features/webview/condolenceList/condolenceListSlice'
import dataRsvpReduce from '../features/rsvp/rsvpSlice'
import guestlistReducer from '../features/guestlist/guestlistSlice'
import { restApi } from '../api/restApi';
import { gqlApi } from '../api/graphql';
import productReducer from '../features/products/productSlice'
import orderReducer from '../features/order/orderSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    auth: authReducer,
    funeral: funeralReducer,
    condolenceMessageList: condolenceMessageListReducer,
    rsvp: dataRsvpReduce,
    product: productReducer,
    order: orderReducer,
    rfmEdit: rfmEditReducer,
    guestlist: guestlistReducer,
    [restApi.reducerPath]: restApi.reducer,
    [gqlApi.reducerPath]: gqlApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([restApi.middleware, gqlApi.middleware])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
