import React, { useState, useEffect, useCallback } from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import CustomTextField from "../base/Textfield/CustomTextField"
import InputAdornment from "@mui/material/InputAdornment"
import { SelectChangeEvent } from "@mui/material/Select"
import CustomSelect from "../base/Select/CustomSelect"
import MenuItem from "@mui/material/MenuItem"
import Button from "../base/Button/Button"
import styles from "./Guestlist.module.css"
import { useEditGuestMutation } from "../../features/guestlist/guestlistRestApi"
import Toast from "../base/Toast/Toast"
import { relationships, debounce, validateName, validateEmail, validateTel } from "../../constants"

interface EditGuestProps {
  selectedGuest: any
  handleClose: () => void
}

const EditGuest = React.forwardRef<"", EditGuestProps>((props, ref) => {
  const { selectedGuest, handleClose } = props
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    message: "",
    type: "",
    open: false
  })
  const [guest, setGuest] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    relationship: "-",
    phoneNumber: ""
  })
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false
  })
  const [editGuest] = useEditGuestMutation()

  useEffect(() => {
    setGuest(selectedGuest)
  }, [selectedGuest])

  const validateSingleField = (obj: { key: string; value: string }) => {
    const { key, value } = obj
    let rv
    if (key === "firstName" || key === "lastName") {
      rv = validateName(value)
    }
    if (key === "email") {
      rv = validateEmail(value)
    }
    if (key === "phoneNumber") {
      rv = validateTel(value)
    }
    if (!rv) {
      setErrors((prev) => ({
        ...prev,
        [key]: true
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        [key]: false
      }))
    }
  }
  const debounceHandler = useCallback(debounce(validateSingleField), [])
  const handleGuestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setGuest({
      ...guest,
      [key]: value
    })

    debounceHandler({ key, value })
  }
  const handleRelationshipChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event
    setGuest({
      ...guest,
      relationship: value
    })
  }
  const updateGuest = async () => {
    setLoading(true)
    const hasError = validateGuest()
    if (hasError) {
      setLoading(false)
      return
    }
    try {
      let body = {
        funeralId: selectedGuest.funeralId,
        old: selectedGuest,
        edit: guest
      }

      let rv = await editGuest(body).unwrap()

      if (rv) {
        setToast({
          message: rv.message,
          type: "success",
          open: true
        })
        setLoading(false)
        handleClose()
      }
    } catch (e: any) {
      setToast({
        message: e.toString(),
        type: "error",
        open: true
      })
      setLoading(false)
    }
  }
  const validateGuest = () => {
    let hasError = false
    for (const [key, value] of Object.entries(guest)) {
      switch (key) {
        case "firstName":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.firstName ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        case "lastName":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.lastName ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        case "email":
          if (!value) {
            setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            errors.email ? (hasError = true) : setErrors((prev) => ({ ...prev, [key]: false }))
          }
          break
        case "phoneNumber":
          if (value) {
            if (errors.phoneNumber) {
              hasError = true
            }
            setErrors((prev) => ({ ...prev, [key]: true }))
          }
          break
        default:
          break
      }
    }
    return hasError
  }

  return (
    <Card className={styles.card} sx={{ p: 4, width: { xs: "250px", lg: "365px" } }}>
      <Toast type={toast.type} message={toast.message} open={toast.open} />
      <Box>
        <Grid item xs={12} sx={{ height: "100px" }}>
          <InputLabel error={errors.email}>
            <span className={styles.mandatory}>Email</span>{" "}
          </InputLabel>
          <CustomTextField error={errors.email} helperText={errors.email ? "Requires a valid email" : ""} sx={{ width: { sm: "250px", lg: "365px" }, mb: 2 }} value={guest.email} onChange={handleGuestChange} id="email" name="email" placeholder="Email" size="small" className={styles.input} />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.firstName}>
              <span className={styles.mandatory}>First Name</span>
            </InputLabel>
            <CustomTextField
              error={errors.firstName}
              helperText={errors.firstName ? "Requires a valid name" : ""}
              sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
              value={guest.firstName}
              onChange={handleGuestChange}
              id="firstName"
              name="firstName"
              placeholder="First Name"
              size="small"
              className={styles.input}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.lastName}>
              <span className={styles.mandatory}>Last Name</span>
            </InputLabel>
            <CustomTextField
              error={errors.lastName}
              helperText={errors.lastName ? "Requires a valid name" : ""}
              sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
              value={guest.lastName}
              onChange={handleGuestChange}
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              size="small"
              className={styles.input}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel>Relationship</InputLabel>
            <CustomSelect
              className={styles.input}
              sx={{ width: { xs: "250px", lg: "365px" } }}
              MenuProps={{
                disableScrollLock: true
              }}
              size="small"
              labelId="action"
              value={guest.relationship}
              onChange={handleRelationshipChange}
            >
              {relationships.map((item) => (
                <MenuItem 
                  key={item} 
                  value={item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'var(--TLC-web-grey)',
                      '&:hover': {
                        backgroundColor: 'var(--TLC-web-grey)',
                      },
                    },
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>

          <Grid item xs={12} sx={{ height: "100px" }}>
            <InputLabel error={errors.phoneNumber}>Phone Number </InputLabel>
            <CustomTextField
              helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
              sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }}
              value={guest.phoneNumber}
              onChange={handleGuestChange}
              id="phoneNumber"
              name="phoneNumber"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>+65</span>
                  </InputAdornment>
                )
              }}
              className={styles.input}
            />
          </Grid>

          <Grid item xs={12} className={styles.isflex}>
            <Button className="btnSecondary" sx={{ width: { xs: "250px", lg: "365px" }, mb: 2 }} loading={loading} onClick={updateGuest}>
              Update Guest
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
})

export default EditGuest
