import { restApi } from '../../api/restApi'
import { setEvent } from './rfmEditSlice';

export const rfmEditRestApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    updateFuneral: builder.mutation({
      invalidatesTags: ['funeral'],
      query: (payload) => ({
        url: '/api/app-template/rfm/update',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
    updatePaynow: builder.mutation({
      invalidatesTags: ['funeral'],
      query: (payload) => ({
        url: '/api/app-template/rfm/update/paynow',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
    getMessages: builder.query({
      providesTags: [`messages`],
      query: (funneralId) => ({
        url: `/api/app-template/rfm/approval/messages/${funneralId}`,
        method: 'GET',
      })
    }),
    addEvent: builder.mutation({
      invalidatesTags: (result,  error, id) => {
        if(!error) {
          return ['getEvent','funeral']
        }else {
          return []
        }
      },
      query: (payload) => ({
        url: '/api/app-template/account/event',
        method: 'POST',
        body: payload,
      })
    }),
    getDownloadRsvp: builder.query({
      providesTags: [`messages`],
      query: (payload) => ({
        url: `/api/app-template/rfm/download/rsvp`,
        method: 'GET',
        params: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
    getEvent: builder.query({
      providesTags: ['getEvent'],
      query: (payload) => ({
        url: `/api/app-template/rfm/event`,
        method: 'GET',
        params: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEvent(data))
        } catch (error) {}
      },
    }),
    broadcast: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/broadcast',
        method: 'POST',
        body: payload,
      })
    }),
    updateRfm: builder.mutation({
      invalidatesTags: ['rfmlist'],
      query: (payload) => ({
        url: '/api/app-template/rfm/surviveBy/update',
        method: 'POST',
        body: payload,
      })
    }),
    deleteRfm: builder.mutation({
      invalidatesTags: ['rfmlist'],
      query: (payload) => ({
        url: '/api/app-template/rfm/surviveBy/delete',
        method: 'POST',
        body: payload,
      })
    }),
    updateMessage: builder.mutation({
      invalidatesTags: [`messages`],
      query: (payload) => ({
        url: '/api/app-template/approval/update',
        method: 'POST',
        body: payload,
      })
    }),
    deleteMessage: builder.mutation({
      invalidatesTags: [`messages`],
      query: (payload) => ({
        url: '/api/app-template/approval/delete',
        method: 'POST',
        body: payload,
      })
    }),
    deleteEvent: builder.mutation({
      invalidatesTags: [`funeral`],
      query: (payload) => ({
        url: '/api/app-template/rfm/event/delete',
        method: 'POST',
        body: payload,
      })
    }),
    addEventProducts: builder.mutation({
      query: (payload) => ({
        url: '/api/app-template/account/event/product',
        method: 'POST',
        body: payload,
      })
    }),
    deleteObituary: builder.mutation({
      invalidatesTags: [`funeral`],
      query: (payload) => ({
        url: '/api/app-template/rfm/obituary/delete',
        method: 'POST',
        body: payload,
      })
    }),
    getBroadcast: builder.query({
      providesTags: ['broadcast'],
      query: (funeralId) => ({
        url: `/api/app-template/broadcast/${funeralId}`,
        method: 'GET',
      })
    }),
    updateBroadcast: builder.mutation({
      invalidatesTags: (result,  error, id) => {
        if(!error) {
          return ['broadcast']
        }else {
          return []
        }
      },
      query: (payload) => ({
        url: '/api/app-template/broadcast/update',
        method: 'POST',
        body: payload,
      })
    }),
  })
})

export const { 
  useAddEventProductsMutation, 
  useUpdateFuneralMutation, 
  useGetMessagesQuery, 
  useAddEventMutation, 
  useGetDownloadRsvpQuery, 
  useGetEventQuery, 
  useBroadcastMutation, 
  useUpdateRfmMutation, 
  useDeleteRfmMutation, 
  useUpdateMessageMutation, 
  useUpdatePaynowMutation, 
  useDeleteMessageMutation, 
  useDeleteEventMutation,
  useDeleteObituaryMutation,
  useGetBroadcastQuery,
  useUpdateBroadcastMutation
} = rfmEditRestApi