import React, { useState, useEffect, useCallback } from "react"
import { FormControlLabel, Box, Grid, Checkbox, InputLabel, Select, OutlinedInput, Typography, Button, MenuItem, InputAdornment, ListItem, ListItemButton, ListItemText, Collapse } from "@mui/material"
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import CustomTextField from "../base/Textfield/CustomTextField"
import { CheckedIcon, Icon } from "../base/Checkbox/CustomCheckbox"
import SquareIcon from "../base/Icons/SquareIcon"
import SquareCheckedIcon from "../base/Icons/SquareCheckedIcon"
import styles from "./Checkout.module.css"
import { styled } from "@mui/material/styles"
import { getDateInRange, getDate, validatePostal, padZeros, validateCountryCode } from "../../constants"
import { useAppSelector } from "../../app/hooks"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { getUserState } from "../../features/user/userSlice"
import { debounce, validateName, validateEmail, validateTel } from "../../constants"
import { useSetCustomerForOrderMutation, useSetOrderShippingAddressMutation } from "../../features/checkout/checkoutGqpApi"
import { useGetActiveCustomerQuery } from "../../features/auth/authGqlApi"
import { useGetRfmlistQuery } from "../../features/funeral/funeralRestApi"
import { useGetOrderQuery, useUpdateCustomIdMutation } from "../../features/order/orderGqlApi"
import { useGetExecutorRfmQuery } from '../../features/base/baseRestApi'
import { useParams } from "react-router-dom"
import Toast from '../base/Toast/Toast'

interface OrderDetailProps {
  handleStepper: (step: number) => void
}

const CustomSelect = styled(Select)({
  "&": {
    //   background: 'transparent'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)",
    borderRadius: "3px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid var(--TLC-web-grey)"
  }
})

type details = {
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  message?: string
  event: string
  eventDate: string
  rfm: string
  addressLine1: string
  addressLine2?: string
  addressPostcode: string
  countryCode: string
}

const OrderDetail: React.FC<OrderDetailProps> = (props) => {
  const { id } = useParams()
  const userState = useAppSelector(getUserState)
  const mainRFM = useGetExecutorRfmQuery({ funeralId: id }, { refetchOnMountOrArgChange: true })
  const [RFM, setRfm] = useState<any>()
  const [toast, setToast] = useState({
    type: 'success',
    open: false,
    message: "",
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })
  const getActiveCustomer = useGetActiveCustomerQuery("")
  const activeCustomerData = getActiveCustomer?.data
  //const activeCustomerIsLoading = getActiveCustomer?.isLoading
  const activeCustomerRefetch = getActiveCustomer?.refetch
  //const activeCustomer = activeCustomerData?.activeCustomer

  const [setCustomerForOrder] = useSetCustomerForOrderMutation()
  const [setOrderShippingAddress] = useSetOrderShippingAddressMutation()
  const [updateCustomId] = useUpdateCustomIdMutation()

  const [sendToLocation, setSendToLocation] = useState(true)

  // const [details, setDetails] = useState<details>({
  const [details, setDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    event: "",
    eventDate: "",
    rfm: "",
    addressLine1: "",
    addressLine2: "",
    addressPostcode: "",
    countryCode: "",
  })

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    countryCode: false,
  })

  const [failMsg, setFailMsg] = useState<any>({
    error: false,
    message: ""
  })

  useEffect(() => {
    if (failMsg.error) {
      setTimeout(() => {
        setFailMsg({
          error: false,
          message: ""
        })
      }, 5000)
    }
  }, [failMsg])

  useEffect(() => {
    if (mainRFM.data) {
      setRfm(mainRFM.data)
    }
  }, [mainRFM])

  const [range, setRange] = useState<any[]>([])
  const [nearestday, setNearestday] = useState("")
  //const [event, setEvent] = useState(0)
  //const [selectedDate, setSelectedDate] = useState<string>("")
  const funeralState = useAppSelector(getFuneralState)
  const { events } = funeralState
  const [upcomingEvent, setUpcomingEvent] = useState<any>({
    id: "",
    type: "",
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    address: "",
    products: ""
  })
  const [postalError, setPostalError] = useState({
    error: false,
    message: ''
  })

  const [selectRfmError, setSelectRfmError] = useState({
    error: false,
    message: ''
  })

  const [checkoutOpen, setCheckoutOpen] = useState(true)
  const [userDetailOpen, setUserDetailOpen] = useState(true)

  const { data, isLoading, refetch } = useGetOrderQuery(id)
  const activeOrder = data?.activeOrder

  const validate = (obj: { key: string; value: string }) => {
    const { key, value } = obj
    let check = true
    if (key === "firstName" || key === "lastName") {
      if (value) {
        check = validateName(value)
      }
    }
    if (key === "phoneNumber") {
      if (value) {
        check = validateTel(value)
      }
    }
    if (key === "countryCode") {
      if (value) {
        check = validateCountryCode(value)
      }
    }
    if (key === "email") {
      if (value) {
        check = validateEmail(value)
      }
    }
    if (key === "eventDate") {
      if (value) {
        check = true
      }
    }


    if (!check) {
      setErrors((prev) => ({ ...prev, [key]: true }))
    } else {
      setErrors((prev) => ({ ...prev, [key]: false }))
    }
  }

  const debounceHandler = useCallback(debounce(validate), [])

  const validateDetail = (item: any, showError: boolean) => {
    let hasError = false
    for (const [key, value] of Object.entries<string>(item)) {
      switch (key) {
        case "firstName":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateName(value)
            if (check) {
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "lastName":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateName(value)
            if (check) {
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "email":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateEmail(value)
            if (check) {
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "phoneNumber":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateTel(value)
            if (check) {
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break
        case "countryCide":
          if (!value.trim()) {
            if (showError) setErrors((prev) => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            let check = validateCountryCode(value)
            if (check) {
              setErrors((prev) => ({ ...prev, [key]: false }))
            } else {
              hasError = true
              setErrors((prev) => ({ ...prev, [key]: true }))
            }
          }
          break

        default:
          break
      }
    }
    return hasError
  }
  
  const handleOrderDetail = async () => {
    setDetails({
      ...details,
      event: upcomingEvent.title,
      eventDate: nearestday
    })

    const hasError = validateDetail(details, true)

    if (hasError) {
      return
    }
    /* if(!details.eventDate) {
      setToast({
        ...toast,
        open:true,
        type: 'error',
        message: "Please select a delivery date!"
      })
      return
    } */
    if (!userState.isLoggedIn) {
      const { firstName, lastName, email, phoneNumber } = details
      try {
        await setCustomerForOrder({
          firstName,
          lastName,
          email,
          phoneNumber: `${details.countryCode} ${details.phoneNumber}`,
          funeralId: id
        })
      } catch (e) {
        console.error("debug customer setting = ", e)
      }
    }

    try {
      const deceaseId = id
      const eventId = details.event
      const eventDate = details.eventDate
      const message = details.message
      let addressLine1 = ""
      let addressLine2 = ""
      let postcode = ""
      let rfmId = RFM.id
      let rfmName = RFM.lastName
      /* if (userState.funeral.isRFM) {
        const address = upcomingEvent.address.split(",")
        addressLine1 = address[0]?.trim() || ""
        addressLine2 = address[1]?.trim() || ""
        postcode = address[2]?.trim() || ""
        rfmId = userState.user.userId
        rfmName = userState.user.lastName
        // rfmList.map((item: any) => {
        //   if (userState.user.email == item.email) {
        //     const address = item.address.split(",")
        //     addressLine1 = address[0] || ""
        //     addressLine2 = address[1] || ""
        //     postcode = address[2] || ""
        //   }
        // })
      } else if (selectRfm) {
        if(details.rfm === '') {
          setSelectRfmError({
            error: true,
            message: "Please select an RFM!"
          })
          return
        }else {
          setSelectRfmError({
            ...selectRfmError,
            error: false,
          })
        }
        rfmId = rfmList[`${details.rfm}`].id
        rfmName = rfmList[`${details.rfm}`].lastName
        const address = upcomingEvent.address.split(",")
        addressLine1 = address[0]?.trim() || ""
        addressLine2 = address[1]?.trim() || ""
        postcode = address[2]?.trim() || ""
      } else {
        addressLine1 = details.addressLine1?.trim() || ""
        addressLine2 = details.addressLine2?.trim() || ""
        postcode = details.postcode?.trim() || ""
        rfmId = ""
        rfmName = ""
      } */
      if (['Wake', 'Funeral'].includes(upcomingEvent.type)) {
        const address = upcomingEvent.address.split(",")
        addressLine1 = address[0]?.trim() || ""
        addressLine2 = address[1]?.trim() || ""
        postcode = address[address.length - 1]?.trim() || ""
        rfmId = userState.user.userId
        rfmName = userState.user.lastName
      } else if (userState.funeral.isRFM) {
        const address = upcomingEvent.address.split(",")
        addressLine1 = address[0]?.trim() || ""
        addressLine2 = address[1]?.trim() || ""
        postcode = address[address.length - 1]?.trim() || ""
        rfmId = userState.user.userId
        rfmName = userState.user.lastName
        // rfmList.map((item: any) => {
        //   if (userState.user.email == item.email) {
        //     const address = item.address.split(",")
        //     addressLine1 = address[0] || ""
        //     addressLine2 = address[1] || ""
        //     postcode = address[2] || ""
        //   }
        // })
      } else {
        if (sendToLocation) {
          //rfmId = rfmList[`${details.rfm}`].id
          //rfmName = rfmList[`${details.rfm}`].lastName
          const address = upcomingEvent.address.split(",")
          addressLine1 = address[0]?.trim() || ""
          addressLine2 = address[1]?.trim() || ""

          // postal code is in last index not in 2 index
          // postcode = address[2]?.trim() || ""
          postcode = address.slice(-1)[0]?.trim() || ""
        } else {
          addressLine1 = details.addressLine1?.trim() || ""
          addressLine2 = details.addressLine2?.trim() || ""
          postcode = details.addressPostcode?.trim() || ""
        }
      }

      if (!upcomingEvent.id) {
        postcode = details.addressPostcode
      }

      const isValid = validatePostal(postcode.trim())

      if (!isValid || !postcode) {
        setPostalError({
          error: true,
          message: 'Please input a valid postal code!'
        })
        return
      } else {
        setPostalError({
          error: false,
          message: 'Please input a valid postal code!'
        })
      }

      const rv = await setOrderShippingAddress({ deceaseId, eventId, eventDate, message, addressLine1, addressLine2, postcode, rfmId, rfmName, funeralId: id }).unwrap()

      if (rv.setOrderShippingAddress?.id) {
        let eventId = upcomingEvent.customId
        const x = padZeros(5, rv.setOrderShippingAddress?.id)
        let customOrderId = eventId + '/O' + x
        let body = {
          customId: customOrderId,
          orderId: rv.setOrderShippingAddress?.id
        }
        await updateCustomId(body).unwrap()
      }

      activeCustomerRefetch()

      refetch()

      props.handleStepper(1)

    } catch (e) {
      console.error("debug shipping error = ", e)
    }
    if (hasError) {
      return false
    }
  }

  useEffect(() => {
    let date = new Date().setHours(0, 0, 0, 0)
    let today = new Date()
    let tomorrow = new Date(today.setDate(today.getDate() + 1)).setHours(0, 0, 0, 0)
    let start_flag = true

    let data: any = {}

    for (var item of events) {

      if (new Date(item.startDate).getTime() >= date) {
        setNearestday(getDate(item.startDate))
        setUpcomingEvent(item)
        data = {
          ...details,
          event: upcomingEvent.title,
          eventDate: nearestday
        }
        break
      } else if (new Date(item.endDate).getTime() > date) {
        setNearestday(getDate(tomorrow))
        setUpcomingEvent(item)
        data = {
          ...details,
          event: upcomingEvent.title,
          eventDate: nearestday
        }
        break
      }
    }

    if (activeOrder?.shippingAddress?.customFields) {
      const { eventId, eventDate, message } = activeOrder.shippingAddress.customFields
      data = {
        ...details,
        event: eventId,
        eventDate: eventDate,
        message: message
      }
    }

    if (userState.isLoggedIn) {
      const { firstName, lastName, email, phoneNumber } = userState.user
      if (activeOrder?.shippingAddress?.customFields) {
        const { eventId, eventDate, message } = activeOrder.shippingAddress.customFields
        data = {
          ...details,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          event: eventId,
          eventDate: eventDate,
          message: message
        }
      } else {
        data = {
          ...details,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          event: upcomingEvent.title,
          eventDate: nearestday
        }
      }
    } else {
      if (activeOrder?.customer?.firstName) {
        const { firstName, lastName, emailAddress, phoneNumber } = activeOrder.customer
        if (activeOrder?.shippingAddress?.customFields) {
          const { eventId, eventDate, message } = activeOrder.shippingAddress.customFields
          data = {
            ...details,
            firstName: firstName,
            lastName: lastName,
            email: emailAddress,
            phoneNumber: phoneNumber,
            event: eventId,
            eventDate: eventDate,
            message: message
          }
        } else {
          data = {
            ...details,
            firstName: firstName,
            lastName: lastName,
            email: emailAddress,
            phoneNumber: phoneNumber
          }
        }
      }
    }

    setDetails({
      ...data,
      phoneNumber: data.phoneNumber ? data.phoneNumber.split(" ")[1] : '',
      countryCode: data.phoneNumber ? data.phoneNumber.split(" ")[0] : ''
    })

  }, [events, upcomingEvent, userState, activeOrder, isLoading])

  const handleChange = (event: any) => {
    let key = event.target.name
    let value = event.target.value

    setDetails({
      ...details,
      [key]: value
    })

    debounceHandler({ key, value })
  }

  const handleCheckboxClick = (event: any) => {
    setSendToLocation(!sendToLocation)
  }

  let shippingUI

  if (events != undefined && upcomingEvent.id > 0) {
    /* if (['Wake', 'Funeral'].includes(upcomingEvent.type)) {
      shippingUI = <></>
    } else { */
    shippingUI = (
      <>
        <Typography className="sectionTitle">
          <b>Send to location</b>
        </Typography>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                icon={<SquareIcon />}
                checkedIcon={<SquareCheckedIcon />}
                onClick={(event) => handleCheckboxClick(event)}
                color="primary"
                disabled={['Wake', 'Funeral'].includes(upcomingEvent.type)}
                checked={sendToLocation}
                inputProps={{
                  "aria-labelledby": "rfm_check"
                }}
              />
            }
            label={
              <Box component="div" fontSize={16}>
                Send to event location
              </Box>
            }
          />
          {/* <Checkbox
            icon={<Icon />}
            checkedIcon={<CheckedIcon />}
            onClick={(event) => handleCheckboxClick(event)}
            color="primary"
            disabled={['Wake', 'Funeral'].includes(upcomingEvent.type)}
            checked={sendToLocation}
            inputProps={{
              "aria-labelledby": "rfm_check"
            }}
          />
          <span style={{ marginRight: 10 }}>Send to event location</span> */}
        </Grid>
        {/* {(() => {
          return (
            <>
            {selectRfm && (
              <CustomSelect error={selectRfmError.error} sx={{ mt: 1,width: { xs: "365px" }, height: { xs: "45px" } }} size="small" id="rfm" name="rfm" labelId="action" input={<OutlinedInput />} value={details.rfm} onChange={handleChange}>
                {rfmList &&
                  rfmList.map((item: any, index: any) => (
                    <MenuItem key={item.id} value={index}>
                      {item.lastName}
                    </MenuItem>
                  ))}
              </CustomSelect>
            )}
            {selectRfmError.error ? <Typography sx={{marginTop:'4px', fontSize: 13, color:'#d32f2f'}}>Please select a RFM!</Typography> : <></>}
            </>
          )
        })()} */}
        {(() => {
          return (
            !sendToLocation && (
              <Grid item style={{ flexDirection: "row" }} sx={{ mt: 2 }}>
                <InputLabel className="sectionText"><span className={styles.mandatory}>Address</span></InputLabel>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomTextField id="addressLine1" name="addressLine1" placeholder="Address Line 1" size="small" className="formInput" value={details.addressLine1} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField id="addressLine2" name="addressLine2" placeholder="Address Line 2" size="small" className="formInput" value={details.addressLine2} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={6}>
                    <CustomTextField error={postalError.error} helperText={postalError.error ? postalError.message : ''} sx={{ '& p': { marginLeft: 0 } }} id="addressPostcode" name="addressPostcode" placeholder="Postcode" size="small" className="formInput" value={details.addressPostcode} onChange={handleChange} fullWidth />
                  </Grid>
                </Grid>
              </Grid>
            )
          )
        })()}
      </>
    )
    if (window.innerWidth > 768) {
      return (
        <Grid container item sx={{ pr: 0 }}>
          <Toast {...toast} />
          <Grid item>
            <InputLabel sx={{ mt: 1 }} className="sectionText">
              Send for event
            </InputLabel>
            <CustomSelect
              sx={{ mt: 1, mb: 3, width: { md: "365px" }, height: { xs: "45px" } }}
              size="small"
              id="event"
              name="event"
              labelId="action"
              value={upcomingEvent.title}
              onChange={handleChange}
              fullWidth
            // disabled
            >
              {/* <MenuItem key="1" value={upcomingEvent.title}>
                {upcomingEvent.title}
              </MenuItem> */}
              {
                events.map((event) => {
                  let date = new Date().setHours(0, 0, 0, 0)
                  if (new Date(event.startDate).getTime() >= date) {
                    return (
                      <MenuItem key={event.id} value={event.title}>
                        {event.title}
                      </MenuItem>
                    )
                  } else if (new Date(event.endDate).getTime() > date) {
                    return (
                      <MenuItem key={event.id} value={event.title}>
                        {event.title}
                      </MenuItem>
                    )
                  } else {
                    return null
                  }
                })
              }
            </CustomSelect>
            <InputLabel className="sectionText">Event date</InputLabel>
            <CustomSelect
              sx={{ mt: 1, mb: 3, width: { md: "365px" }, height: { xs: "45px" } }}
              size="small"
              id="eventDate"
              name="eventDate"
              labelId="action"
              input={<OutlinedInput />}
              value={nearestday}
              onChange={handleChange}
              fullWidth
            // disabled
            >
              {/* <MenuItem key="2" value={nearestday}>
                {nearestday}
              </MenuItem> */}
              {
                events.map((event) => {
                  let date = new Date().setHours(0, 0, 0, 0)
                  let today = new Date()
                  let tomorrow = new Date(today.setDate(today.getDate() + 1)).setHours(0, 0, 0, 0)
                  if (new Date(event.startDate).getTime() >= date) {
                    return (
                      <MenuItem key={event.id} value={getDate(event.startDate)}>
                        {getDate(event.startDate)}
                      </MenuItem>
                    )
                  } else if (new Date(event.endDate).getTime() > date) {
                    return (
                      <MenuItem key={event.id} value={getDate(tomorrow)}>
                        {getDate(tomorrow)}
                      </MenuItem>
                    )
                  } else {
                    return null
                  }
                })
              }
            </CustomSelect>
            {shippingUI}
            <Typography sx={{ mt: 2, mb: 3 }} component={"div"} variant={"body2"} className="hr"></Typography>

            <Grid item style={{ flexDirection: "row" }}>
              <InputLabel className="sectionText">Message</InputLabel>
              <CustomTextField sx={{ width: "100%", py: 2 }} multiline rows={6} id="message" name="message" placeholder="Your Message" value={details.message} onChange={handleChange} size="small" />
              <Typography sx={{ mb: 3, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
              <Typography className="sectionTitle">
                <b>Your Details</b>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>First Name </span></InputLabel>
                  <CustomTextField error={errors.firstName} helperText={errors.firstName ? "Requires a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="firstName" name="firstName" placeholder="First Name" size="small" className="formInput" onChange={handleChange} value={details.firstName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>Last Name </span></InputLabel>
                  <CustomTextField error={errors.lastName} helperText={errors.lastName ? "Require a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="lastName" name="lastName" placeholder="Last Name" size="small" className="formInput" onChange={handleChange} value={details.lastName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>Email</span></InputLabel>
                  <CustomTextField error={errors.email} helperText={errors.email ? "Require a valid email" : ""} sx={{ '& p': { marginLeft: 0 } }} id="email" name="email" placeholder="Email" size="small" className="formInput" onChange={handleChange} value={details.email} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText">
                    <span className={styles.mandatory}>Phone Number</span>
                  </InputLabel>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <CustomTextField
                        error={errors.countryCode}
                        helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                        sx={{ width: { xs: '100%' }, mb: 4 }}
                        value={details.countryCode}
                        onChange={handleChange}
                        id='countryCode'
                        name='countryCode'
                        size='small'
                        type=''
                        className={styles.input}
                        placeholder='65'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}
                      sx={{
                        textAlign: 'end'
                      }}
                    >
                      <CustomTextField
                        error={errors.phoneNumber}
                        helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                        sx={{ width: { xs: '98%' }, mb: 2 }}
                        value={details.phoneNumber}
                        onChange={handleChange}
                        id="phoneNumber"
                        name="phoneNumber"
                        size="small"
                        className={styles.input}
                        fullWidth
                        placeholder='Phone number'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Button type="submit" fullWidth onClick={handleOrderDetail} className={styles.btnCheckout} variant="outlined">
                    Continue to Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container item sx={{ px: 3 }}>
          <Toast {...toast} />
          <Grid item xs={12}>
            <ListItem disablePadding>
              <ListItemButton sx={{ px: 0 }} onClick={() => setCheckoutOpen(!checkoutOpen)}>
                <ListItemText primary="Checkout" />
                {checkoutOpen ? <ExpandLess sx={{}} /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Collapse className={styles.orderDetail} in={checkoutOpen} timeout="auto" unmountOnExit>
              <InputLabel sx={{ mt: 2 }} className="sectionText1">
                Send for event
              </InputLabel>
              <CustomSelect sx={{ mt: 1, mb: 2, width: { md: "365px" }, height: { xs: "45px" } }} size="small" id="event" name="event" labelId="action" value={upcomingEvent.title} onChange={handleChange} fullWidth disabled>
                <MenuItem key="1" value={upcomingEvent.title}>
                  {upcomingEvent.title}
                </MenuItem>
              </CustomSelect>
              <InputLabel className="sectionText1">Event date</InputLabel>
              <CustomSelect sx={{ mt: 1, mb: 2, width: { md: "365px" }, height: { xs: "45px" } }} size="small" id="eventDate" name="eventDate" labelId="action" input={<OutlinedInput />} value={nearestday} onChange={handleChange} fullWidth disabled>
                <MenuItem key="2" value={nearestday}>
                  {nearestday}
                </MenuItem>
              </CustomSelect>
              {shippingUI}
              <Typography sx={{ mt: 2, mb: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
              <InputLabel className="sectionText">Message</InputLabel>
              <CustomTextField sx={{ width: "100%", py: 1 }} multiline rows={4} id="message" name="message" placeholder="Your Message" value={details.message} onChange={handleChange} size="small" />
              <Typography sx={{ mb: 3, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
            </Collapse>
            <ListItem disablePadding>
              <ListItemButton sx={{ px: 0 }} onClick={() => setUserDetailOpen(!userDetailOpen)}>
                <ListItemText primary="Your Details" />
                {userDetailOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            <Collapse className={styles.userDetail} in={userDetailOpen} timeout="auto" unmountOnExit>
              <Grid item style={{ flexDirection: "row" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                    <InputLabel className="sectionText"><span className={styles.mandatory}>First Name </span></InputLabel>
                    <CustomTextField error={errors.firstName} helperText={errors.firstName ? "Requires a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="firstName" name="firstName" placeholder="First Name" size="small" className="formInput" onChange={handleChange} value={details.firstName} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className="sectionText"><span className={styles.mandatory}>Last Name </span></InputLabel>
                    <CustomTextField error={errors.lastName} helperText={errors.lastName ? "Require a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="lastName" name="lastName" placeholder="Last Name" size="small" className="formInput" onChange={handleChange} value={details.lastName} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className="sectionText"><span className={styles.mandatory}>Email</span></InputLabel>
                    <CustomTextField error={errors.email} helperText={errors.email ? "Require a valid email" : ""} sx={{ '& p': { marginLeft: 0 } }} id="email" name="email" placeholder="Email" size="small" className="formInput" onChange={handleChange} value={details.email} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel className="sectionText">
                      <span className={styles.mandatory}>Phone Number</span>
                    </InputLabel>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <CustomTextField
                          error={errors.countryCode}
                          helperText={errors.countryCode ? 'Requires a valid country code' : ''}
                          sx={{ width: { xs: '100%' }, mb: 4 }}
                          value={details.countryCode}
                          onChange={handleChange}
                          id='countryCode'
                          name='countryCode'
                          size='small'
                          type=''
                          className={styles.input}
                          placeholder='65'
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>+</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={9}
                        sx={{
                          textAlign: 'end'
                        }}
                      >
                        <CustomTextField
                          error={errors.phoneNumber}
                          helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                          sx={{ mb: 2 }}
                          value={details.phoneNumber}
                          onChange={handleChange}
                          id="phoneNumber"
                          name="phoneNumber"
                          size="small"
                          className={styles.input}
                          fullWidth
                          placeholder='Phone number'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography component={"div"} variant={"body2"} className="hr"></Typography>
            </Collapse>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6} sx={{ mt: 2 }}>
              <Button type="submit" fullWidth onClick={handleOrderDetail} className={styles.btnCheckout} variant="outlined">
                Continue to Payment
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }
  else {
    // return null
    if (window.innerWidth > 768) {
      return (
        <>
          <Grid item style={{ flexDirection: "row" }} sx={{ mt: 2 }}>
            <InputLabel className="sectionText"><span className={styles.mandatory}>Address</span></InputLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomTextField id="addressLine1" name="addressLine1" placeholder="Address Line 1" size="small" className="formInput" value={details.addressLine1} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField id="addressLine2" name="addressLine2" placeholder="Address Line 2" size="small" className="formInput" value={details.addressLine2} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={6}>
                <CustomTextField error={postalError.error} helperText={postalError.error ? postalError.message : ''} sx={{ '& p': { marginLeft: 0 } }} id="addressPostcode" name="addressPostcode" placeholder="Postcode" size="small" className="formInput" value={details.addressPostcode} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            <Typography sx={{ mb: 3, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
          </Grid>

          <Grid item style={{ flexDirection: "row" }}>
            <InputLabel className="sectionText">Message</InputLabel>
            <CustomTextField sx={{ width: "100%", py: 2 }} multiline rows={6} id="message" name="message" placeholder="Your Message" value={details.message} onChange={handleChange} size="small" />
            <Typography sx={{ mb: 3, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
            <Typography className="sectionTitle">
              <b>Your Details</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel className="sectionText"><span className={styles.mandatory}>First Name </span></InputLabel>
                <CustomTextField error={errors.firstName} helperText={errors.firstName ? "Requires a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="firstName" name="firstName" placeholder="First Name" size="small" className="formInput" onChange={handleChange} value={details.firstName} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel className="sectionText"><span className={styles.mandatory}>Last Name </span></InputLabel>
                <CustomTextField error={errors.lastName} helperText={errors.lastName ? "Require a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="lastName" name="lastName" placeholder="Last Name" size="small" className="formInput" onChange={handleChange} value={details.lastName} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel className="sectionText"><span className={styles.mandatory}>Email</span></InputLabel>
                <CustomTextField error={errors.email} helperText={errors.email ? "Require a valid email" : ""} sx={{ '& p': { marginLeft: 0 } }} id="email" name="email" placeholder="Email" size="small" className="formInput" onChange={handleChange} value={details.email} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel className="sectionText">
                  <span className={styles.mandatory}>Phone Number</span>
                </InputLabel>
                <CustomTextField
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                  sx={{ mb: 4 }}
                  value={details.phoneNumber}
                  onChange={handleChange}
                  id="phoneNumber"
                  name="phoneNumber"
                  size="small"
                  className={styles.input}
                  fullWidth
                  placeholder='Phone number with country code'
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>+65</span>
                //     </InputAdornment>
                //   )
                // }}
                />

              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Button type="submit" fullWidth onClick={handleOrderDetail} className={styles.btnCheckout} variant="outlined">
                  Continue to Payment
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return (
        <Grid container item sx={{ px: 3 }}>
          <Grid item style={{ flexDirection: "row" }} sx={{ mt: 2 }}>
            <InputLabel className="sectionText"><span className={styles.mandatory}>Address</span></InputLabel>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomTextField id="addressLine1" name="addressLine1" placeholder="Address Line 1" size="small" className="formInput" value={details.addressLine1} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField id="addressLine2" name="addressLine2" placeholder="Address Line 2" size="small" className="formInput" value={details.addressLine2} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={6}>
                <CustomTextField error={postalError.error} helperText={postalError.error ? postalError.message : ''} sx={{ '& p': { marginLeft: 0 } }} id="addressPostcode" name="addressPostcode" placeholder="Postcode" size="small" className="formInput" value={details.addressPostcode} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            <Typography sx={{ mt: 2, mb: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
          </Grid>

          <Typography sx={{ mt: 2, mb: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>
          <InputLabel className="sectionText">Message</InputLabel>
          <CustomTextField sx={{ width: "100%", py: 1 }} multiline rows={4} id="message" name="message" placeholder="Your Message" value={details.message} onChange={handleChange} size="small" />
          <Typography sx={{ mb: 3, mt: 2 }} component={"div"} variant={"body2"} className="hr"></Typography>

          <ListItem disablePadding>
            <ListItemButton sx={{ px: 0 }} onClick={() => setUserDetailOpen(!userDetailOpen)}>
              <ListItemText primary="Your Details" />
              {userDetailOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>

          <Typography component={"div"} variant={"body2"} className="hr"></Typography>

          <Collapse className={styles.userDetail} in={userDetailOpen} timeout="auto" unmountOnExit>
            <Grid item style={{ flexDirection: "row" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>First Name </span></InputLabel>
                  <CustomTextField error={errors.firstName} helperText={errors.firstName ? "Requires a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="firstName" name="firstName" placeholder="First Name" size="small" className="formInput" onChange={handleChange} value={details.firstName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>Last Name </span></InputLabel>
                  <CustomTextField error={errors.lastName} helperText={errors.lastName ? "Require a valid name" : ""} sx={{ '& p': { marginLeft: 0 } }} id="lastName" name="lastName" placeholder="Last Name" size="small" className="formInput" onChange={handleChange} value={details.lastName} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText"><span className={styles.mandatory}>Email</span></InputLabel>
                  <CustomTextField error={errors.email} helperText={errors.email ? "Require a valid email" : ""} sx={{ '& p': { marginLeft: 0 } }} id="email" name="email" placeholder="Email" size="small" className="formInput" onChange={handleChange} value={details.email} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel className="sectionText">
                    <span className={styles.mandatory}>Phone Number</span>
                  </InputLabel>
                  <CustomTextField
                    error={errors.phoneNumber}
                    helperText={errors.phoneNumber ? "Requires a valid phone number" : ""}
                    sx={{ mb: 2 }}
                    value={details.phoneNumber}
                    onChange={handleChange}
                    id="phoneNumber"
                    name="phoneNumber"
                    size="small"
                    className={styles.input}
                    fullWidth
                    placeholder='Phone number'
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>+65</span>
                  //     </InputAdornment>
                  //   )
                  // }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Typography component={"div"} variant={"body2"} className="hr"></Typography>
          </Collapse>

          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6} sx={{ mt: 2 }}>
            <Button type="submit" fullWidth onClick={handleOrderDetail} className={styles.btnCheckout} variant="outlined">
              Continue to Payment
            </Button>
          </Grid>
        </Grid>
      )
    }
  }
}

export default OrderDetail
