// import { styles } from "@material-ui/pickers/views/Calendar/Calendar"
import { Box, Typography, Button } from "@mui/material"
import { useState, useEffect } from "react"
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getFuneralState } from '../../features/funeral/funeralSlice';
import { getUserState } from '../../features/user/userSlice';
import ItemsCarousel from 'react-items-carousel';
import Toast from "../base/Toast/Toast";
import styles from './Product.module.css'
import { useAddItemOrderMutation, useGetOrderQuery, useSetOrderShippingMethodMutation } from "../../features/order/orderGqlApi";

interface ProductProps {
  id : string
  name: string
  description: string
  variants: Variants[]
  assets: Assets[]
}

interface Variants {
  id: string
  sku: string
  price: number
  priceWithTax: number
  name: string
  assets: []
}

interface Assets {
  name: string
  source: string
}

const SendGiveCarousel = (props:any) => {
  const [toast, setToast] = useState({
    open: false,
    type: "success",
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })
  const { id } = useParams()
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const chevronWidth = 0
  const userState = useAppSelector(getUserState)
  const [addItemToOrder] = useAddItemOrderMutation()
  const { refetch } = useGetOrderQuery(id)
  const [setOrderShippingMethod] = useSetOrderShippingMethodMutation()

  const [numberOfCards, setNumberOfCards] = useState(4)
  const [imageHeight, setImageHeight] = useState(window.innerWidth)

  const width = window.innerWidth
  useEffect(() => {
    if (window.innerWidth < 768) {
      setNumberOfCards(2)
      setImageHeight(window.innerWidth / 2 - 20)
    }else{
      setNumberOfCards(4)
      setImageHeight(window.innerWidth / 5 - 20)
    }
  },[window.innerWidth])

  const handleAddCart = async (event:any) => {
    const productName = event.target.name;
    const productVariantId = event.target.value;
    const quantity = 1
    if (userState.isLoggedIn) {
      try {
        await addItemToOrder({
          productVariantId,
          quantity,
          funeralId: id,
          eventId: props.upcomingEvent.id
        })
        await setOrderShippingMethod({funeralId: id})
        refetch()
        setToast({
          ...toast,
          open: true,
          message: `${productName} successfully added to cart!`,
          type: "success"
        })
      } catch(e) {
        console.error('debug', e)
      }
    }
    
  }
  return (
    <div style={{ padding: `0 ${chevronWidth}px`}}>
      <Toast {...toast} />
      <ItemsCarousel
        infiniteLoop
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={numberOfCards}
        gutter={12}
        leftChevron={<ChevronLeftIcon className={styles.chevronLeft}/>}
        rightChevron={<ChevronRightIcon className={styles.chevronRight} />}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {
          props.products.map((item: any, index: any) => {
            return (
              <Box key={index}>
                <Link className='link' to={`../give/product/${item.slug}/${item.id}`} relative="path">
                  <Box sx={{height: `${imageHeight}px`, backgroundColor: 'white'}}>
                    <img src={item.image} className={styles.carouselImg}/>
                  </Box>
                </Link>
                <Typography className={styles.productName}>
                  {item.name}
                </Typography>
                <Typography className={styles.productPrice}>${(item.price/100).toFixed(2)}</Typography>
                <Button sx={{mt:2}} className={styles.btnCart} onClick={handleAddCart} name={item.name} value={item.id} fullWidth>Add To Cart</Button>
              </Box>
            )
          })
        }
      </ItemsCarousel>
    </div>
  )
}

export default SendGiveCarousel
