import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { guest } from '../../types'

interface RfmEditState {
  toSave: string
  note: string
  obituary: {
    message: string
    file: any
  }
  payNow: {
    details: string
    file: any
  }
  selectedEvent: any
  event_details: any
  email: {
    subject: string
    message: string
  }
  guestlist: guest[]
  survivedBy: any[],
  executorRfm: any
}

const initialState: RfmEditState = {
  toSave: '',
  note: "",
  obituary: {
    message: "",
    file: ''
  },
  payNow: {
    details: "",
    file: ''
  },
  selectedEvent: '',
  event_details: {
    id: null,
    funeralId: '',
    type: 'Ash collection : Collection of cremated remains',
    title: '',
    createdAt: null,
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    details: '',
    address: '',
    livestream: 'Live Stream Not Available',
    livestreamUrl: '',
    rfmId: null,
  },
  email: {
    subject: 'Passing of <<Deceased Name>>',
    message: 'Our mother passed away peacefully in her sleep in hospital surrounded by her family and loved ones on the 16 October 2022. We are blessed to have been able to spend the remaining time with her.'
  },
  guestlist: [],
  survivedBy: [],
  executorRfm: null
}

export const rfmEditSlice = createSlice({
  initialState,
  name: "rfmEdit",
  reducers: {
    reset: () => initialState,
    setEditNote: (state, { payload }) => {
      state.note = payload.notes
      state.toSave = payload.toSave
    },
    setEditObituary: (state, { payload }) => {
      state.toSave = payload.toSave
      state.obituary = payload.obituary
    },
    setEditPayNow: (state, { payload }) => {
      state.toSave = payload.toSave
      state.payNow = payload.payNow
    },
    setSelectedEvent:  (state, { payload }) => {
      state.selectedEvent = payload
    },
    addGuest: (state, {payload}) => {
      for(var guest of payload) {
        let found = state.guestlist.find((item) => {
          return item.email === guest.email
        })
        if(!found) {
          state.guestlist.push(guest)
        }
      }
    },
    deleteGuest: (state, {payload}) => {
      state.guestlist = payload
    },
    setEvent: (state, {payload}) => {
      state.event_details = payload
    },
    resetEvent: (state, {payload}) => {
      state.event_details = {
        id: null,
        funeralId: '',
        type: 'Ash collection : Collection of cremated remains',
        title: '',
        createdAt: null,
        startDate: null,
        endDate: null,
        startTime: '',
        endTime: '',
        details: '',
        address: '',
        livestream: 'Live Stream Not Available',
        livestreamUrl: '',
        rfmId: null,
      }
    },
    setEmail: (state, {payload}) => {
      state.email = {
        ...state.email,
        ...payload
      }
    },
    setSurvivedBy: (state, {payload}) => {
      let index = state.survivedBy.findIndex((item) => {
        return item.id === payload.data.id
      })
      if(index >= 0) {
        state.survivedBy[index] = payload.data
      }else {
        state.survivedBy.push(payload.data)
      }

      state.toSave = payload.toSave
    },
    setExecutorRfm: (state, {payload}) => {
      state.executorRfm = payload
      state.toSave = 'survivedBy'
    }
  }
})

export const getRfmEditState = (state: RootState) => state.rfmEdit

export default rfmEditSlice.reducer

export const { setEditNote, setEditObituary, setEditPayNow, setSelectedEvent, reset, setEvent, resetEvent, setEmail, setSurvivedBy, setExecutorRfm } = rfmEditSlice.actions
