import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { invite, eventInvite } from '../../types';

export interface UserState {
  isLoggedIn: boolean
  toggleLoginModal: boolean
  funeral: {
    isInvited: boolean
    isRFM: boolean
  },
  user: {
    customerId: number | null
    userId: number | null
    email: string
    phoneNumber: string
    firstName: string
    lastName: string
  }
  invites: invite[]
  eventInvites: eventInvite[]
}

const initialState: UserState  = {
  isLoggedIn: false,
  toggleLoginModal: false,
  funeral: {
    isInvited: false,
    isRFM: false
  },
  user: {
    customerId: null,
    userId: null,
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
  },
  invites: [],
  eventInvites: []
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: () => initialState,
    setUser: (state, {payload}) => {
      const {id, emailAddress, firstName, lastName, user, phoneNumber} = payload.activeCustomer
      state.isLoggedIn = true
      state.user = {
        customerId: id,
        userId: user.id,
        email: emailAddress,
        phoneNumber: phoneNumber,
        firstName: firstName,
        lastName: lastName
      }
    },
    setInvites: (state, {payload}) => {
      const {funeralInvites, eventInvites} = payload
      state.invites = funeralInvites
      state.eventInvites = eventInvites
    },
    setCurrentInvite: (state, {payload}) => {
      state.funeral.isInvited = payload.isInvited
    },
    setIsRfm: (state, {payload}) => {
      state.funeral.isRFM = payload.isRFM
    },
    toggleLoginModal: (state, {payload}) => {
      state.toggleLoginModal = !state.toggleLoginModal
    },
    
  },
});

export const getUserState = (state: RootState) => state.user;

export default userSlice.reducer;

export const { logout, setUser, setInvites, setCurrentInvite, setIsRfm, toggleLoginModal } = userSlice.actions;

