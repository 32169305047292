import { Box, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import SendGiveCarousel from "../../components/product/sendGiveCarousel"
import { getFuneralState } from "../../features/funeral/funeralSlice"
import { useGetProductsQuery } from "../../features/products/productGqlApi"
import { getProductState } from "../../features/products/productSlice"
// import styles from './give.module.css'

interface ProductObject {
  [key: string]: any
}

function Give() {
  useGetProductsQuery("")

  let products = useAppSelector(getProductState)
  const [eventProducts, setEventProducts] = useState<any>([])
  const funeralState = useAppSelector(getFuneralState)
  const { events } = funeralState
  const [upcomingEvent, setUpcomingEvent] = useState<any>({
    id: "",
    title: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    address: "",
    products: ""
  })

  // useEffect(() => {
  //   let date = new Date().setHours(0, 0, 0, 0);
  //   let start_flag = true;
  //   for (var item of events) {
  //     /* if (new Date(item.startDate).getTime() >= date) {
  //       if (start_flag) {
  //         setUpcomingEvent(item);
  //         start_flag = false;
  //       }else {
  //         if (new Date(upcomingEvent.startDate).getTime() > new Date(item.startDate).getTime()) {
  //           setUpcomingEvent(item)
  //         }
  //       }
  //     } */
  //     if (new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
  //       setUpcomingEvent(item)
  //       break
  //     }
  //   }

  //   let productArray = upcomingEvent.products.split(',');

  //   products.items.map((item) => {
  //     item.variants.map((subItem: any) => {
  //       if (productArray.indexOf(subItem.id) > -1) {
  //         let tempItem: ProductObject = {}
  //         tempItem.id = subItem.id
  //         tempItem.name = subItem.name
  //         tempItem.slug = item.slug
  //         tempItem.price = subItem.price
  //         tempItem.description = item.description
  //         tempItem.image = subItem.assets[0]?.source
  //         tempItemList.push(tempItem)
  //       }
  //     })
  //   })
  //   event_products = tempItemList
  //   setEventProducts(event_products)
  // }, [events, products, upcomingEvent])

  useEffect(() => {
    let tempItemList: any[] = []

    let date = new Date().setHours(0, 0, 0, 0)

    for (var item of events) {
      if (new Date(item.startDate).getTime() >= date || new Date(item.endDate).getTime() >= date) {
        setUpcomingEvent(item)
        break
      }
    }

    products.items.slice(0, 4).map((item) => {
      let tempItem: ProductObject = {}
      tempItem.id = item.variants[0]?.id
      tempItem.name = item.variants[0].name
      tempItem.slug = item.slug
      tempItem.price = item.variants[0].priceWithTax
      tempItem.description = item.description
      tempItem.image = item.variants[0]?.assets[0]?.source || item.assets[0]?.source
      tempItemList.push(tempItem)

      return true
    })

    setEventProducts(tempItemList)
  }, [products])

  if (Object.entries(eventProducts).length > 0) {
    return (
      <Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={6} sx={{ pb: 1 }}>
            <Typography className="sectionTitle" component="div">
              <b>Send Gifts</b>
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: "none", md: "block" }, pb: 1 }}>
            <Link to={`../give`} className="link">
              View More
            </Link>
          </Grid>
        </Grid>

        <SendGiveCarousel products={eventProducts} upcomingEvent={upcomingEvent} />
        {/* <Grid sx={{display: {xs:'block', md: 'none'}, mt: 2}}>
          <Link to="../give" className='link' relative="path" style={{ textDecoration: 'none'}}>
            <Button  sx={{ mb:2 }} variant="outlined" className={styles.btnViewMore} fullWidth>View More Gifts</Button>
          </Link>
        </Grid> */}
      </Box>
    )
  } else {
    return null
  }
}

export default Give
