import { gqlApi } from '../../api/graphql';
import { gql} from 'graphql-request'
import { getOrder } from './orderSlice'

export const orderGqlApi = gqlApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrder: builder.query({
            providesTags: ['cart'],
            query: (funeralId) => ({
                body: gql`
                    query {
                        activeOrder(
                            activeOrderInput: {
                                orderToken: {
                                    token: "${funeralId}"
                                }
                            }
                        ) {
                            id
                            code
                            active
                            createdAt
                            updatedAt
                            state
                            currencyCode
                            totalQuantity
                            subTotal
                            subTotalWithTax
                            taxSummary {
                                description
                                taxRate
                                taxTotal
                            }
                            shipping
                            shippingWithTax
                            totalWithTax
                            customer {
                                id
                                firstName
                                lastName
                                emailAddress
                                phoneNumber
                            }
                            shippingAddress {
                                fullName
                                streetLine1
                                streetLine2
                                company
                                city
                                province
                                postalCode
                                countryCode
                                phoneNumber
                                customFields
                            }
                            shippingLines {
                                shippingMethod {
                                    id
                                    name
                                }
                                priceWithTax
                            }
                            lines {
                                id
                                unitPriceWithTax
                                linePriceWithTax
                                quantity
                                featuredAsset {
                                    id
                                    preview
                                }
                                productVariant {
                                    id
                                    name
                                    price
                                    product {
                                    id
                                    slug
                                    }
                                }
                            }
                            customFields {
                                eventId
                            }
                        }
                    }
                `
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    let {data} = await queryFulfilled;
                    dispatch(getOrder(data.activeOrder));
                } catch (error) {}
            },
        }),
        addItemOrder: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        addItemToOrder(
                            productVariantId: ${data.productVariantId},
                            quantity: ${data.quantity}
                            activeOrderInput: {
                            orderToken: {
                                token: "${data.funeralId}"
                                eventId: "${data.eventId}"
                              }
                            }
                        ){
                            ... on Order {
                                id
                                code
                                active
                                createdAt
                                state
                                currencyCode
                                totalQuantity
                                subTotal
                                subTotalWithTax
                                taxSummary {
                                    description
                                    taxRate
                                    taxTotal
                                }
                                shipping
                                shippingWithTax
                                totalWithTax
                                customer {
                                    id
                                    firstName
                                    lastName
                                    emailAddress
                                }
                                shippingAddress {
                                    fullName
                                    streetLine1
                                    streetLine2
                                    company
                                    city
                                    province
                                    postalCode
                                    countryCode
                                    phoneNumber
                                }
                                shippingLines {
                                    shippingMethod {
                                        id
                                        name
                                    }
                                    priceWithTax
                                }
                                lines {
                                    id
                                    unitPriceWithTax
                                    linePriceWithTax
                                    quantity
                                    featuredAsset {
                                        id
                                        preview
                                    }
                                    productVariant {
                                        id
                                        name
                                        price
                                        product {
                                            id
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                `
            })
        }),
        adjustOrderLine: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        adjustOrderLine(
                            orderLineId: ${data.adjustId}
                            quantity: ${data.adjustQuantity}
                            activeOrderInput: {
                                orderToken: {
                                    token: "${data.funeralId}"
                                  }
                                }
                        ){
                            ... on Order {
                                id
                                code
                                active
                                createdAt
                                state
                                currencyCode
                                totalQuantity
                                subTotal
                                subTotalWithTax
                                taxSummary {
                                    description
                                    taxRate
                                    taxTotal
                                }
                                shipping
                                shippingWithTax
                                totalWithTax
                                customer {
                                    id
                                    firstName
                                    lastName
                                    emailAddress
                                }
                                shippingAddress {
                                    fullName
                                    streetLine1
                                    streetLine2
                                    company
                                    city
                                    province
                                    postalCode
                                    countryCode
                                    phoneNumber
                                }
                                shippingLines {
                                    shippingMethod {
                                        id
                                        name
                                    }
                                    priceWithTax
                                }
                                lines {
                                    id
                                    unitPriceWithTax
                                    linePriceWithTax
                                    quantity
                                    featuredAsset {
                                        id
                                        preview
                                    }
                                    productVariant {
                                        id
                                        name
                                        price
                                        product {
                                            id
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                `
            })
        }),
        removeOrderLine: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        removeOrderLine(
                            orderLineId: ${data.adjustId}
                            activeOrderInput: {
                                orderToken: {
                                    token: "${data.funeralId}"
                                  }
                                }
                        ){
                            ...on Order {
                                id
                            }
                            ... on OrderModificationError {
                                errorCode
                                message
                            }
                        }
                    }
                `
            })
        }),
        // default shipping method setting.
        setOrderShippingMethod: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        setOrderShippingMethod(
                            shippingMethodId: 1
                            activeOrderInput: {
                                orderToken: {
                                    token: "${data.funeralId}"
                                  }
                                }
                        ) {
                            __typename
                            ... on Order {
                                id
                                code
                                active
                                createdAt
                                state
                                currencyCode
                                totalQuantity
                                subTotal
                                subTotalWithTax
                                taxSummary {
                                    description
                                    taxRate
                                    taxTotal
                                }
                                shipping
                                shippingWithTax
                                totalWithTax
                                customer {
                                    id
                                    firstName
                                    lastName
                                    emailAddress
                                    phoneNumber
                                }
                                shippingAddress {
                                    fullName
                                    streetLine1
                                    streetLine2
                                    company
                                    city
                                    province
                                    postalCode
                                    countryCode
                                    phoneNumber
                                    customFields
                                }
                                shippingLines {
                                    shippingMethod {
                                        id
                                        name
                                    }
                                    priceWithTax
                                }
                                lines {
                                    id
                                    unitPriceWithTax
                                    linePriceWithTax
                                    quantity
                                    featuredAsset {
                                        id
                                        preview
                                    }
                                    productVariant {
                                        id
                                        name
                                        price
                                        product {
                                        id
                                        slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                `    
            })
        }),
        getOrderById: builder.mutation({
            query: (data) => ({
                body: gql`
                    query {
                        order(id: "${data.orderId}") {
                            ... on Order {
                                id
                                code
                                active
                                createdAt
                                updatedAt
                                state
                                currencyCode
                                totalQuantity
                                subTotal
                                subTotalWithTax
                                taxSummary {
                                    description
                                    taxRate
                                    taxTotal
                                }
                                shipping
                                shippingWithTax
                                totalWithTax
                                customer {
                                    id
                                    firstName
                                    lastName
                                    emailAddress
                                    phoneNumber
                                }
                                shippingAddress {
                                    fullName
                                    streetLine1
                                    streetLine2
                                    company
                                    city
                                    province
                                    postalCode
                                    countryCode
                                    country
                                    phoneNumber
                                    customFields
                                }
                                shippingLines {
                                    shippingMethod {
                                        id
                                        name
                                    }
                                    priceWithTax
                                }
                                lines {
                                    id
                                    unitPriceWithTax
                                    linePriceWithTax
                                    quantity
                                    featuredAsset {
                                        id
                                        preview
                                    }
                                    productVariant {
                                        id
                                        name
                                        price
                                        product {
                                        id
                                        slug
                                        }
                                    }
                                }
                                customFields {
                                    orderToken
                                    orderId
                                }
                            }
                        }
                    }
                `
            })
        }),
        updateCustomId: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        updateOrderCustomId(
                            customId: "${data.customId}"
                            orderId: "${data.orderId}"
                        ) {
                            id
                        }
                    }
                `
            })
        }),
        updateEventId: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        updateOrderEventId(
                            eventId: "${data.eventId}"
                            orderId: "${data.orderId}"
                        ) {
                            id
                        }
                    }
                `
            })
        }),
        removeAllOrderLines: builder.mutation({
            query: (data) => ({
                body: gql`
                    mutation {
                        removeAllOrderLines(
                            activeOrderInput: {
                                orderToken: {
                                    token: "${data.funeralId}"
                                  }
                                }
                        ){  ...on Order {
                                id
                            }
                            ... on OrderModificationError {
                                errorCode
                                message
                            }
                        }
                    }
                `
            })
        }),
    }),
}) 
export const { useGetOrderQuery, useAddItemOrderMutation, useAdjustOrderLineMutation, useSetOrderShippingMethodMutation, useGetOrderByIdMutation, useRemoveOrderLineMutation, useUpdateCustomIdMutation, useRemoveAllOrderLinesMutation, useUpdateEventIdMutation } = orderGqlApi
